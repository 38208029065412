import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CkeditorComponent } from '../../app/components/ckeditor/ckeditor.component';
import { MixinModule } from '../mixin/mixin.module';

@NgModule({
  declarations: [
    CkeditorComponent
  ],
  imports: [
    MixinModule,
    CommonModule
  ],
  exports: [CkeditorComponent]
})
export class CkeditorModule {
}
