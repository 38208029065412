import { ChintI18nInterface } from '../chint-i18n.interface';

// 俄语
export const ru_RU: ChintI18nInterface = {
  locale: 'ru_RU',

  // 首页
  login: 'Войдите в аккаунт',
  search: 'Поиск',
  download_center: 'Центр загрузок',
  contact_us: 'Связь с нами',
  learn_more: 'Подробнее',
  subscribe: 'Подписаться',
  all_categories: 'Все категории',
  membership: 'Тип клана',
  log_out: 'Выйти',
  home: 'Главная',
  privacy: 'Конфиденциальность',

  back_to_home: 'вернуться на главную страницу',
  // 解决方案
  solutions: 'подсказки',

  // 产品列表
  product_search_placeholder: 'Введите название продукта или модели',

  // 产品详情
  return_list: 'обратный список',
  overview: 'Обзор',
  catalog: 'Catalog',
  manual: 'Manual',
  certificate: 'Certificate',
  drawings: 'Drawings',
  technical_patameter: 'Технические параметры',
  installation_manual: 'Руководство',
  video: 'видео',
  // 客服服务
  contacts: 'Контакты',
  contact_no: 'контактный телефон',
  e_mail: 'Электронная почта',
  service_center: 'Сервисный центр',

  // 搜索中心
  all: 'Все',
  news: 'Новости',
  products: 'Товары',
  files: 'файлов',

  // 下载中心
  sample: 'образец',
  product_category: 'ассортимент продукции',
  product_sub_caregory: 'подкатегория продукции',
  download_center_search_placeholder: 'поиск по ключевому слову',
  file_name: 'имя файла',
  document_format: 'формат документа',
  update_time: 'Обновить время',
  document_size: 'размер документа',
  download: 'загрузка',
  category: 'категория',

  // 关于我们
  about_us: 'О нас',

  // 新闻中心
  read_more: 'читать больше информации',
  more_news: 'еще',
  news_center: 'пресс - центр',

  // 加入我们
  recruitment: 'набор персонала',
  hunman_resource: 'Людские ресурсы',
  care_team: 'медицинская команда',
  city: 'город',
  type: 'тип',
  position: 'Функции',
  number: 'количество',
  download_template: 'Загрузить шаблоны',
  upload: 'Загрузка резюме',
  join_us_warn: 'загружать файлы в формате документа или PDF',
  details: 'Сведения',
  position_search_placeholder: 'место поиска',

  // 会员详情
  logins: 'Имя пользователя',
  member_detail_warn: 'Ждите, пока администратор проверит привилегии членов.',
  your_email: 'Ваш адрес электронной почты',
  password: 'пароль',
  confirm_password: 'подтвердить пароль',
  save: 'Сохранить',
  personal_info: 'Личные сведения',
  select_your_industry: 'выбор профессии',
  select_your_country: 'Выберите вашу страну',

  // 登录
  register_now: 'Никаких счетов?немедленная регистрация',
  forgot_password: 'забыть пароль',
  password_length_error: 'Длина пароля должна быть от 6 до 20 знаков',
  password_error: 'Неверный пароль',
  email_check: 'ваше электронное письмо не проверено',
  account_ban: 'Ваш почтовый ящик находится на рассмотрении',
  no_account: 'Нет такого аккаунта',

  // 注册
  existing_accounts: 'текущий счет',
  profile: 'Я...',
  policy: 'принятие Стратегии пользователя',
  submit: 'Отправить',
  link_expired: 'текущая ссылка устарела, Перезагрузите её.',

  // 注册完发送邮箱验证信息
  send_email: '',
  send_register_email: 'Мы направили вам электронное письмо, в котором содержится ссылка на завершение регистрации.',
  resend: 'пересылать',
  after: 'через',
  second: 'секунд',

  // 忘记密码
  register: 'регистрация',

  // 修改密码发送邮箱验证信息
  send_reset_email: 'Мы направили вам электронное письмо, в котором содержится ссылка на сброс пароля.',

  // 重置密码
  new_password: 'новый пароль',

  // 订阅表单
  name: 'имя',
  company: 'единица',
  email: 'электронная почта',
  industry: 'промышленность',
  region: 'область',

  // Self Service表单
  phone_number: 'Телефон: (+ EARA КОД)',
  select_your_region: 'Выберите область',
  question_type: 'тип проблемы',
  desc_of_info: 'описание релевантной информации',
  verification_code: 'код проверки',
  submit_now: 'немедленная подача',

  // Contact US表单
  title: 'звание',

  // 表单错误信息
  item_required: 'этот пункт необходимо заполнить',
  email_wrong: 'Введите адрес электронной почты',
  fields_required: 'поле с меткой * необходимо',
  enter_correct_mail: 'Введите правильный адрес почтового ящика',
  enter_password: 'Введите пароль',
  enter_graphic_code: 'Введите правильный графический код проверки.',
  password_re_enter: 'Две пары паролей введены непоследовательно, пожалуйста, введите их снова.',
  agree_use_policy: 'Пожалуйста, сначала дайте согласие на использование политики.',

  // 表单成功信息
  reset_succeed: 'сбросить Пароль успешно',
  code_send_succeed: 'успешно отправлен код проверки',
  login_succeed: 'регистрация прошла успешно',
  log_out_succeed: 'Вы списаны',
  sign_succeed: 'регистрация прошла успешно',
  submit_succeed: 'передача успешно завершена',
  upload_succeed: 'Загрузка завершена',
  update_succeed: 'обновление завершено',

  // 月份缩写
  Jan: 'янв.',
  Feb: 'фев.',
  Mar: 'Март',
  Apr: 'Aпр.',
  May: 'Май',
  Jun: 'Июн.',
  Jul: 'Июль',
  Aug: 'Авг.',
  Sept: 'Сент',
  Oct: 'Окт.',
  Nov: 'Ноя.',
  Dec: 'Дек.',

  // 月份
  January: 'Январь',
  February: 'Февраль',
  March: 'Март',
  April: 'Апрель',
  May_full: 'Май',
  June: 'Июнь',
  July: 'Июль',
  August: 'Август',
  September: 'Сентябрь',
  October: 'Октябрь',
  November: 'Ноябрь',
  December: 'Декабрь',

  accept: 'Accept',
  before_news: 'Previous',
  next_news: 'Next',

  reset_email_send: 'we have send a email to your account, please check the email to perform the next step'
};
