import { ChintI18nInterface } from '../chint-i18n.interface';

export const tr_TR: ChintI18nInterface = {
  locale: 'tr_TR',

  // 首页
  login: 'Oturum Aç',
  search: 'Ara',
  download_center: 'İndirme Merkezi',
  contact_us: 'Bize ulaşın',
  learn_more: 'Daha fazla bilgi',
  subscribe: 'Abone ol',
  all_categories: 'Tüm Kategoriler',
  membership: 'Üyeler',
  log_out: 'Çıkış',
  home: 'Ev',
  privacy: 'gizlilik',
  back_to_home: 'Ev sayfasına geri dön.',
  // 解决方案
  solutions: 'çözümler',

  // 产品列表
  product_search_placeholder: 'Ürün veya modelin adını girin',

  // 产品详情
  return_list: 'Listeye dön',
  overview: 'Genel Bakış',
  catalog: 'Catalog',
  manual: 'Manual',
  certificate: 'Certificate',
  drawings: 'Drawings',
  technical_patameter: 'Teknik Parametreler',
  installation_manual: 'Manuel',
  video: 'Video',
  // 客服服务
  contacts: 'Kişiler',
  contact_no: 'İletişim No.',
  e_mail: 'E-posta',
  service_center: 'servis merkezi',

  // 搜索中心
  all: 'Tümü',
  news: 'Haberler',
  products: 'Ürünler',
  files: 'Dosya',

  // 下载中心
  sample: 'örnek',
  product_category: 'Ürün Kategorisi',
  product_sub_caregory: 'Ürün Alt Kategorisi',
  download_center_search_placeholder: 'Anahtar kelimeye göre arama, ürün adı',
  file_name: 'Dosya adı',
  document_format: 'Belge Biçimi',
  update_time: 'Güncelleme zamanı',
  document_size: 'Belge Boyutu',
  download: 'İndir',
  category: 'Kategorisi',

  // 关于我们
  about_us: 'Hakkımızda',

  // 新闻中心
  read_more: 'Daha fazla oku',
  more_news: 'tane daha',
  news_center: 'haber Merkezi',

  // 加入我们
  recruitment: 'İşe Alım',
  hunman_resource: 'İnsan kaynakları',
  care_team: 'Sağlık ekibi',
  city: 'şehir',
  type: 'tip',
  position: 'posta',
  number: 'miktar',
  download_template: 'Şablonu İndir',
  upload: 'CV yükle',
  join_us_warn: 'Lütfen dosyaları doc veya PDF formatında yükleyin',
  details: 'Ayrıntılar',
  position_search_placeholder: 'Pozisyon ara',

  // 会员详情
  logins: 'Girişler',
  member_detail_warn: 'Lütfen yöneticinin üyelik ayrıcalıklarınızı incelemesini bekleyin.',
  your_email: 'E-posta adresiniz',
  password: 'Parola',
  confirm_password: 'Şifreyi Onayla',
  save: 'Kayıt etmek',
  personal_info: 'Kişisel bilgi',
  select_your_industry: 'Sektörünüzü seçin',
  select_your_country: 'Ülkeni seç',

  // 登录
  register_now: 'Hesap yok? Şimdi üye Ol',
  forgot_password: 'Parolanızı mı unuttunuz',
  password_length_error: 'Şifre en az 6 en fazla 20 karakter olmalıdır.',
  password_error: 'Hatalı Şifre',
  email_check: 'E-posta adresiniz kontrol edilmedi',
  account_ban: 'Posta kutunuz inceleniyor',
  no_account: 'Böyle bir hesap yokt',

  // 注册
  existing_accounts: 'Mevcut hesaplar',
  profile: 'Ben bir...',
  policy: 'Kullanıcı Politikasını Kabul Et',
  submit: 'Gönder',
  link_expired: 'Mevcut bağlantının süresi doldu, lütfen tekrar başlat.',

  // 注册完发送邮箱验证信息
  send_email: '',
  send_register_email: 'Kaydınızı tamamlamak için size bir bağlantı içeren bir e-posta gönderdik.',
  resend: 'Yeniden gönder',
  after: '',
  second: 'saniye sonra',

  // 忘记密码
  register: 'Kayıt olmak',

  // 修改密码发送邮箱验证信息
  send_reset_email: 'Şifrenizi sıfırlamak için size bir bağlantı içeren bir e-posta gönderdik.',

  // 重置密码
  new_password: 'Yeni Şifre',

  // 订阅表单
  name: 'isim',
  company: 'şirket',
  email: 'E-posta',
  industry: 'sanayi',
  region: 'bölge',

  // Self Service表单
  phone_number: 'Telefon Numarası: (+ alan kodu)',
  select_your_region: 'Bölgenizi seçin',
  question_type: 'soru tipi',
  desc_of_info: 'İlgili bilgilerin açıklaması',
  verification_code: 'Doğrulama kodu',
  submit_now: 'Şimdi gönder',

  // Contact US表单
  title: 'Başlıklar',

  // 表单错误信息
  item_required: 'Bu ürün gerekli',
  email_wrong: 'Geçerli bir işletme e-postası girin',
  fields_required: '* İle işaretli alanların doldurulması zorunludur',
  enter_correct_mail: 'Lütfen doğru posta kutusu adresini girin',
  enter_password: 'Lütfen şifre giriniz',
  enter_graphic_code: 'Lütfen doğru grafik doğrulama kodunu giriniz.',
  password_re_enter: 'İki şifre girişi tutarsız, lütfen tekrar girin.',
  agree_use_policy: 'Lütfen önce kullanım politikasını kabul edin.',

  // 表单成功信息
  reset_succeed: 'Şifreyi başarıyla sıfırla',
  code_send_succeed: 'Doğrulama kodu başarıyla gönderildi',
  login_succeed: 'Başarılı giriş',
  log_out_succeed: 'Zaten çıkış yaptınız',
  sign_succeed: 'Başarılı kayıt',
  submit_succeed: 'Başarıyla Gönder',
  upload_succeed: 'Başarıyla Yükle',
  update_succeed: 'Başarıyla Güncelle',

  // 月份缩写
  Jan: 'Oca.',
  Feb: 'Şbt.',
  Mar: 'Mar.',
  Apr: 'Nis.',
  May: 'May.',
  Jun: 'Haz.',
  Jul: 'Tem.',
  Aug: 'Ağu.',
  Sept: 'Eyl.',
  Oct: 'Eki.',
  Nov: 'Kas.',
  Dec: 'Ara.',

  // 月份
  January: 'Ocak',
  February: 'Şubat',
  March: 'Mart',
  April: 'Nisan',
  May_full: 'Mayžs',
  June: 'Haziran',
  July: 'Temmuz',
  August: 'A-gustos',
  September: 'Eylül',
  October: 'Ekim',
  November: 'Kasžm',
  December: 'Aralžk',

  accept: 'Accept',
  before_news: 'Previous',
  next_news: 'Next',

  reset_email_send: 'we have send a email to your account, please check the email to perform the next step'
};
