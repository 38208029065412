/**
 * Created by cml on 2017/11/23.
 */

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelper } from '../../../helper/http.helper';
import { Content } from '../../../model/common/content';
import { Image } from '../../../model/common/image';
import { GraphqlBuilder } from '../../grapql/graphql_builder';
import { BaseService } from './base.service';
import { ContentsService } from './contents.service';
import { AnyObject } from '../../types/custom_access_value';

@Injectable()
export class ImageService extends BaseService {
  constructor(private content_service: ContentsService,
              public http: HttpHelper,
              private http_client: Http) {
    super(http);
  }

  public images(search_params = {}): Observable<any[]> {
    return this.http.AUTH_HTTP_GET('atyun/images.json?per=10000', search_params).pipe(map(data => this.http.extractData(data).images));
  }

  public update(id: string, update_params:AnyObject = {}): Observable<any> {
    update_params.image_id = id;
    return this.http.AUTH_HTTP_PUT('atyun/images/' + id + '.json', update_params
    );
  }

  public create(create_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_POST('atyun/images.json', create_params);
  }

  public delete_by_id(id: string) {
    const params = {image_id: id};
    return this.http.AUTH_HTTP_DELETE('atyun/images/' + id + '.json', params);

  }

  public gql_images(search_params = {}): Observable<any[]> {
    return this.http.AUTH_HTTP_GET('atyun/images.json?per=10000', search_params).pipe(map(data => this.http.extractData(data).images));
  }

  // 图片全文搜索
  public full_search_images(search_params = {}) {
    const query = new GraphqlBuilder(Image);  // Image
    const body = query.getProperties();
    const search = query.query().build('imageFull', body);
    const params = {
      query: search,
      variables: {search: {...search_params}}
    };
    return this.content_service.query(params);
  }

  // 获取图片详细信息
  public get_image_detail_info(url: any): any {
    return this.http_client.get(url).pipe(map(data => JSON.parse(data['_body'])));
  }

}
