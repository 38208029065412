import { ApplicationRef, NgModuleRef } from '@angular/core';
import { createNewHosts } from '@angularclass/hmr';
import { MainInjectorService } from './app/services/main-injector.service';

export const hmrBootstrap = (module: any, bootstrap: () => Promise<any>) => {
  let ngModule: NgModuleRef<any>;
  module.hot.accept();
  bootstrap().then(mod => ngModule = mod).then((module) => {
    MainInjectorService.getInstance().setInjector(module.injector);
  }).catch(err => console.log(err));

  module.hot.dispose(() => {
    const appRef: ApplicationRef = ngModule.injector.get(ApplicationRef);
    const elements = appRef.components.map(c => c.location.nativeElement);
    const makeVisible = createNewHosts(elements);
    ngModule.destroy();
    makeVisible();
  });
};
