import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: [
      `:host {
      width: 100%;
      height: 100%;
      display: block;
      margin-bottom: -1px;
    }`
  ]
})
export class AppComponent implements OnInit {
  ngOnInit(): void {
  }

  ngAfterViewInit() {

  }
}
