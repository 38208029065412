import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { combineLatest, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { count } from '../../anotations/count.anotation';
import { DataHelper } from '../../helper/data.helper';
import { Content } from '../../model/common/content';
import { Project } from '../../model/common/project';
import { ProjectConfig } from '../../model/common/project_config';
import { Template } from '../../model/common/template';
import { I18nInterface } from '../i18n/i18n.interface';
import { I18nService } from '../i18n/i18n.service';
import { CounterService } from './content/counter.service';
import { ImageService } from './content/image.service';
import { ImageCategoryService } from './content/image_category.service';
import { TemplatesService } from './content/template.service';
import { UserPluginService } from './content/user-plugin.service';

import { DrawerData } from '../components/section-menu/section-menu.component';
import { en_US } from '../i18n/languages/en_US';
import { zh_CN } from '../i18n/languages/zh_CN';
import { AtI18nService, en_US as at_en_US, zh_CN as at_zh_CN } from 'at-ng';
import { ChintI18nService } from '../../modules/domain/chint/i18n/chint-i18n.service';
import { ChintI18nInterface } from '../../modules/domain/chint/i18n/chint-i18n.interface';
import { ar_SA } from '../../modules/domain/chint/i18n/languages/ar_SA';
import { en_US as chint_en_US } from '../../modules/domain/chint/i18n/languages/en_US';
import { es_ES } from '../../modules/domain/chint/i18n/languages/es_ES';
import { fr_FR } from '../../modules/domain/chint/i18n/languages/fr_FR';
import { it_IT } from '../../modules/domain/chint/i18n/languages/it_IT';
import { pt_PT } from '../../modules/domain/chint/i18n/languages/pt_PT';
import { ru_RU } from '../../modules/domain/chint/i18n/languages/ru_RU';
import { tr_TR } from '../../modules/domain/chint/i18n/languages/tr_TR';
import { zh_CN as chint_zh_CN } from '../../modules/domain/chint/i18n/languages/zh_CN';
import { AnyObject } from '../types/custom_access_value';
const getUrl = require('speakingurl');
type modalType = 'edit' | 'preview' | 'show';

class SaveStatus {
  title: string;
  isSaving: boolean;

  constructor() {
    this.title = '正在编辑...';
    this.isSaving = false;
  }

  saving(value) {
    setTimeout(_ => {
      this.isSaving = value;
      if (!value) {
        setTimeout(_ => {
          this.title = '正在编辑...';
        }, 400);
      }
    });
    return this;
  }

  text(value) {
    setTimeout(_ => {
      this.title = value;
    });
    return this;
  }
}

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(private data_helper: DataHelper, private template_service: TemplatesService,
    private counter_service: CounterService,
    public i18n: I18nService,
    private image_service: ImageService,
    private image_category_service: ImageCategoryService,
    private atI18NService: AtI18nService,
    public router: Router,
    private chint_i18n_service: ChintI18nService) {

    this._template_select_list = new BehaviorSubject([]) as BehaviorSubject<Template[]>;
    this._system_categories_lib = new BehaviorSubject([]) as BehaviorSubject<any[]>;
    this._user_image_lib = new BehaviorSubject([]) as BehaviorSubject<any[]>;
    this._alternative_image_lib = new BehaviorSubject([]) as BehaviorSubject<any[]>;
    this._content_template_list = new BehaviorSubject([]) as BehaviorSubject<Content[]>;
    this._system_content_list = new BehaviorSubject([]) as BehaviorSubject<Content[]>;
    this._project_menu = new BehaviorSubject(null) as BehaviorSubject<any>;
    this._classifications = new BehaviorSubject([]) as BehaviorSubject<any[]>;
    this._product_categories = new BehaviorSubject([]) as BehaviorSubject<any[]>;
    this._product_labels = new BehaviorSubject([]) as BehaviorSubject<any[]>;
    this._content_labels = new BehaviorSubject([]) as BehaviorSubject<any[]>;
    this._shops = new BehaviorSubject([]) as BehaviorSubject<any[]>;

    this.i18n.setLocale(this.locale_key[sessionStorage.getItem('locale') || 'zh_CN']);

    this.i18n.localChange.subscribe(locale => {
      this.locale = locale;
    });

    this.atI18NService.setLocale(this.atI18Nlocale[sessionStorage.getItem('locale') || 'zh_CN']);

    this.chint_i18n_service.setLocale(this.chint_Locale_key.en_US);

    this.chint_i18n_service.localChange.subscribe(locale => {
      this.chint_local = locale;
    });
  }

  locale_key: { [key: string]: any } = { 'zh_CN': zh_CN, 'en_US': en_US };
  atI18Nlocale: { [key: string]: any } = { 'zh_CN': at_zh_CN, 'en_US': at_en_US };
  chint_Locale_key: { [key: string]: any } = { 'zh_CN': chint_zh_CN, 'en_US': chint_en_US, 'pt-PT': pt_PT, 'es-ES': es_ES, 'fr-FR': fr_FR, 'ru-RU': ru_RU, 'it-IT': it_IT, 'tr-TR': tr_TR, 'ar-SA': ar_SA };

  locale: I18nInterface;

  chint_local: ChintI18nInterface;

  configDrawerSubject = new Subject<DrawerData>();

  first = true;

  modal: string = 'edit';

  // search terms
  searchTerms: Subject<string> = new Subject<string>();

  editing_template: any;

  // 板块配置的弹窗
  config_drawer_visible = false;

  // 可撤销历史列表
  revoke_template_list = [];

  // 现在的template所在的位置
  revoke_index: number = 0;

  // 保存状态
  saveStatus = new SaveStatus();

  _pc_editor;

  /**
   *   config drawer 相关配置
   */

  plugins = ['basic_config'];
  themes = [];
  image_index: number = 0;
  swiper_index: number = 0;
  // 是否在编辑轮播图图片
  edit_carousel = false;
  sunlight_index: number = 0;

  /**
   * 图片设置 相关配置
   */
  editing_image;

  editing_image_size;
  /**
   * 导航设置-页尾通用设置
   */
  menu_config: any;
  /**
   * 登录注册页面id
   */
  _login_template_id

  public set loginTemplateId(id) {
    this._login_template_id = id;
  }

  public get loginTemplateId() {
    return this._login_template_id;
  }

  get device_type(): string {

    if (this.modal !== 'edit') {
      this._pc_editor = window.document.body.clientWidth > 781 ? true : false;
    }

    return window.document.body.clientWidth > 781 ? 'pc' : 'mobile';
  }

  public set pc_editor(pc_editor) {
    this._pc_editor = pc_editor;
  }

  public get pc_editor() {
    return this._pc_editor;
  }

  /**
   * 重新渲染
   * @type {Function}
   */
  render_contents: any = Function.prototype;

  get edit_modal(): boolean {
    return this.modal === 'edit';
  }

  addProjectCounter(): void {
    if (this.current_project) {
      const params = {
        create: {
          count_type: 'project_active',
          resource_type: 'Project',
          resource_id: this.current_project.id
        }
      };
      this.counter_service.project_count(params).subscribe(data => {

      });
    }
  }

  get template_animation() {
    if (this.editing_template && this.editing_template.template_animation) {
      return this.editing_template.template_animation;
    } else {
      return '';
    }
  }

  get background_animation() {
    if (this.editing_template && this.editing_template.background_animation) {
      return this.editing_template.background_animation;
    } else {
      return '';
    }
  }

  get current_project(): any {
    const data = localStorage.getItem('current_project');
    const _current_project = JSON.parse(data);
    return _current_project;
  }

  set current_project(value: any) {
    localStorage.setItem('current_project', JSON.stringify(value));
  }

  public user_ids(type: string): any {

    return []
    // if (type === 'news') {
    //   if (this.current_project.shop_id == this.current_project.main_shop_id) {
    //     const ids = this.current_project.news_share_ids || [];
    //     ids.push(this.current_project.user_id)
    //     return ids;
    //   } else {
    //     return this.current_project.news_shared_by ? [this.$current_user_id || this.current_project.user_id, this.current_project.manager_id] : [this.$current_user_id || this.current_project.user_id];
    //   }
    // } else {
    //   if (this.current_project.shop_id == this.current_project.main_shop_id) {
    //     const ids = this.current_project.document_share_ids || [];
    //     ids.push(this.current_project.user_id)
    //     return ids;
    //   } else {
    //     return this.current_project.document_shared_by ? [this.$current_user_id || this.current_project.user_id, this.current_project.manager_id] : [this.$current_user_id || this.current_project.user_id];
    //   }
    // }
  }

  public tmp_user_ids(type: string = ''): any {
    if (this.$current_user && this.$current_user.post === '管理员') {
      return [];
    } else {
      if (type === 'document' && !this.current_project.document_shared_by) {
        return [this.current_project.user_id];
      } else {
        return [this.current_project.user_id, this.current_project.manager_id];
      }
    }
  }

  private _current_project_config: ProjectConfig = null;

  get current_project_config(): ProjectConfig {
    return this._current_project_config || new ProjectConfig();
  }

  set current_project_config(value: ProjectConfig) {
    // this.generateMetaContent(value);
    this._current_project_config = value;
  }

  /**
   * meta tag generator from project configs
   * @param config
   */
  generateMetaContent(config): void {
    const meta_name = [{ name: 'name', column: 'title' }, { name: 'keywords', column: 'keyword' }, {
      name: 'description',
      column: 'desc'
    }];

    // 设置name的meta
    meta_name.forEach((name) => {
      const meta = document.createElement('meta');
      meta.content = this.current_project[name.column];
      meta.name = name.name;
      document.getElementsByTagName('head')[0].appendChild(meta);
    });

    // 设置image 之类的
    const og_name = [
      { name: 'og:image', project: false, column: 'share_pic' },
      { name: 'og:title', project: true, column: 'title' },
      { name: 'og:site_name', project: true, column: 'name' },
      { name: 'og:description', project: true, column: 'desc' }
    ];

    og_name.forEach(tag => {
      const value = tag.project ? this.current_project[tag.column] : config[tag.column];
      const meta = document.createElement('meta');
      meta.content = value;
      meta.setAttribute('property', tag.name);
      document.getElementsByTagName('head')[0].appendChild(meta);
    });

    // itemprop
    const item_prop = [
      { name: 'name', column: 'name', project: true },
      { name: 'image', column: 'share_pic', project: false },
      { name: 'description', project: true, column: 'desc' }
    ];

    item_prop.forEach(item => {
      const value = item.project ? this.current_project[item.column] : config[item.column];
      const meta = document.createElement('meta');
      meta.content = value;
      meta.setAttribute('itemprop', item.name);
      document.getElementsByTagName('head')[0].appendChild(meta);
    });

  }

  public get $current_form_session(): any {
    return JSON.parse(sessionStorage.getItem('form_session_info'));
  }

  public set $current_form_session(value) {
    sessionStorage.setItem('form_session_info', value);
  }

  addFormSessionKey(key, value) {
    let session = this.$current_form_session;
    if (!session) {
      session = {};
    }
    session[key] = value;
    this.$current_form_session = JSON.stringify(session);
  }

  /**
   * 当前登录用户
   */
  public get $current_user(): any {
    let user = localStorage.getItem('user');
    user = JSON.parse(user);
    return user;
  }

  public set $current_user(value) {
    localStorage.setItem('user', JSON.stringify(value));
  }

  /**
   * 商品管理平台用户
   */
  public get $product_user(): any {
    let product_user = localStorage.getItem('product_user');
    product_user = JSON.parse(product_user);
    return product_user;
  }

  public set $current_shop(value) {
    localStorage.setItem('current_shop', JSON.stringify(value));
  }

  public get $current_shop(): any {
    let current_shop = localStorage.getItem('current_shop');
    current_shop = JSON.parse(current_shop);
    return current_shop;
  }

  // 当前管理站点的定制平台用户id，不是当前登录用户的Id
  public set $current_user_id(value: string) {
    localStorage.setItem('current_user_id', value);
  }

  public get $current_user_id(): string {
    return localStorage.getItem('current_user_id');
  }

  _project_id;

  set ProjectId(value) {
    this._project_id = value;
  }

  public get projectID() {
    if (this._project_id) {
      return this._project_id;
    }
    const hostname = window.location.hostname;
    const site = hostname.split('.')[0];
    const id = site.split('site-')[1];
    return this.data_helper.encodeId(id);
  }

  /**
   * template select list cache, select template's template
   */
  private _template_select_list: BehaviorSubject<Template[]>;

  /**
   * system image category list cache
   */
  private _system_categories_lib: BehaviorSubject<any[]>;

  /**
   * user image list cache
   */
  private _user_image_lib: BehaviorSubject<any[]>;

  /**
   * emmmmmm
   */
  private _alternative_image_lib: BehaviorSubject<any[]>;

  /**
   * content's template cache ,for create block from user's custom block
   */
  private _content_template_list: BehaviorSubject<any[]>;

  private _system_content_list: BehaviorSubject<any[]>;

  private _classifications: BehaviorSubject<any[]>;

  private _product_categories: BehaviorSubject<any[]>;

  private _content_labels: BehaviorSubject<any[]>;

  private _product_labels: BehaviorSubject<any[]>;

  private _shops: BehaviorSubject<any[]>;

  set SystemCategories(value: any) {
    this._system_categories_lib.next(value);
  }

  get SystemCategories(): any {
    return this._system_categories_lib.asObservable();
  }

  set UserImage(value: any) {
    this._user_image_lib.next(value);
  }

  get UserImage(): any {
    return this._user_image_lib.asObservable();
  }

  set AlternativeImage(value: any) {
    this._alternative_image_lib.next(value);
  }

  get AlternativeImage(): any {
    return this._alternative_image_lib.asObservable();
  }

  /**
   * return cache templates
   * @returns {any}
   */
  get template_select_list(): any {
    return this._template_select_list.asObservable();
  }

  set template_select_list(value: any) {
    let templates = value
    if (!this.current_project_config.document_center_status) {
      templates = templates.filter(item => {
        return ['document_center', 'document_search', 'document_detail'].indexOf(item.business_category) === -1;
      });
    }

    this._template_select_list.next(templates);

  }

  get content_template_list(): any {
    return this._content_template_list.asObservable();
  }

  set content_template_list(value: any) {
    this._content_template_list.next(value);
  }

  get classifications(): any {
    return this._classifications.asObservable();
  }

  set classifications(value: any) {
    this._classifications.next(value);
  }

  get content_labels(): any {
    return this._content_labels.asObservable();
  }

  set content_labels(value: any) {
    this._content_labels.next(value);
  }

  get shops(): any {
    return this._shops.asObservable();
  }

  set shops(value: any) {
    this._shops.next(value);
  }

  get product_labels(): any {
    return this._product_labels.asObservable();
  }

  set product_labels(value: any) {
    this._product_labels.next(value);
  }

  /**
   * 获取系统板块
   */
  get system_content_list(): any {
    return this._system_content_list.asObservable();
  }

  set system_content_list(value: any) {
    this._system_content_list.next(value);
  }

  /**
  *  获取商品分类
  */

  get product_categories(): any {
    return this._product_categories.asObservable();
  }

  set product_categories(value: any) {
    this._product_categories.next(value);
  }

  /**
   * revoke content's change
   */
  set_editing_template() {
    if (this.revoke_index == this.revoke_template_list.length - 1) {
      this.revoke_template_list.push(this.data_helper.deepCopy(this.editing_template));

      this.revoke_index += 1;
    } else {
      const list_length = this.data_helper.deepCopy(this.revoke_template_list).length;
      const revoke_index = this.data_helper.deepCopy(this.revoke_index);
      this.revoke_template_list.splice(revoke_index + 1, list_length - revoke_index - 1, this.data_helper.deepCopy(this.editing_template));

      this.revoke_index = this.data_helper.deepCopy(this.revoke_template_list).length - 1;
    }
  }

  get html_fontSize() {
    return parseFloat(window.document.documentElement.style.fontSize.replace('px', ''));
  }

  /**
   * 动态内容管理 当前点击的菜单id
   */
  private _project_menu: BehaviorSubject<any>;

  set ProjectMenu(value: any) {
    this._project_menu.next(value);
  }

  get ProjectMenu(): any {
    return this._project_menu.asObservable();
  }

  sectionBackgroundMode(content: any): any {
    return {
      'y-section--tiling': content.is_tiling,
      'y-section--locked': content.is_locked,
      'y-section--normal': !content.is_tiling && !content.is_locked && content.alignment === ''
    };
  }

  sectionBackground(content: any): any {
    return content.background ? 'url(' + content.background + ')' : content.background_color;
  }

  @count('link', { resource_id: '', project_id: '', resource_type: 'Template' })
  goTo(menu: any, current_menu?: any): void {
    if (current_menu && current_menu.name) {
      localStorage.setItem('current_menu', current_menu.name);
    }
    let template_id;

    if (menu.link && typeof (menu.link) === 'string') {
      window.open(menu.link);
      return;
    }
    if (typeof (menu) === 'string') {
      switch (menu) {
        case 'Products Center':
          template_id = this.current_project_config.product_center_page_id;
          break;
        case 'Services Center':
          template_id = this.current_project_config.service_center_page_id;
          break;
        case 'About Us':
          template_id = this.current_project_config.about_us_page_id;
          break;
        case 'Join Us':
          template_id = this.current_project_config.join_us_page_id;
          break;
        case 'contact_us':
          template_id = this.current_project_config.contact_us_page_id;
          break;
        case 'news':
          template_id = this.current_project_config.news_center_page_id;
          break;
        case 'download':
          template_id = this.current_project_config.download_center_page_id;
          break;
        case 'membership':
          template_id = this.current_project_config.membership_page_id;
          break;
        case 'login':
          template_id = this.current_project_config.login_page_id;
          break;
        case 'memberCenter':
          template_id = this.current_project_config.member_center_page_id;
          break;
      }
    } else {
      template_id = menu.template_id;
    }
    if (template_id) {
      if (this.modal === 'show') {
        this.template_service.templates({ 'search[id]': template_id }).subscribe(data => {
          const template = (data || [])[0];
          if (menu.target_blank) {
            if (template.business_category === 'solution') {
              window.open('/' + template.business_category + '/' + this.generatePath(template.name, template_id));
            } else {
              window.open('/' + this.generatePath(template.business_category, template_id));
            }
          } else {
            if (template.business_category === 'solution') {
              this.router.navigate(['/' + template.business_category + '/' + this.generatePath(template.name, template_id)]);
            } else {
              this.router.navigate(['/' + this.generatePath(template.business_category, template_id)]);

            }
          }
        });
      } else {
        if (menu.target_blank) {
          window.open(`/dashboard/preview/${this.projectID}/template/${template_id}`);
        } else {
          this.router.navigate([`/dashboard/preview/${this.projectID}/template/`, template_id]);
        }
      }
    } else if (menu === 'home') {
      if (this.modal === 'show') {
        this.router.navigate(['/']);
      } else {
        this.router.navigate([`/dashboard/preview/${this.projectID}/template/${this.current_project_config.chint_homepage_id}`]);
      }
    }
  }
  getGoToURL(menu: any, current_menu?: any): Promise<string> {
    if (current_menu && current_menu.name) {
      localStorage.setItem('current_menu', current_menu.name);
    }
    let template_id;
    let url = '';
    if (menu.link && typeof (menu.link) === 'string') {
      return new Promise(resolve => resolve(menu.link));
    }
    if (typeof (menu) === 'string') {
      switch (menu) {
        case 'Products Center':
          template_id = this.current_project_config.product_center_page_id;
          break;
        case 'Services Center':
          template_id = this.current_project_config.service_center_page_id;
          break;
        case 'About Us':
          template_id = this.current_project_config.about_us_page_id;
          break;
        case 'Join Us':
          template_id = this.current_project_config.join_us_page_id;
          break;
        case 'contact_us':
          template_id = this.current_project_config.contact_us_page_id;
          break;
        case 'news':
          template_id = this.current_project_config.news_center_page_id;
          break;
        case 'download':
          template_id = this.current_project_config.download_center_page_id;
          break;
        case 'membership':
          template_id = this.current_project_config.membership_page_id;
          break;
        case 'login':
          template_id = this.current_project_config.login_page_id;
          break;
        case 'memberCenter':
          template_id = this.current_project_config.member_center_page_id;
          break;
      }
    } else {
      template_id = menu.template_id;
    }
    if (template_id) {
      if (this.modal === 'show') {
        return new Promise(resolve => {
          this.template_service.templates({ 'search[id]': template_id }).subscribe(data => {
            const template = (data || [])[0];
            if (template) {
              if (template.business_category === 'solution') {
                url = '/' + template.business_category + '/' + this.generatePath(template.name, template_id);
              } else {
                url = '/' + this.generatePath(template.business_category, template_id);
              }
            }
            resolve(window.location.origin + url + '/');
          });
        });
      } else {
        if (menu.target_blank) {
          url = `/dashboard/preview/${this.projectID}/template/${template_id}`;
        } else {
          url = `/dashboard/preview/${this.projectID}/template/${template_id}`;
        }
      }
    } else if (menu === 'home') {
      if (this.modal === 'show') {
        url = '';
      } else {
        url = `/dashboard/preview/${this.projectID}/template/${this.current_project_config.chint_homepage_id}`;
      }
    }
    return new Promise(resolve => {
      resolve(window.location.origin + url + '/');
    });
  }

  goProductDetail(product: any, target_blank: boolean = false): void {
    const product_detail_page_id = this.current_project_config.product_detail_page_id
    const product_id = product.id;
    if (this.modal === 'show') {
      if (target_blank) {
        window.open('/products/' + this.generatePath(product.name, product_detail_page_id, product_id));
      } else {
        this.router.navigate(['/products/' + this.generatePath(product.name, product_detail_page_id, product_id)]);
      }
    } else {
      if (target_blank) {
        window.open(`/dashboard/preview/${this.current_project.id}/template/${product_detail_page_id}?product_id=${product_id}`);
      } else {
        this.router.navigate([`/dashboard/preview/${this.current_project.id}/template/${product_detail_page_id}`], { queryParams: { product_id } });
      }
    }
  }

  getProductDetailURL(product: any, target_blank: boolean = false): string {
    const product_detail_page_id = this.current_project_config.product_detail_page_id
    const product_id = product.id;
    let url;
    if (this.modal === 'show') {
      url = '/products/' + this.generatePath(product.name, product_detail_page_id, product_id);
    } else {
      url = `/dashboard/preview/${this.current_project.id}/template/${product_detail_page_id}?product_id=${product_id}`;
    }
    return location.origin + url;
  }

  goNewsDetail(news: AnyObject, target_blank: boolean = false): void {
    const news_id = news.id;
    if (!this.edit_modal) {
      if (this.modal === 'show') {
        if (target_blank) {
          window.open('/news/' + this.generatePath(news.title || news.name, this.current_project_config.news_detail_page_id, news_id));
        } else {
          this.router.navigate(['/news/' + this.generatePath(news.title || news.name, this.current_project_config.news_detail_page_id, news_id)]);
        }
      } else {
        if (target_blank) {
          window.open(`/dashboard/preview/${this.projectID}/template/${this.current_project_config.news_detail_page_id}?news_id=${news_id}`);
        } else {
          this.router.navigate([`/dashboard/preview/${this.projectID}/template/${this.current_project_config.news_detail_page_id}`], { queryParams: { news_id } });
        }
      }
    }
  }

  getGoNewsDetailURL(news: AnyObject, target_blank: boolean = false): string {
    let url = '';
    const news_id = news.id;
    if (!this.edit_modal) {
      if (this.modal === 'show') {
        // url = '/news/' + this.current_project_config.news_detail_page_id + '?news_id=' + news_id;
        url = '/news/' + this.generatePath(news.title || news.name, this.current_project_config.news_detail_page_id, news_id) + '/';
      } else {
        url = `/dashboard/preview/${this.projectID}/template/${this.current_project_config.news_detail_page_id}?news_id=${news_id}`;
      }
    }
    return location.origin + url;
  }

  get dataformat(): string {
    return sessionStorage.getItem('locale') === 'en_US' ? 'LL' : 'YYYY-MM-DD';
  }

  get shop_ids(): any {
    if (this.current_project.shop_id == this.current_project.main_shop_id) {
      const ids = this.current_project.shop_share_ids || [];
      ids.push(this.current_project.shop_id)
      return ids;
    } else {
      return this.current_project.product_shared_by ? [this.current_project.shop_id, this.current_project.main_shop_id] : [this.current_project.shop_id];
    }
  }

  get is_main_shop(): boolean {
    return this.$current_shop.id === this.current_project.main_shop_id;
  }

  generatePath(url: string, id?: string, sec_id?: string, thir_id?: string): string {
    const urlStr = url.replace(/[\_\&\¥\$]/g, '-');
    const nameArr = getUrl(`${url}`, {
      custom: {
        '_': '-',
        '&': '-',
        '¥': '-',
        '$': '-'
      }
    }).split('-');
    const urlArr = [];
    nameArr.map(item => {
      if (item.length) {
        urlArr.push(item);
      }
    })
    if (!nameArr[0].length) {
      urlArr.push(url);
    }
    if (id) {
      urlArr.push(id);
    }
    if (sec_id) {
      urlArr.push(sec_id);
    }
    if (thir_id) {
      urlArr.push(thir_id);
    }
    return urlArr.join('-').toLocaleLowerCase();
  }

  getParamsFromUrl(url: string, index: number, type?: string): string {
    const url_arr = url.split('-');
    let params_arr = [];
    let page;
    // url_arr.reverse().map((item, index) => {
    //   // 最多只传三个参数
    //   if (item.includes('page')) {
    //   page = item.split('page')[1];
    //   }
    //   if (!isNaN(Number(item)) && index <= 3) {
    //     params_arr.push(item);
    //   }
    // });

    const reverse_url_arr = url_arr.reverse();
    for (let i = 0; i < reverse_url_arr.length; i++) {
      const item = reverse_url_arr[i]
      // 最多只传三个参数
      if (isNaN(Number(item)) && !item.includes('page')) {
        break;
      }

      if (item.includes('page')) {
        page = item.split('page')[1];
      }
      if (!isNaN(Number(item)) && index <= 3) {
        params_arr.push(item);
      }
    }

    params_arr = params_arr.reverse();
    if (type === 'page') {
      return page;
    }
    return params_arr[index];
  }

  appendChildToBody(code): void {
    const script_arr = document.body.querySelectorAll('script');
    for (let i = 0; i < script_arr.length; i++) {
      if (script_arr[i].type === 'application/ld+json') {
        document.body.removeChild(script_arr[i]);
      }
    }
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.appendChild(document.createTextNode(JSON.stringify(code)));
    document.body.appendChild(script);
  }

  appendChildToHead(code): void {
    const script_arr = document.head.querySelectorAll('script');
    for (let i = 0; i < script_arr.length; i++) {
      if (script_arr[i].type === 'application/ld+json') {
        document.head.removeChild(script_arr[i]);
      }
    }
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.appendChild(document.createTextNode(JSON.stringify(code)));
    document.head.appendChild(script);
  }

  appendCanonicalToHead(): void {
    const link = document.createElement('link');
    link.rel = 'canonical';
    link.href = location.href;
    document.head.appendChild(link);
  }

  appendTwitterCardToHead(metaName: string, content: string): void {
    const meta_arr = document.head.getElementsByTagName('meta');
    // for (let i =0; i < meta_arr.length; i++) {
    //   if (meta_arr[i].name === `twitter:${metaName}`) {
    //     meta_arr[i].parentNode.removeChild(meta_arr[i]);
    //   }
    // }
    const meta = document.createElement('meta');
    meta.name = `twitter:${metaName}`;
    meta.content = content;
    document.head.appendChild(meta);
  }

  appendOGtoHead(property: string, content: string): void {
    const meta_arr = document.head.getElementsByTagName('meta');
    // for (let i =0; i < meta_arr.length; i++) {
    //   if (meta_arr[i].getAttribute('property') === `og:${property}`) {
    //     meta_arr[i].parentNode.removeChild(meta_arr[i]);
    //   }
    // }
    const meta = document.createElement('meta');
    meta.setAttribute('property', `og:${property}`);
    meta.content = content;
    document.head.appendChild(meta);
  }

  setDocumentTitle(title: string): void {
    document.title = title + this.current_project.title_abbr
  }

  setDocumentDescription(description_meta: string): void {
    const desc_meta = Array.from(document.querySelectorAll('meta')).find(item => item.name == 'description');
    desc_meta.setAttribute('content', description_meta);
  }
  getTextFromRichText(str: string): string {
    const patt = /[0-9]+\.[0-9]+/;
    let target_str = str.replace(/<[^<>]+>/g, '').replace(/&nbsp;/gi, '').replace(patt, '');

    if (target_str.length > 200) {
      target_str = target_str.slice(0, 200) + '...'
    }
    return target_str;
  }

  appendAToPagination(page, searchKey): void {
    let base_url = location.href;
    base_url = base_url.split('?')[0];
    if (base_url.includes('-page')) {
      const index = base_url.indexOf('-page');
      base_url = base_url.slice(0, index);
    }

    document.querySelector('at-pagination').querySelectorAll('li').forEach(item => {
      if (item.title == 'First Page') {
        if (page != 1) {
          item.className = item.className.replace('at-pagination__item--active', '')
        } else {
          item.className = item.className + ' at-pagination__item--active';
        }
        item.innerHTML = `<a href='${base_url}-page1${searchKey ? '?searchKey=' + searchKey : ''}' style="color: ${page == 1 ? '#fff' : '#3F536E'}">1</a>`;
      };

      if (!isNaN(Number(item.title))) {
        if (page == item.title) {
          item.className = item.className + ' at-pagination__item--active';
        } else {
          item.className = item.className.replace('at-pagination__item--active', '')
        }
        item.innerHTML = `<a href='${base_url}-page${item.title}${searchKey ? '?searchKey=' + searchKey : ''}' style="color: ${page == item.title ? '#fff' : '#3F536E'}">${item.title}</a>`;
      }

      if (item.title.indexOf('Last Page:') !== -1) {
        const page_arr = item.title.split(':');
        if (page != page_arr[1]) {
          item.className = item.className.replace('at-pagination__item--active', '')
        } else {
          item.className = item.className + ' at-pagination__item--active';
        }
        item.innerHTML = `<a href='${base_url}-page${page_arr[1]}${searchKey ? '?searchKey=' + searchKey : ''}' style="color: ${page == page_arr[1] ? '#fff' : '#3F536E'}">${page_arr[1]}</a>`;
      }

    })
  }

  removeAttribute(): void {
    Array.from(document.querySelectorAll('a')).filter(item => item.getAttribute('href') === 'void').forEach(a => a.removeAttribute('href'));
  }

  getQueryValue(queryName: string): string {
    const reg = new RegExp(`(^|&)${queryName}=([^&]*)(&|$)`, "i")
    const val = window.location.search.substr(1).match(reg)
    if (val !== null) {
      return decodeURI(val[2])
    } else {
      return null
    }
  }

  getScreenSize() {
    const width = window.screen.availWidth;
    let size = 'medium';
    if (width < 720) {
      size = 'small';
    } else if (width > 960) {
      size = 'large';
    }
    return size;
  }
}
