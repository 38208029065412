/**
 * Created by atyun on 7/28/17.
 */

import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelper } from '../../../helper/http.helper';
import { Template } from '../../../model/common/template';
import { GraphqlBuilder } from '../../grapql/graphql_builder';
import { ContentsService } from './contents.service';

// import {StaticDataFactory} from "../../../static-templates-data/static-data-factory";

@Injectable()
export class TemplatesService {

  constructor(public http: HttpHelper, private content_service: ContentsService) {

  }

  public templates(search_params = {}): Observable<Template[]> {
    return this.http.AUTH_HTTP_GET('atyun/templates.json?per=10000', search_params).pipe(map(data => this.http.extractData(data).templates));
  }

  public operate_templates(search_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('atyun/manager/templates.json', search_params);
  }

  public update(id: string, update_params: any = {}): Observable<any> {
    update_params.template_id = id;
    return this.http.AUTH_HTTP_UPLOAD_PUT('atyun/templates/' + id + '.json', update_params);
  }

  public delete(id: string): Observable<any> {
    const params = {template_id: id};
    return this.http.AUTH_HTTP_DELETE('atyun/templates/' + id + '.json', params);
  }

  public create(create_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_POST('atyun/templates.json', create_params);
  }

  public contents(search_params = {}): Observable<Template[]> {
    return this.http.AUTH_HTTP_GET('atyun/templates/contents.json', search_params).pipe(map(data => this.http.extractData(data).templates));

  }

  public chint_contents(search_params = {}): Observable<Template[]> {
    return this.http.AUTH_HTTP_GET('atyun/templates/chint_contents.json', search_params).pipe(map(data => this.http.extractData(data).templates));

  }

  public footer_contents(search_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('atyun/templates/footer_contents.json', search_params).pipe(map(data => this.http.extractData(data)));

  }

  public copy(params = {}): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_POST('atyun/manager/templates/copy.json', params);
  }

  public update_chint_section(params: any = {}): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_PUT('atyun/templates/update_chint_section.json', params);
  }

  public create_chint_section(params: any = {}): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_POST('atyun/templates/create_chint_section.json', params);
  }

  public remove_chint_section(id: string): Observable<any> {
    const params = {id};
    return this.http.AUTH_HTTP_DELETE('atyun/templates/remove_chint_section.json', params);
  }

}
