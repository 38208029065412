/**
 * Created by atyun on 7/31/17.
 */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelper } from '../../../helper/http.helper';

@Injectable()
export class UserService {

  constructor(public http: HttpHelper, private httpClient: HttpClient) {

  }

  public users(search_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('atyun/users.json', search_params);
  }

  public update(id: string, update_params: any = {}): Observable<any> {
    update_params.user_id = id;
    return this.http.AUTH_HTTP_PUT('atyun/users/' + id + '.json', update_params).pipe(map(data => this.http.extractData(data, true)));
  }

  public create(create_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_POST('atyun/users.json', create_params);
  }

  public sign_in(user: any, password: any): Observable<any> {
    const params = {phone: user, password};
    return this.httpClient.post('/atyun/users/sign_in.json', params);
  }

  public sso_sign_in(service_ticket: string): Observable<any> {
    return this.httpClient.get('/atyun/users/sso_sign_in.json?service_ticket=' + service_ticket);
  }

  public send_check_code(phone: any, code_type = ''): Observable<any> {
    const params = {
      phone,
      code_type
    };
    return this.http.AUTH_HTTP_POST('/atyun/users/send_check_code.json', params).pipe(map(data => this.http.extractData(data, true)));
  }

  public sign_out(): void {
    localStorage.clear();
  }

  public reset_pwd_email(email) {
    const params = {
      email,
      host: window.location.origin
    };
    return this.http.AUTH_HTTP_POST('/atyun/users/reset_pwd_email.json', params);
  }

  public reset_pwd(token, password) {
    const params = {
      confirm_token: token,
      password
    };
    return this.http.AUTH_HTTP_POST('/atyun/users/reset_pwd.json', params);
  }

  public batch_add_designers(id: string, update_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_PUT('atyun/manager/designer_groups/' + id + '.json', update_params);
  }

  public my_packages() {
    return this.http.AUTH_HTTP_GET('/atyun/users/get_user_package.json');
  }

  public info() {
    return this.http.AUTH_HTTP_GET('/atyun/users/website_info.json');
  }

  public use_project_fee(project_id) {
    return this.http.AUTH_HTTP_POST('/atyun/users/use_package_fee.json', {project_id});
  }

  public addPermission(create_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_POST('atyun/users/add_permission.json', create_params);
  }

  public removePermission(delete_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_DELETE('atyun/users/remove_permission.json', delete_params);
  }

  public checkLogin(): Observable<any> {
    return this.httpClient.get('/atyun/users/check_login_status.json');
  }

}
