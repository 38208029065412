import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AtModule } from 'at-ng';
import { SectionMenuComponent } from '../../app/components/section-menu/section-menu.component';
import { ClickRippleDirective } from '../../app/diretives/click-ripple.directive';
import { AutoSaveService } from '../../app/services/auto-save.service';
import { GraphqlBuilderService } from '../../app/services/graphql_builder.service';

@NgModule({
  imports: [
    CommonModule,
    AtModule
  ],
  declarations: [
    SectionMenuComponent,
    ClickRippleDirective
  ],
  exports: [
    SectionMenuComponent,
    ClickRippleDirective
  ]
})
export class SectionMenuModule {
  static withProviders(): ModuleWithProviders {
    return {
      ngModule: SectionMenuModule,
      providers: [AutoSaveService, GraphqlBuilderService]
    };
  }
}
