import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelper } from '../../../helper/http.helper';

@Injectable()
export class ProjectConfigService {

  constructor(private http: HttpHelper) {
  }

  public project_configs(search_params = {}): Observable<any[]> {
    return this.http.AUTH_HTTP_GET('atyun/project_configs.json?per=10000', search_params).pipe(map(data => this.http.extractData(data).project_configs));
  }

  public update(id: any, update_params: any = {}): Observable<any> {
    update_params.project_config_id = id;
    return this.http.AUTH_HTTP_UPLOAD_PUT('atyun/project_configs/' + id + '.json', update_params);
  }

  public delete(id: string): Observable<any> {
    const params = {project_config_id: id};
    return this.http.AUTH_HTTP_DELETE('atyun/project_configs/' + id + '.json', params);
  }

  public create(create_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_POST('atyun/project_configs.json', create_params);
  }

  public change_model_seq(params = {}): Observable<any> {
    return this.http.AUTH_HTTP_POST('atyun/project_configs/change_model_seq.json', params);
  }
}
