var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AtMessageService } from 'at-ng';
import * as moment from 'moment';
import { DataHelper } from '../../helper/data.helper';
import { TemplatesService } from '../services/content/template.service';
import { ContentsService } from './content/contents.service';
import { ProjectConfigService } from './content/project-config.service';
import { GlobalService } from './global.service';
import { MainInjectorService } from './main-injector.service';
var AutoSaveService = /** @class */ (function () {
    function AutoSaveService(nz_message_service, content_service, data_helper, global_service, template_service, project_config_service) {
        this.nz_message_service = nz_message_service;
        this.content_service = content_service;
        this.data_helper = data_helper;
        this.global_service = global_service;
        this.template_service = template_service;
        this.project_config_service = project_config_service;
        this.last_save_time = moment();
        this.save_interval = 15;
    }
    AutoSaveService.prototype.save = function (content, callback) {
        var _this = this;
        if (this.global_service.edit_modal) {
            if (content.id == '' || content.id == undefined) {
                var create_params = {
                    create: content
                };
                this.content_service.create_section(create_params).subscribe(function (data) {
                    _this.global_service.saveStatus.saving(false).text('保存成功！');
                    content.id = data.content.id;
                    callback && callback(data);
                    _this.global_service.set_editing_template();
                });
            }
            else {
                var update_params = {
                    content_id: content.id,
                    update: content
                };
                this.content_service.update_section(update_params).subscribe(function (data) {
                    _this.global_service.saveStatus.saving(false).text('保存成功！');
                    // this.global_service.render_contents()
                    callback && callback(data);
                    _this.global_service.set_editing_template();
                });
            }
        }
    };
    // 板块间顺序变换自动保存
    AutoSaveService.prototype.save_template = function (template) {
        if (this.global_service.edit_modal) {
            var params = {
                model: 'content',
                seqs: template.contents.map(function (content) {
                    return { id: content.id, seq: content.seq };
                })
            };
            this.project_config_service.change_model_seq(params).subscribe();
        }
    };
    AutoSaveService.prototype.setHistory = function (content) {
        var contentHistory = MainInjectorService.getInstance().contentHistories;
        var node = contentHistory.append(__assign({}, content));
        contentHistory.setCurrent(node);
    };
    AutoSaveService.prototype.update_model_seq = function (model_name, models) {
        if (this.global_service.edit_modal) {
            var params = {
                model: model_name,
                seqs: models.map(function (m) {
                    return { id: m.id, seq: m.seq };
                })
            };
            this.project_config_service.change_model_seq(params).subscribe(function (data) {
            });
        }
    };
    return AutoSaveService;
}());
export { AutoSaveService };
