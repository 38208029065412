/**
 * Created by cml on 2017/12/5.
 */

import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { AnyObject } from '../../types/custom_access_value';

@Injectable()
export class ImageCategoryService extends BaseService {

    // constructor(private http: HttpHelper) {
    //
    // }

    public ImageCategorys(search_params = {}): Observable<any[]> {
        return this.http.AUTH_HTTP_GET('atyun/image_categories.json?per=10000', search_params).pipe(map(data => this.http.extractData(data).image_categories));
    }

    public update(id: string, update_params:AnyObject = {}): Observable<any> {
        update_params.image_category_id = id;
        return this.http.AUTH_HTTP_PUT('atyun/image_categories/' + id + '.json', update_params);
    }

    public create(create_params = {}): Observable<any> {
        return this.http.AUTH_HTTP_POST('atyun/image_categories.json', create_params);
    }

}
