import { I18nInterface } from '../i18n.interface';

export const en_US: I18nInterface = {
  locale: 'en_US',
  common: {
    edit: 'Edit',
    data: 'Data',
    setting: 'Setting',
    my_sites: 'Sites',
    user_center: 'Profile',
    my_orders: 'Orders',
    my_content: 'News Center',
    my_member: 'Member Center',
    my_form: 'Forms',
    my_document: 'Document Center',
    my_package: 'Subscriptions',
    log_out: 'Sign out',
    order: 'Sort By',
    days_ago: 'days ago',
    minutes_ago: 'minutes ago',
    hours_ago: 'hours ago',
    language: 'Language',
    hello: 'hello',
    is_saving: 'Saving',
    save_succeed: 'Save successfully',
    product_center: 'Product Center',
    product_label: 'Product Tags',
    product_category: 'Product Categories',
    product_management: 'Products',
    brands: 'Product Brands',
    user_management: 'Users',
    shops: 'Shops',
    shop_management: 'Shop Management',
    promotion: 'Promotions',
    promotion_category: 'Promotion Categories',
    website_name: 'CHINT Oversea Websites'
  },
  common_locale: {
    edit_document: 'Edit Document',
    batch_upload: 'Batch Upload',
    batch_edit: 'Batch Edit',
    cancel_top: 'Unpin',
    move_top: 'Topping',
    document_type_code: 'Code',
    end_time: 'Expiration Date',
    confirm_delete: 'Confirm',
    confirm_edit: 'Confirm Edit',
    content_tag: 'Content Tags',
    total: 'total',
    page: 'page',
    edit: 'Edit',
    show: 'Show',
    delete: 'Delete',
    batch_delete: 'Delete',
    new_data: 'New',
    name: 'Name',
    english_name: 'English Name',
    status: 'Status',
    new_content_category: 'New Tag',
    keywords: 'Keyword',
    current_records: 'Current records',
    count: '',
    desc: 'Description',
    parent_node: 'Parent Node',
    thumbnail: 'Thumbnail',
    operation: 'Operations',
    edit_category: 'Edit Category',
    confirm: 'Confirm',
    cancel: 'Cancel',
    time: 'Time',
    seq: 'Seq',
    seq_info: 'The larger the number ranked the top',
    input_info: 'Name is required',
    index_page: 'First Page',
    search: 'Query',
    last_page: 'Last Page',
    preview: 'Preview',
    download: 'Download',
    errors: {
      not_check: 'You have not check categories.'
    },
    create_success: 'Create Successful',
    update_success: 'Update Successful',
    delete_success: 'Delete Successful',
    create_failed: 'Create Failed',
    update_failed: 'Update Failed',
    delete_failed: 'Delete Failed',
    creating: 'Creating...',
    updating: 'Updating...',
    deleting: 'Deleting...',
    update_time: 'Update Time',
    create_time: 'Created Time',
    updater: 'Updater',
    creator: 'Creator',
    active: 'Active',
    all: 'All',
    check: 'Check',
    ban: 'Ban',
    active_a: 'Active',
    inactive: 'Inactive',
    permission: 'Permission',
    edit_permission: 'Edit Permission',
    pls_chose_item: 'Please chose the data you are going to delete',
    yes: 'Yes',
    no: 'No',
    password: 'Password',
    password_required: 'Password Required',
    password_confirm: 'Confirm Password',
    input_password_again: 'Please input the password again',
    password_different: 'Inconsistent input password twice',
    pls_input_password: 'Please input the password',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    reset: 'Reset',
    export: 'Export',
    confirm_export: 'Confirm Export',
    export_left: 'You are going to export',
    export_right: 'data, are you sure?',
    batch_export_select: 'You have not selected the data to export',
    email_config: 'Email Config',
    company_name_required: 'Company name is required',
    profile_required: 'Profile is required',
    document_config: 'Document Config',
    news_config: 'News Config',
    share: 'Share',
    shared_by: 'Shared By',
    config: 'Config',
    password_length_error: 'Password length 6 - 20',
    export_all: 'Export All',
    export_all_str: 'You need to export all the data this time, are you sure?'
  },
  pages: {
    projects: {
      new: 'New Site',
      site_info: 'Site Info',
      site_count: 'Total',
      online_site_count: 'Online',
      package_expired_in: 'due ',
      none_package: 'Don\'t have any subscription',
      days_left: ' days left',
      paid_site_count: 'Paid',
      used_site_count: 'Used',
      message: 'Messages',
      history_versions: 'Versions',
      latest_version: 'Latest Version',
      original_version: 'Original Version',
      online: 'Online',
      offline: 'Offline',
      site_online: 'online',
      site_offline: 'offline',
      free: 'Free',
      paid: 'Paid',
      recently_reading: 'Recent traffic',
      updated_at: 'Updated at',
      created_at: 'Created at',
      please: 'Please',
      renew: 'Renew Immediately',
      or: 'or',
      upgrade_package: 'Update Package',
      recently_updated: 'updated at',
      recently_created: 'created at',
      delete_site: 'Delete',
      use_package: 'Use Package',
      add_version: 'Add To Versions',
      add_soon: 'Create And Experience Tight Now.',
      add_to_template_library: 'Add To Template Library',
      add_new_project: 'Create New Website',
      name_of_template: 'name of template',
      cover_of_template: 'cover of template',
      name_of_project: 'Name of Website',
      modal_message: {
        confirm_delete: 'Are you sure you want to delete it? After deletion, the site data will be emptied.',
        confirm_online: 'Confirm to go online?',
        confirm_offline: 'Confirm to go offline?',
        has_online: 'Your website has been successfully online',
        has_offline: 'Your website has been successfully offline',
        succeed: 'Successful',
        adding: 'Please wait patiently while you add a version to your background.'
      },
      content_category: 'News Categories',
      content_tag: 'News Tags',
      news: 'News',
      new_project: {
        choose_template: 'Please choose a template',
        template_type: {
          all: 'All',
          internet: 'Internet',
          health_care: 'Health Care',
          talent_consultation: 'Talent Consultation'
        },
        preview: 'preview',
        use: 'use',
        create_now: 'Create instantly without templates.',
        no_template: 'Can not find the right template, please ',
        contact_service: 'contact customer service.',
        login_first: 'Please login first',
        my_site: 'My site',
        site: '\'s site'
      },
      project_data: {
        PV: 'PV',
        UV: 'UV',
        from: 'from',
        to: 'to',
        query: 'query',
        last_7_days: 'last 7 days',
        last_3_months: 'last 3 months',
        last_1_year: 'last 1 year',
        chart_option: {
          number_of_clicks: 'number of clicks',
          total: 'total',
          IP_access_times: 'IP access times'
        }
      },
      custom_form: {
        created_at: 'Created at:',
        form_data: 'Form Data',
        all_messages: 'All messages',
        time: 'Time'
      }
    },
    user_center: {
      new_password_confirm: 'Password Confirm',
      change_profile: 'Edit profile',
      email: 'Email',
      phone: 'Phone no.',
      current_password: 'Current Password',
      new_password: 'New Password',
      change_phone: 'Edit',
      change_email: 'Edit',
      update: 'Update',
      placeholder: {
        email: 'Your favourite Email',
        phone_no: 'Please input your phone no.',
        current_password: 'Your current password',
        new_password: 'Input Your New Password',
        phone_validate_code: 'Validate code',
        get_validate_code: 'Send Validate Code',
        new_password_confirm: 'Input The Password Again'
      },
      error: {
        blank_phone: 'Please input phone no.',
        true_email: 'Please input correct email',
        true_phone: 'Please input correct phone no.',
        blank_code: 'Please input code',
        error_code: 'Please input correct code',
        blank_password: 'Please input password',
        error_password: 'Please input correct password',
        none_update: 'You don\'t need to update your profile',
        password_restriction: 'password must in 6-20 length',
        password_not_same: 'Inconsistent input password twice'
      },
      orders: {
        no_data: 'Empty Data',
        order_no: 'Order NO.',
        desc: 'Description',
        status: 'Status',
        order_fee: 'Order Fee',
        pay_fee: 'Payed Fee',
        pay_type: 'Pay Way',
        pay_time: 'Pay time',
        help_doc_prefix: 'Need more help,Please read',
        help_doc: 'help doc',
        my_order: 'My orders'
      },
      packages: {
        package_type: 'Package Type',
        site_count: 'Site amount',
        status: 'Status',
        expired_in: 'Expired In',
        my_package: 'Packages',
        help_doc: 'help doc',
        help_doc_prefix: 'Need more help,Please read',
        renew: 'Renew',
        buy_more: 'Buy more',
        no_packages: 'No packages',
        buy_packages: ' buy packages'
      },
      modify_info_succeed: 'Successful revision of information',
      code_error: 'Verification code error',
      password_error: 'Password error',
      send_code_succeed: 'Verification code was sent successfully'
    },
    member: {
      dealer: 'Active',
      update_confirm: 'Are you sure you want to update?',
      register_at: 'Register At',
      member_label: 'Member Tags',
      member: 'Members',
      keyword: 'Keywords',
      status: 'Status',
      name: 'Name',
      mobile_phone: 'Phone',
      email: 'Email',
      full_name: 'Real Name',
      nick_name: 'Nick Name',
      company_name: 'Company',
      add_member: 'New',
      batch_delete_member: 'Delete',
      confirm_delete_member: 'Confirm',
      search: 'Query',
      portrait: 'Portrait',
      data_source: 'Data Source',
      options: 'Options',
      all: 'All',
      active: 'checking',
      check: 'Email Checking...',
      ban: 'Ban',
      sign: 'Sign',
      message: 'Message',
      question: 'Question',
      system: 'System',
      pass: 'Pass',
      show: 'Show',
      edit: 'Edit',
      remove: 'Delete',
      new_label: 'New',
      update_time: 'Update Time',
      edit_permission: 'Edit Permission',
      active_a: 'Active',
      inactive: 'Inactive',
      delete_left: 'You are going to delete',
      delete_right: 'data, are you sure?',
      delete_confirm: 'Are you sure you wan\'t to delete it ?',
      description: 'Description',
      create_time: 'Create Time',
      updater: 'Updater',
      creator: 'Creator',
      seq: 'Seq',
      permalink: 'Permalink',
      picture: 'Picture',
      parent: 'Parent',
      create_label: 'Create Tag',
      edit_label: 'Edit Tag',
      phone_failed: 'Please input the correct phone number',
      email_failed: 'Please input the correct email',
      birthday: 'Birthday',
      type_of_certificate: 'Type Of Certificate',
      work_permit: 'Work Permit',
      id_card: 'ID Card',
      passport: 'Passport',
      certificate_number: 'Certificate Number',
      country: 'Country',
      province: 'Province',
      city: 'City',
      connect_phone: 'Connect Phone',
      fax: 'Fax',
      connect_address: 'Address',
      address_code: 'Address Code',
      state: 'State',
      profile: 'Profile',
      register_time: 'Register Time'
    },
    news_information: {
      news_information: 'News',
      title: 'Title',
      status: 'Status',
      author: 'Author',
      search: 'Query',
      classification: 'Category',
      label: 'Tag',
      time: 'Updated At',
      create_button: 'New',
      batch_delete: 'Delete',
      today: 'Today',
      yesterday: 'Yesterday',
      seven_day: 'Seven Days',
      option: 'Options',
      confirm_delete: 'Confirm',
      all: 'All',
      draft: 'Draft',
      published: 'Published',
      create_news_information: 'Create News',
      edit_news_information: 'Edit News',
      title_must_input: 'Please Input The Title',
      sub_title: 'Sub Title',
      summary: 'Summary',
      extract_from: 'Extracted from the text',
      keyword: 'Keywords',
      parent_node: 'Parent Node',
      create_new_classification: 'Create News Category',
      create_new_label: 'Create News Tag',
      cover: 'Cover',
      content_text: 'Content',
      data_source: 'Data Source',
      data_source_link: 'Data Source Link',
      seq: 'Seq',
      is_top: 'Whether To Top',
      is_essence: 'Whether The Essence',
      read_times: 'Number Of Times Read',
      collect_times: 'Number Of Times Of Collect',
      share_times: 'Number Of Times Shared',
      permalink: 'Permalink',
      blank: 'Blank',
      delete_confirm: 'Remove The News',
      updater: 'Updated by',
      release_time: 'Release time',
      prompt_str: 'Within 200 characters',
      file_big: 'Cover and content Recommend image size :1200*800px, image format :JPEG, image size: less than 200KB'
    },
    document: {
      document_management: 'Documents',
      document_category: 'Document Categories',
      document_type: 'Document Types',
      document_language: 'Document Languages',
      document_label: 'Document Tags',
      code: 'Code',
      version: 'Version',
      size: 'Size',
      document_file: 'File List',
      date: 'Date',
      is_require: 'is require',
      parent_version: 'Parent',
      new_document: 'New Document',
      edit_document: 'Edit Document',
      batch_delete_select: 'You have not selected the data to delete',
      batch_edit_select: 'You have not selected the data to edit',
      filename: 'File Name',
      download_times: 'Download Times',
      new_file: 'New File',
      edit_file: 'Edit File',
      file: 'File',
      click_upload: 'Click Upload',
      new_document_type: 'New Document Type',
      edit_document_type: 'Edit Document Type',
      go_document: 'Show Document',
      only_parent_search: 'Search Parent Category Only',
      new_document_category: 'New',
      edit_document_category: 'Edit Document Category',
      blank: 'Blank',
      public: 'Public',
      password: 'Password',
      member: 'Member',
      manager: 'Manager',
      owner: 'Owner',
      member_hint: 'To enable member access, be sure to enable login registration on your website.',
      new_document_language: 'New Document Language',
      edit_document_language: 'Edit Document Language',
      new_document_label: 'New Document Tag',
      edit_document_label: 'Edit Document Tag',
      category: 'Category',
      type: 'Type',
      label: 'Tag',
      language: 'Language',
      none: 'None',
      file_type: 'Business Type',
      product_sample: 'Product Sample',
      certificate: 'Certificate',
      system_certification: 'System Certification',
      test_report: 'Test Report',
      resource_document: 'Belongs To'
    },
    content: {
      content_category: {
        confirm_delete: 'Confirm',
        content_category: 'News Categories',
        total: 'Total',
        page: 'Page',
        edit: 'Edit',
        show: 'Show',
        delete: 'Delete',
        batch_delete: 'Delete',
        name: 'Name',
        status: 'Status',
        new_content_category: 'New',
        keywords: 'Keywords',
        current_records: 'Current Records',
        count: '',
        desc: 'Description',
        parent_node: 'Parent Node',
        thumbnail: 'Thumbnail',
        operation: 'Operations',
        edit_category: 'Edit Category',
        confirm: 'Confirm',
        cancel: 'Cancel',
        time: 'Time',
        seq: 'Seq',
        seq_info: 'The larger the number ranked the top',
        input_info: 'Name is required',
        index_page: 'First Page',
        search: 'Query',
        last_page: 'Last Page',
        all: 'All',
        active: 'Active',
        inactive: 'Inactive',
        blank: 'Blank',
        errors: {
          not_check: 'You have not check categories.'
        }
      },
      content_tag: {
        confirm_delete: 'Confirm',
        content_tag: 'News Tags',
        total: 'Total',
        page: 'Page',
        edit: 'Edit',
        show: 'Show',
        delete: 'Delete',
        batch_delete: 'Delete',
        new_data: 'New Data',
        name: 'Name',
        status: 'Status',
        new_content_category: 'New',
        keywords: 'Keywords',
        current_records: 'Current Records',
        count: '',
        desc: 'Description',
        parent_node: 'Parent Node',
        thumbnail: 'Thumbnail',
        operation: 'Operations',
        edit_category: 'Edit Category',
        confirm: 'Confirm',
        cancel: 'Cancel',
        time: 'Time',
        seq: 'Seq',
        seq_info: 'The larger the number ranked the top',
        input_info: 'Name Is Required',
        index_page: 'First Page',
        search: 'Query',
        last_page: 'Last Page',
        preview: 'Preview',
        download: 'Download',
        errors: {
          not_check: 'You have not check categories.'
        }
      }
    },
    login: {
      placeholder: {
        email_or_phone: 'email/phone',
        password: 'password'
      },
      account_login: 'account login',
      automatic_login_next: 'automatic login next time',
      forget_password: 'forget password?',
      home: {
        customize_websites: 'Customize your personalized website together',
        new: 'New Site',
        WYS_WYG: 'WYS|WYG',
        find_more: 'Find more',
        enterprise_package: 'Enterprise Package',
        apply_for_trial: 'Apply',
        contact_us: 'Contact Us',
        submit: 'submit',
        reset: 'reset',
        join_us: 'Join Us',
        about_us: 'About Us',
        help_center: 'Help Center',
        placeholder: {
          name: 'name',
          phone: 'phone',
          email: 'email',
          feedback: 'feedback'
        },
        wrong_email: 'Please fill in the correct email address.',
        wrong_form: 'Please complete the form.',
        sending: 'sending',
        thanks: 'Thanks for feedback'
      },
      common: {
        sign_out: 'sign out',
        login: 'login',
        sign: 'sign',
        privacy_statement: 'Privacy Statement',
        legal_notices: 'Legal Notices',
        terms_of_service: 'Terms Of Service'
      },
      check: {
        sign: 'sign',
        apply: 'apply',
        waiting: 'waiting',
        succeed: 'succeed',
        improve_application_materials: 'Improve pplication materials',
        after_application: 'After the application is submitted, we will verify the application information with you. Please be sure to fill in the form.',
        name: 'name',
        company: 'company',
        phone: 'phone',
        code: 'code',
        get_code: 'get code',
        resend: 's',
        other_message: 'other message',
        submit: 'submit',
        thanks: 'Thanks for apply',
        stage: 'stage.',
        account_check: 'During the platform trial run, the functions provided by the platform can be used after review. The staff will complete the account review within 1~3 business days.',
        send_to: 'The latest review progress will be sent to',
        period: '.',
        pass_check: 'Congratulations, you have passed the review! Start customizing your personalized website now!',
        new: 'New Site',
        wrong_phone: 'Please fill in the correct phone number.',
        wrong_form: 'Please complete the form.'
      },
      alipay_jump: {
        waiting: 'eqding:Jumping to the Alipay payment page, please wait...'
      }
    },
    register: {
      use_email: 'Sign by email',
      sign: 'sign now',
      accept: 'I have read and accepted',
      terms_of_service: ' Terms Of Service',
      placeholder: {
        email: 'Please enter your email address.',
        password: 'Please enter a 6-20-bit password.'
      },
      message: {
        sign_failed: 'Registration failed, please try again!',
        wrong_email: 'Please enter the correct email address!！',
        wrong_password: 'Please enter a 6-20-bit password!',
        not_accept: 'Please read and accept the Terms of Service first.'
      }
    },
    reset_password: {
      retrieve_password: 'Retrieve password',
      next_step: 'Next',
      sent: 'The mail has been sent',
      check_now: 'check now',
      reset_password: 'Reset password',
      complete: 'Complete',
      placeholder: {
        email: 'Please enter the email address at the time of registration.',
        password: 'Setting a new password',
        confirm_password: 'Please confirm the new password'
      },
      message: {
        succeed: 'The password was changed successfully. Please login again.',
        wrong_password: 'Please enter 6-20 password',
        differ_password: 'Entered passwords differ'
      }
    },
    dashboard: {
      back: 'Back',
      preview: 'Preview',
      offline: 'Offline',
      online: 'Online',
      setting: 'Setting',
      menu: 'Menu',
      page_management: 'Page Management',
      save: 'Save',
      edit: 'Edit',
      delete: 'Delete',
      login_page: 'Login Page',
      add_page: 'Add Page',
      exit_editor: 'Exit',
      add_template: 'Add Block',
      set_page_modal: {
        title: 'Setting',
        page_name: 'Name',
        page_desc: 'Description',
        page_keyword: 'Keyword',
        business_type: 'Business Type',
        page_title: 'Title',
        page_head: 'Custom Head Content',
        page_body: 'Custom Body Content',
        page_title_abbr: 'Brand word'
      },
      add_page_modal: {
        title: 'Add Page',
        custom_name: 'Custom Name'
      },
      add_to_template_modal: {
        title: 'Add To Templete',
        template_name: 'Template Name'
      },
      set_site_properties_modal: {
        title: 'Set Site Properties',
        basic_setting: {
          title: 'Basic setting',
          site_title: 'Title',
          site_description: 'Description',
          site_keyword: 'Keyword',
          site_category: 'Category',
          site_categories: {
            shop: 'Shop',
            offical: 'Offical',
            show: 'Show',
            personal: 'Personal'
          },
          site_language: 'Language',
          message_mailbox: 'Message Mailbox',
          second_domain: 'Second Domain',
          custom_domain: 'Custom Domain',
          site_logo: 'Logo',
          show_navigation: 'Navigation',
          open_document_center: 'Open Document Center',
          open_search: 'Open Search'
        },
        site_share: {
          title: 'Share',
          share_imgae_big: 'Share Picture(big)',
          share_image_samll: 'Share Picture(small)'
        },
        privacy_setting: {
          title: 'Privacy Setting',
          shielding_search_engine: 'Shielding Eearch Engine'
        },
        service_and_validation: {
          title: 'Service And Validation',
          baidu_statistics_key: 'Baidu Statistics Key',
          google_analytics_tracker: 'Google Analytics Tracker',
          placeholder: {
            baidu: 'Please fill in the HTML tag verification code provided by Baidu.(ex: NQ53KRpuNy)',
            google: 'ex: UA-XXXXXXX-X'
          }
        },
        data_analysis: {
          title: 'Data Analysis',
          data_analysis_and_traffic: 'Data Analysis And Traffic',
          click_to_data: 'Click To Check Data'
        },
        header_and_footer: {
          title: 'Header And Footer',
          show_header: 'Navigation',
          show_footer: 'Footer',
          navigation: 'Navigation',
          footer: 'Footer'
        },
        custom_content: {
          title: 'Custom Content',
          title_head: 'Custom Head Content',
          title_body: 'Custom Body Content'
        },
        sitemap: {
          title: 'Sitemap',
          content: 'Content'
        },
        robots: {
          title: 'robots.txt',
          content: 'Content'
        },
        system_setting: {
          title: 'System Setting',
          auto_save: 'Automatic Save Interval（s）'
        },
        sign_and_login: {
          title: 'Sign And Login',
          show_sign_and_login: 'Sign And Login',
          account_type: 'account type',
          email: 'Email Address',
          phone: 'Phone Number',
          check_type: 'Check Type',
          manual: 'Manual',
          auto: 'Auto',
          sign_type: 'Sign Type',
          account: 'Account Verification',
          password: 'Set Password',
          sign_picture: 'CAPTCHA at Registration',
          login_picture: 'CAPTCHA at Login',
          service_agreement: 'Service Agreement'
        },
        access_right: {
          title: 'Access Right',
          access_control: 'Access Control',
          site_access_restrictions: 'Access Restrictions',
          public: 'Public',
          password: 'Password',
          member: 'Member',
          everyone_has_access: 'Everyone has access to the website.',
          access_password: 'Access Password',
          enter_the_correct_password: 'Enter the correct password to access the website.',
          membership_scope: 'Membership Scope',
          all: 'All',
          checked: 'Checked',
          member_label: 'Member Tag',
          passed_the_audit: 'Members who have passed the audit can visit the website only after they log in.',
          permission: 'Members with the above membership tags can visit the website only after they log in.',
          page_access_control: 'Page Access Control',
          project: 'The Same As Website Settings',
          custom: 'Custom'
        }
      },
      add_new_block: {
        title: 'Add New Block',
        system_block: 'System',
        personal_block: 'Personal',
        delete: 'Delete？'
      },
      system_block: {
        'y-form': 'Form',
        'y-navbar': 'Navigation',
        'y-news-information': 'News Information',
        'y-title': 'Paragraph',
        'y-product-card': 'Product Card',
        'y-carousel': 'Carousel',
        'y-footer': 'Footer',
        'y-freedom': 'Free Customization',
        'y-product-desc': 'Product Description',
        'y-product-recruit': 'Recruit',
        'y-workflow': 'Workflow',
        'y-slider': 'Slider',
        'y-hr': 'Dividing Line',
        'y-handbook': 'Handbook',
        'y-widget': 'Widget',
        'y-custom-section': 'Grid Layout',
        'y-photo-album': 'Photo Album',
        'y-news': 'News',
        'y-category-list': 'Category List',
        'y-product-detail': 'Product Detail',
        'y-product-list': 'Product List'
      },
      tip_modal: {
        title: 'Tips',
        tip: 'Setting Access Control - Registered Members Need to Open Registered Login。'
      },
      ok: 'OK',
      cancel: 'Cancel',
      new_page: 'New Page',
      confirm_delete: 'Delete？',
      add_page_first: 'Please add page first!',
      confirm_online: 'Confirm to go online?',
      confirm_offline: 'Confirm to go offline?',
      has_online: 'Your website has been successfully online',
      has_offline: 'Your website has been successfully offline',
      login_and_register: 'Login And Register',
      validate_logon: 'Validate Login',
      login_title: 'Log on to the website and enjoy easy-to-set high-quality services',
      login_subtitle: 'Jing Bei XXXXXXXXXXX',
      document: {
        center_name: 'Download Center',
        search_name: 'Search List',
        detail_name: 'Document Detail',
        center_file_name: 'Download File',
        search_title: 'Search Page',
        search_subtitle: 'Search products, documents, news and pages',
        center_title: 'Download files, support information, software, video and audio content.'
      },
      es_search: {
        search_center: 'Search Center',
        search: 'Search',
        search_list: 'Search List'
      },
      new_template_name: 'New Page'
    },
    drawer_group: {
      navigation: 'Navigation',
      add_new_item: 'Add New Item',
      language_setting: 'Site Settings',
      add_language: 'Add Site',
      add: 'Add',
      button_setting: 'Button Settings',
      add_button: 'Add Button',
      menu_name: 'Name',
      jump_page: 'Page',
      jump_block: 'Block',
      jump_link: 'Link',
      add_new_menu: 'Add New Menu',
      menu: 'Menu',
      alignment_at_both_ends: ' Justify Align ',
      compact_center: 'Compact Center',
      is_saving: 'Saving...',
      save_succeed: 'Save Successfully',
      is_adding: 'Adding...',
      add_succeed: 'Add Successfully',
      confirm_delete_button: 'Are you sure to delete this data?',
      confirm_delete_language: 'Are you sure to delete this data?',
      delete_button_succeed: 'Delete data Successfully',
      delete_language_succeed: 'Delete data Successfully',
      new_nav_link: 'New Link',
      chint_menu_type: 'Type',
      product_label: 'Product Tag',
      open_new_tab: 'Open New Tab',
      link: 'Link',
      product: 'Product',
      common: 'Common',
      member: 'Only Member',
      language_name: 'language'
    }
  },
  component: {
    config_drawer: {
      basic_setting: 'Basic Setting',
      name: 'Name',
      to_personal_block: 'Add to personal blocks',
      placeholder: {
        new_content: 'New Content',
        custom_color: 'custom color #fff'
      },
      hide_category: 'Hide category',
      use_menu: 'Dynamic content management using menus.',
      apply_to_all_pages: 'Apply to all pages.',
      open_advanced_search: 'Hide advanced search',
      delete: 'Remove',
      theme: 'Theme',
      icon: 'Icon',
      qrcode: 'QRCode',
      background: 'Background',
      column_layout: 'Column Layout',
      column: 'Column',
      pictures_and_links: 'Pictures And Links',
      external_link: 'External Link',
      sequence: 'Sequence',
      carousel: 'Carousel',
      slider: 'Slider',
      display: 'Sisplay',
      tiling: 'Tiling',
      locking: 'Locking',
      align: 'Align',
      filter: 'Filter',
      clip_path_left: 'Clip Path Left',
      clip_path_right: 'Clip Path Right',
      more: 'More',
      upload: 'Upload',
      effect: 'Effect',
      animation: 'Animation',
      speed: 'Speed',
      max_height: 'Max Height',
      navigation_effect: 'Navigation Effect',
      superposition_effect: 'Superposition Effect',
      selection_effect: 'Selection Effect',
      fixed_on_the_top: 'Fixed On The Top',
      news: 'News',
      choose_category: 'Choose Category',
      menu_left: 'Menu On Left',
      menu_right: 'Menu On Right',
      basic: 'Basic',
      display_settings: 'Display Settings',
      navigation_style: 'Navigation Style',
      text_color: 'Text Color',
      chosen_color: 'Chosen Color',
      on_top: 'On Top',
      element: 'Element',
      show_element: 'Show Element',
      button: 'Button',
      language: 'Language',
      logo: 'Logo',
      qrcode_icon: 'QRCode Icon',
      transparent: 'Transparent',
      mask: 'Mask',
      hrs: {
        dashed: 'Dashed',
        solid: 'Solid'
      },
      alignments: {
        center: 'Center',
        left: 'Left',
        right: 'Right'
      },
      animations: {
        common: 'Common',
        bounce: 'Bounce',
        fade: 'Fade',
        flip: 'Flip'
      },
      speeds: {
        none: 'None',
        quick: 'Quick',
        slow: 'Slow'
      },
      selected_animation_effects: {
        none: 'None',
        focus: 'Focus',
        underline: 'Underline'
      },
      confirm_delete: 'Delete?',
      save_succeed: 'Save successfully',
      add_to_personal_blocks_succeed: 'Add to personal blocks successfully.',
      es_search: 'Global Search',
      none: 'None',
      search_type_list: {
        all: 'All',
        document: 'Document',
        news_information: 'News Information',
        template: 'Template',
        product: 'Product'
      },
      category_setting: 'Category',
      width: 'Width',
      product_label: 'Product Tag',
      choose_product_label: 'Please select the tag of the product you want to show.',
      content_label: 'News Tag',
      choose_content_label: 'Please select the tag of the news you want to show.',
      title: 'Title',
      time: 'Time',
      link: 'Link',
      setting: 'Setting',
      content_source: 'Source',
      relevant_tag: 'Relevant Tag',
      manual_edit: 'Manual Edit',
      hidden: 'Hidden',
      file_big: 'The uploaded picture is too large. It is recommended that the size of the uploaded picture be less than 200KB',
      desc: 'Description',
      seq: 'Seq',
      delete_customer_service_channel: 'Delete_customer service_channel'
    },
    picture_config_drawer: {
      picture: 'Picture',
      video: 'Video',
      choose_picture: 'Choose Picture',
      upload_video: 'Upload Video',
      edit_picture: 'Edit Picture',
      picture_link: 'Picture Link',
      page: 'Page',
      pic_size: 'Please use lowercase letters(a-z) and numbers(0-9) for the name, and use the "-" between words.',
      open_new_tab: 'Open New Tab',
      picture_descripition: 'Picture Descripition',
      picture_desc_placeholder: 'Descripition',
      remove: 'Remove',
      video_url: 'Video Url（qq、iqiyi、youku、tudou）',
      url: 'Url (http://xxxx)',
      confirm_upload: 'Upload?',
      none: 'None',
      save: 'Save',
      cancel: 'Cancel',
      confirm: 'Confirm？',
      edit: 'Edit',
      move: 'Move',
      crop: 'Crop',
      enlarge: 'Enlarge',
      reduce: 'Reduce',
      anticlockwise: 'Anticlockwise 45°',
      clockwise: 'Clockwise 45°',
      left_translation: 'Left Translation',
      right_translation: 'Right Translation',
      recommended_size: 'Recommended Size :',
      file_big: 'The Picture is Too Large'
    },
    button: {
      name: 'Name',
      layout: 'Layout',
      solid: 'Solid',
      hollow: 'Hollow',
      button_color: 'Button Color',
      link: 'Link',
      jump_page: 'Page',
      jump_block: 'Block',
      open_new_tab: 'Open New Tab',
      hide_after_going_online: 'Hide After Going Online',
      right_angle: 'Right Angle',
      fillet: 'Fillet',
      full_angle: 'Full Angle',
      new_content: 'New Content',
      button_size: {
        small: 'Small',
        normal: 'Normal',
        big: 'Big'
      },
      none: 'None'
    },
    image_modal: {
      upload_new_pictures: 'Upload New Pictures',
      uploaded_pictures: 'Uploaded Picture',
      gallery: 'Gallery',
      browse_in_local_files: 'Browse In Local Files',
      all_pictures: 'Here are all the pictures you uploaded.',
      pic_size: 'Please use lowercase letters(a-z) and numbers(0-9) for the name, and use the "-" between words.',
      picture_select: 'You can upload more pictures and choose from the list.\n',
      upload_pictures: 'Upload Pictures',
      file_count: ' files',
      uploaded_at: 'Uploaded At ',
      all: 'All',
      confirm_delete: 'Delete?',
      landscape: 'landscape',
      animation: 'animation',
      blurry: 'blurry',
      food: 'food',
      city: 'city',
      literary: 'literary',
      abstract: 'abstract',
      business: 'business',
      natural: 'natural'
    },
    menu_editor: {
      all_menu: 'All menu',
      all: 'All',
      block_content: 'Block Content',
      add_content: 'Add Content',
      title: 'Title',
      belongs_to_blcok: 'Belongs To',
      creat_at: 'Create At',
      operate: 'Operate',
      confirm_delete: 'Delete？',
      add_succeed: 'Add successfully',
      modify_succeed: 'Modify successfully',
      update_succeed: 'Update successfully',
      edit: 'Edit',
      delete: 'Delete',
      belongs_to_menu: 'Belongs To',
      status: 'Status',
      published: 'Published',
      unpublished: 'Unpublished',
      picture: 'Picture',
      upload: 'Upload',
      notes: 'Notes',
      content: 'Content',
      cancel: 'Cancel',
      ok: 'OK',
      setting_menu: 'Setting',
      name: 'Name',
      associate_page: 'Associate Page',
      associate_block: 'Associate Block',
      add_new_menu: 'Add',
      superior_menu: 'Superior Menu',
      none: 'None'
    },
    nav_title_dropdown: {
      link: 'Link',
      page: 'Page',
      block: 'Block',
      null: 'Null',
      selected_color: 'Selected Color',
      unselected_font_color: 'Unselected Font Color',
      open_new_tab: 'Open New Tab',
      delete: 'Delete'
    },
    section_menu: {
      setting: 'Setting'
    },
    form_editor: {
      field: 'Field',
      options: 'Options',
      border_color: 'Border Color',
      save: 'Save',
      cancel: 'Cancel',
      check_all_messages: 'Check All Messages'
    },
    map_editor: {
      baidu_map: 'Baidu Map',
      placeholder: 'Placeholder',
      address: 'Address',
      save: 'Save',
      cancel: 'Cancel',
      edit: 'Edit'
    },
    cook_loading: {
      preparing: 'Loading...'
    },
    drag_grid_item: {
      down: 'Down',
      up: 'Up',
      untying: 'Untying',
      center: 'Center',
      locking: 'Locking',
      theme: 'Theme',
      layout: 'Layout',
      upload_picture: 'Upload Picture',
      custom_color: 'custom color #fff',
      remove: 'Remove',
      combine: 'Combine'
    },
    empty_box: {
      no_data: 'No Data'
    },
    item_adder: {
      add_content: 'Add Content',
      title: 'Title',
      text: 'Text',
      picture_or_video: 'Picture/Video',
      button: 'Button',
      product: 'Product',
      form: 'Form',
      map: 'Map',
      grid: 'Grid',
      split_line: 'Split Line',
      delete: 'Delete',
      confirm: 'Confirm?',
      config: 'Config'
    },
    plan_loading: {
      is_loading: 'Loading hard for you... '
    },
    es_search: {
      news_information: 'News',
      product: 'Product',
      document: 'Document',
      template: 'Template',
      search_keyword: 'please input the search-keywords',
      search: 'Search',
      search_option: 'Search Options',
      search_type: 'Type',
      find_result: 'Find Results',
      search_order: 'Order',
      order_type: 'Order Type',
      all_type: 'All',
      load_more: 'Display more'
    },
    image: {
      confirm_upload: 'Upload?'
    },
    item_empty_adder: {
      add: 'Add'
    },
    nav_modal: {
      cancel: 'Cancel',
      ok: 'OK'
    }
  },
  sections: {
    login_section: {
      placeholder: {
        enter_password: 'Please enter your access password',
        email: 'Email',
        phone: 'Phone Number',
        password: 'Password',
        code: 'Code',
        picture_code: 'Graphic Verification Code',
        set_password: 'Set password length 6-20 bits',
        confirm_password: 'Confirm password'
      },
      login: 'Login',
      send_code: 'Send Code',
      resend: 's',
      change_picture: 'Change',
      code_login: 'Code',
      password_login: 'Password',
      find_password: 'Retrieve password',
      sign: 'Sign',
      read_and_accept: 'I have read and agreed ',
      service_agreement: 'service agreement',
      account_type: {
        check: 'Your account is under review.',
        ban: 'Your account has been disabled by the webmaster.',
        no_member: 'Your login account is not a member of the website.',
        no_member_label: 'You do not have access to this page, please contact the webmaster.'
      },
      modify_password: 'Modify password',
      new_password: 'You have successfully set your password. Please try a new password to login.',
      next_step: 'Next',
      wrong_phone_format: 'The mobile phone number format is incorrect.',
      wrong_email_format: 'The mailbox address format is incorrect',
      send_code_succeed: 'Verification code was sent successfully',
      wrong_phone: 'Please enter the correct mobile phone number.',
      wrong_email: 'Please enter the correct mailbox address.',
      wrong_code: 'Wrong verification code',
      empty_code: 'Please enter the validation code.',
      wrong_password_length: 'Please enter password.',
      wrong_password: 'Wrong password',
      password_not_same: 'Two password entries are inconsistent, please re-enter.',
      wrong_picture_code: 'Please enter the correct graphic validation code.',
      read_agreement_first: 'Please read the service agreement first.',
      account_status: 'Normal',
      sign_succeed: 'Sign successfully',
      login_succeed: 'Login successfully'
    },
    y_carousel: {
      add: 'Add',
      animations: {
        common: 'Common',
        bounce: 'Bounce',
        fade: 'Fade',
        flip: 'Flip'
      },
      speed: {
        null: 'None',
        slow: 'Slow',
        quick: 'Quick'
      },
      is_saving: 'Saving...',
      save_succeed: 'Save successfully'
    },
    y_category_list: {
      all_categories: 'All Categories',
      categories: 'Categories',
      all: 'All',
      add_content: 'Add',
      add_category: 'Add',
      modify_category: 'Modify Category',
      name: 'Name',
      ok: 'OK',
      cancel: 'Cancel',
      add_succeed: 'Add successfully',
      name_empty: 'Category name can not be empty.',
      modify_succeed: 'Modify successfully',
      confirm_delete: 'Delete？',
      delete_succeed: 'Delete successfully',
      choose_menu: 'Please choose a menu first.',
      none: 'None'
    },
    y_document_center: {
      download: 'Download'
    },
    y_document_detail: {
      download_area: 'Download Area',
      download_center: 'Download Center',
      document_attributes: {
        date: 'Date',
        size: 'Size',
        type: 'Type',
        category: 'Category',
        code: 'Code',
        version: 'Version',
        language: 'Language',
        description: 'Description'
      },
      copy: 'Copy',
      download: 'Download',
      download_all: 'Download All',
      scan_and_share: 'Scan And Share',
      login_download: 'Click to login and you can download it after login.',
      login: 'Login',
      password_for_download: 'Please enter a password to access or download the document.',
      password: 'Password',
      ok: 'OK',
      login_member_account: 'To access this document, you need to login to the membership account. Please click on this link to login.',
      params_error: 'Missing parameters, please jump to the document details page through the document list page.',
      account_is_checking: 'Your account is under review and cannot access the document for the time being.',
      account_is_forbidden: 'Your account is disabled and you are temporarily unable to access the document.',
      login_for_read: 'This document is only accessible to webmaster. If you are an administrator, please log in to your account and visit again.',
      read_forbidden: 'This document is accessible only by webmaster.',
      read_for_owner: 'This document is accessible only by owner.',
      password_error: 'Password Error',
      open_in_browser: 'Please click on the top right corner of the screen to open in the browser.',
      copy_succeed: 'Copy successfully',
      click_to_share: 'Please click on the top right corner of the screen to share.'
    },
    y_document_list: {
      choose_type: 'Method of selecting',
      clear: 'Clear',
      document_category: 'Category',
      document_type: 'Type',
      document_language: 'Language',
      category: 'Category',
      type: 'Type',
      extends: 'Extends',
      language: 'Language',
      order: 'Order',
      order_placeHolder: 'Order Type',
      display: 'Dispaly',
      display_count: 'Count',
      document_attributes: {
        date: 'Date',
        size: 'Size',
        type: 'Type',
        category: 'Category',
        code: 'Code',
        version: 'Version',
        language: 'Language',
        description: 'Description'
      },
      advanced_search: 'Advanced Search',
      password_for_download: 'Please enter a password to access or download the document.',
      password: 'Password',
      ok: 'OK',
      login_member_account: 'To access this document, you need to login to the membership account. Please click on this link to login.',
      params_error: 'Missing parameters, please jump to the document details page through the document list page.',
      account_is_checking: 'Your account is under review and cannot access the document for the time being.',
      account_is_forbidden: 'Your account is disabled and you are temporarily unable to access the document.',
      login_for_read: 'This document is only accessible to webmaster. If you are an administrator, please log in to your account and visit again.',
      read_forbidden: 'This document is accessible only by webmaster.',
      read_for_owner: 'This document is accessible only by owner.',
      password_error: 'Password Error',
      open_in_browser: 'Please click on the top right corner of the screen to open in the browser.',
      copy_succeed: 'Copy successfully',
      click_to_share: 'Please click on the top right corner of the screen to share.',
      order_rules: {
        name_asc: 'name asc',
        name_desc: 'name desc',
        created_at_asc: 'created at asc',
        created_at_desc: 'created at desc',
        updated_at_asc: 'updated at asc',
        updated_at_desc: 'updated at desc'
      }
    },
    y_document_search: {
      advanced_search: 'Advanced Search',
      category: 'Category',
      type: 'Type',
      search_placeholder: 'Search by keyword, code, or name',
      search: 'Search'
    },
    y_footer: {
      link: 'Link',
      page: 'Page',
      block: 'Block',
      none: 'None',
      open_new_tab: 'Open New Tab',
      delete: 'Delete',
      add_link: 'Add',
      add_picture: 'Add',
      themes: {
        center: 'Center',
        justify_align: 'Justify Align',
        cross_sectional: 'Cross Sectional'
      },
      confirm_delete: 'Delete？'
    },
    y_form: {
      edit: 'Edit',
      form_content: {
        name: 'Name',
        name_label: 'Name Label',
        mail: 'Mail',
        mail_label: 'Mail Label',
        phone: 'Phone',
        phone_label: 'Phone Label',
        opinion: 'Opinion',
        opinion_label: 'Opinion Label',
        send: 'Send',
        send_label: 'Send Label',
        subject: 'Subject',
        subject_label: 'Subject Label'
      },
      is_sending: 'Sending...',
      send_succeed: 'Send successfully. Thank you for your feedback.',
      wrong_mail: 'Please fill in the correct email address.',
      complete_form: 'Please fill in the complete form.',
      themes: {
        layout_A: 'Layout A',
        layout_B: 'Layout B'
      }
    },
    y_freedom_section: {
      container_width: 'Container Width',
      responsive: 'Responsive',
      text: 'Text',
      picture: 'Picture',
      button: 'Button',
      whiteboard: 'Whiteboard',
      map: 'Map',
      spilt_line: 'Spilt Line'
    },
    y_handbook: {
      home_page: 'Home',
      add_article: 'Add',
      modify_article: 'Modify',
      add_category: 'Add',
      modify_category: 'Modify',
      category: 'Category',
      title: 'Title',
      rich_text: 'Rich Text',
      preview: 'Preview',
      category_name: 'Category Name',
      modify_home_page: 'Modify',
      color: 'Color',
      custom_color: 'Custom Color #fff',
      name: 'Name',
      name_title: 'Name Title',
      confirm_delete: 'Delete？',
      is_saving: 'Saving...',
      save_succeed: 'Save successfully'
    },
    y_header: {
      confirm_delete: 'Delete？',
      themes: {
        common: 'Common',
        layout_A: 'Layout A'
      }
    },
    y_hr: {
      dashed: 'Dashed',
      solid: 'Solid'
    },
    y_markdown: {
      preview: 'preview'
    },
    y_menu: {
      keyword_placeholder: 'Enter Keyword',
      search: 'Search',
      login_out: 'Login Out',
      confirm_delete: 'Delete？'
    },
    y_navbar: {
      add_link: 'Add',
      keyword_placeholder: 'Enter Keyword',
      confirm_delete: 'Delete？',
      is_saving: 'Saving...',
      save_succeed: 'Save successfully',
      theme: {
        two: 'Justify Align A',
        layout_A: 'Justify Align B',
        layout_B: 'Superpose',
        layout_C: 'Second-level Menu',
        layout_D: 'Compact Center',
        layout_img: 'Icon With Button'
      },
      none: 'None'
    },
    y_news: {
      link: 'Link',
      page: 'Page',
      none: 'None',
      selected_color: 'Selected Color',
      open_new_tab: 'Open New Tab',
      add_news: 'Add',
      is_saving: 'Saving...',
      save_succeed: 'Save successfully',
      confirm_delete: 'Delete？'
    },
    y_news_information: {
      author: 'Author',
      description: 'Description',
      data_source: 'Source',
      tips: 'Unless otherwise specified, the copyright of this article belongs to the original author. Please indicate the source for reprinting.',
      data_source_link: 'Link',
      labels: 'Tags',
      scan_and_preview: 'Scan and preview',
      scan_and_share: 'Scan and share',
      click_to_share: 'Please click on the top right corner of the screen to share.'
    },
    y_news_list: {
      choose_category: 'Please click on Settings to select the categories of news you want to show.',
      author: 'Author',
      no_news: 'No News',
      modify_title: 'Modify Title',
      color: 'Color',
      custom_color: 'Custom Color #fff',
      name: 'Name',
      title: 'Title'
    },
    y_photo_album: {
      add_picture: 'Add',
      choose_menu: 'Please choose a menu first.',
      confirm_delete: 'Delete？',
      themes: {
        common: 'Layout-A',
        one: 'Layout-B',
        two: 'Layout-C'
      }
    },
    y_product_card: {
      add_product: 'Add',
      confirm_delete: 'Confirm Delete？',
      themes: {
        four: 'Vertical Layout',
        layout_A: 'Text Below A',
        layout_B: 'Text Left A',
        layout_C: 'Text Below B',
        layout_D: 'Text Right A'
      },
      none: 'None'
    },
    y_product_desc: {
      upload_thumbnail: 'Thumbnail',
      upload_banner: 'Banner',
      remove: 'Remove',
      confirm_delete: 'Confirm Delete？',
      themes: {
        layout_A: 'Text Left - Center',
        layout_B: 'Text Right - Center',
        layout_C: 'Text Below',
        layout_D: 'Text Left - Bottom Justify',
        layout_E: 'Text Left - Bottom Justify'
      }
    },
    y_recruit_card: {
      add_recruit: 'Add',
      confirm_delete: 'Confirm Delete？',
      themes: {
        layout_A: 'Layout A'
      }
    },
    y_search: {
      search: 'Search',
      search_keyword: 'please input the search-keywords'
    },
    y_search_list: {
      choose_type: 'Method of selecting',
      type: 'Type',
      all: 'All',
      results: 'Results',
      unit: '',
      order: 'Order',
      order_type: 'Order Type',
      document_attributes: {
        date: 'Date',
        size: 'Size',
        type: 'Type',
        category: 'Category',
        code: 'Code',
        version: 'Version',
        language: 'Language',
        description: 'Description'
      },
      brand: 'Brand',
      author: 'Author',
      date: 'Date',
      no_data: 'No Date',
      more: 'More',
      search_empty: 'No Date',
      orders: {
        relativity: 'relativity',
        updated_at_desc: 'updated at desc',
        updated_at_asc: 'updated at asc',
        name_sort_desc: 'name sort desc'
      },
      news_information: 'News',
      product: 'Product',
      document: 'Document',
      template: 'Template'
    },
    y_slider: {
      add_slider: 'Add',
      is_saving: 'Saving...',
      save_succeed: 'Save successfully',
      animations: {
        common: 'common',
        bounce: 'bounce',
        fade_1: 'fade-1',
        fade_2: 'fade-2',
        flip: 'flip'
      },
      themes: {
        common: 'Text Center',
        one: 'Text Left',
        two: 'Text Right'
      }

    },
    y_title: {
      themes: {
        layout_A: 'Center-A',
        layout_B: 'Center-B',
        layout_C: 'Center-C',
        layout_D: 'Left',
        layout_E: 'Right'
      }
    },
    y_widget: {
      save_succeed: 'Save successfully',
      confirm_delete: 'Delete？'
    },
    y_workflow: {
      add_item: 'Add',
      confirm_delete: 'Delete？',
      circle_types: {
        right_angle: 'Right Angle',
        fillet: 'Fillet'
      },
      themes: {
        common: 'From Left To Right',
        one: 'From Up To Down',
        two: 'Center',
        three: 'Left',
        four: 'Text Below'
      },
      style: 'Style',
      none: 'None',
      selected_color: 'Selected Color',
      delete: 'Delete'
    }
  },
  initialization_data: {
    button: {
      content_name: 'More'
    },
    map: {
      title: 'Add text',
      page_content: 'Lane 666, Zhangheng Road, Zhangjiang High-tech Park, Shanghai'
    },
    text: {
      add_text: 'Add text',
      title: 'Author',
      subtitle: 'Snow in Okhotsk',
      page_content: 'Every winter, there will be a seasonally Limited train called the drifting ice extreme acute Okhotsk wind, galloping across most of the Hokkaido continent. My journey is from Sapporo to Okhotsk.'
    },
    form: {
      title: 'Add text',
      name: 'Name',
      name_label: 'Name',
      mail: 'Mail',
      mail_label: 'Mail',
      phone: 'Phone',
      phone_label: 'Phone',
      opinion: 'Opinion',
      opinion_label: 'Opinion',
      send: 'Send',
      send_label: 'Send',
      theme: 'Theme',
      theme_label: 'Theme'
    },
    category_list: {
      title_1: 'The University of MELBOURNE',
      subtitle_1: 'Australia',
      title_2: 'Cambridge University',
      subtitle_2: 'the United Kingdom'
    },
    footer: {
      name_1: 'Link 1',
      name_2: 'Link 2',
      title: 'copyright 2018 XXXX Hu ICP Bei XXXXXXXX'
    },
    function: {
      title: 'Add title',
      subtitle: 'Add subtitle',
      page_content: 'Add content'
    },
    header: {
      title: 'Title',
      subtitle: 'Subtitle',
      page_content: 'Time is quiet, I do not always pour my life, in exchange for years of quiet. If the years are quiet, I will smile and not be old.'
    },
    navbar: {
      name: 'Link',
      content_name: 'Title',
      name_1: 'Home',
      name_2: 'Products',
      name_3: 'About Us',
      name_4: 'Activities',
      name_5: 'Product Service',
      name_6: 'Contact Us',
      login: 'Login',
      sign: 'Sign',
      button: 'Button'
    },
    news: {
      title: 'Title',
      title_1: 'Winning Foreign Design Award',
      subtitle_1: 'Following the first IF Design Gold Award in China in March this year, the company won three CERMAN Design Awards again for its outstanding design concept and unique appearance design.',
      title_2: 'Interview with China Design Magazine No.1',
      subtitle_2: 'I was interviewed by DOMUS International Chinese Edition, a global authoritative magazine in the field of architecture and design. In the interview. Eriber talks about how to lead the development of home-only by scientific and technological aesthetics, and his research wisdom.',
      title_3: 'Happy News | Lead only home science and technology aesthetics, the company\'s products won the IDEA Award',
      subtitle_3: 'IDEA（INDUSTRIAL DESGIN EXCELLENCE AWARDS）Originated in the United States, Business Weekly is recognized as a more critical international design veteran in the industry. meanwhile',
      title_4: 'Intelligent Innovation Technology Leads the Industry Trend',
      subtitle_4: 'Long-term commitment to video analysis, computational vision, machine learning, cloud computing and other technology research and development, adhere to the most advanced science and technology and mass demand, through innovative ideas and technological innovation,'
    },
    news_list: {
      all: 'All'
    },
    product_card: {
      name: 'Product',
      title: '13.3 inch Laptop',
      subtitle: 'Official Self-owned Flagship Shop',
      content_name: 'Add to cart'
    },
    product_desc: {
      title: 'Title',
      subtitle: 'Content',
      content: {
        title: '<p><span style="font-size:36px">MAOKING Radio</span></p><p><span style="line-height:1.5"><span style="color:#abadae"><span style="font-size:14px">PRINCELING&nbsp; 2017</span></span></span></p> <p><span style="color:#abadae"><span style="font-size:14px;">Price： </span></span><span style="color:#f43244"><span style="font-size:14px"><span style="font-size:22px">￥399.00</span></span></span></p> <p><span style="color:#bdbdbe"><span style="font-size:14px"><span style="font-size:22px">&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;</span></span></span></p><p>&nbsp;</p><p><em><span style="font-size:14px">The vitality of logs can withstand the baptism of time</span></em></p><p><em><span style="font-size:14px">Strict selection of North American walnut logs, the passage of time</span></em></p><p><em><span style="font-size:14px">Only in this way can we give wonderful texture and color.</span></em></p>',
        subtitle: 'Feelings and polishing',
        content_name: 'Buy Now'
      }
    },
    recruit_card: {
      title: 'Title',
      subtitle: 'Content'
    },
    slider: {
      title: 'Jonas',
      subtitle: 'Household carpet'
    },
    workflow: {
      title_1: 'Buy',
      subtitle_1: 'Choose what you like from the high quality series and add it to the shopping cart.',
      title_2: 'Pay Online',
      subtitle_2: 'Enter the receiving address and pay by credit card, debit card or PayPal.',
      title_3: 'Send',
      subtitle_3: 'We will deliver the goods within 5 working days. If you have any questions, please contact us.',
      title_4: 'Confirm',
      subtitle_4: 'Upon receipt of the goods, please confirm in time and contact us if you have any questions.'
    },
    title: {
      title: 'Title',
      subtitle: 'Subtitle',
      page_conteng: 'Add new content here.',
      content_name: 'Button'
    },
    handbook: {
      category: 'category 1',
      title: 'Title',
      page_content: 'Add new content here.'
    },
    freedom: {
      page_content_1: 'Service Hall',
      page_content_2: 'Service Hotline',
      page_content_3: 'We will care about every small opinion, if you have any suggestions for the product, you may as well communicate with us through 400-800-1188, your own professional voice channel.',
      page_content_4: 'Card Guide',
      page_content_5: 'As long as you apply to become a preferential consumer, you can get intimate service and support. For this reason, we have prepared relevant questions and answers for you, and wish you a pleasant shopping experience.'
    },
    custom_section: {
      title: 'Organization activities'
    },
    blocks: {
      title: 'Paragraph',
      custom_section: 'Grid Layout',
      header: 'Header',
      navbar: 'Navigation',
      product_card: 'Product Card',
      form: 'Form',
      carousel: 'Carousel',
      footer: 'Footer',
      slider: 'Slider',
      hr: 'Dividing Line',
      workflow: 'Workflow',
      markdown: 'Markdown',
      handbook: 'Handbook',
      photo_album: 'Photo Album',
      freedom: 'Free Customization',
      contact_info: 'Contact Info',
      product_desc: 'Product Description',
      product_recruit: 'Recruit',
      dynamic_form: 'Dynamic Form',
      widget: 'Widget',
      news: 'News',
      category_list: 'Category List',
      news_information: 'News Information'
    }
  },
  site: {
    use_now: 'Use Now',
    back: 'Back'
  },
  chint: {
    dealer_checking: 'Please wait for the admin to review your membership privileges.',
    email_not_blank: 'Email can not be blank',
    delete_confirm: 'Are you want to delete this data?',
    product_center: 'Product Center',
    product_detail: 'Product Detail',
    home_page: 'Home Page',
    service_center: 'Service Center',
    personal_service: 'Personal Service',
    customer_service: 'Customer Service',
    solution: 'Solution',
    about_us: 'About Us',
    news_center: 'News Center',
    news_detail: 'News Detail',
    download_center: 'Download Center',
    contact_us: 'Contact Us',
    product_marketing: 'Product Marketing',
    join_us: 'Join Us',
    membership: 'Membership',
    member_center: 'User center',
    member_promotion: 'Promotions',
    search_center: 'Search Center',
    add_item: 'Add',
    remove_item: 'Remove',
    edit_item: 'Edit',
    unlock: 'Unlock',
    return: 'Return',
    edit_contacts: 'Edit Contacts',
    edit_address: 'Edit Address',
    confirm_delete: 'Confirm',
    login_and_register: 'Login & Register',
    submit_successful: 'Submit Successful',
    save: 'Save',
    option: 'Option',
    name: 'Name',
    color: 'Color',
    member_label: 'Member Labels',
    member_label_permission: 'Member labels which can access this content',
    permission: 'Permission',
    public: 'Public',
    permission_manage: 'Permissions',
    role_list: 'Role List',
    new_role: 'New Role',
    login: {
      login: 'Login',
      register: 'Register',
      register_email: 'Register Email',
      register_success: 'Register Success',
      reset_password: 'Forget Password',
      reset_password_email: 'Forget Password Email',
      reset_password_success: 'Reset Password successful',
      user_agreement: 'User Agreement',
      confirm_password: 'Reset Password',
      must_input: 'Fields marked with * are required',
      reset_email_send: 'we have send a email to your account, please check the email to perform the next step',
      send_email: 'We have sent you an email with a link to',
      send_register_email: 'to complete your registration',
      send_reset_pwd_email: 'to reset your password',
      return: 'Return',
      no_account: 'No Account? Register Now',
      forgot_password: 'Forgot Password',
      submit: 'Submit',
      existing_account: 'Existing accounts',
      log_in: 'Login',
      privacy: 'Acceptable Use Policy - Privacy',
      resend: 'Resend',
      after_send: 'Can be sent again after',
      accept: 'Accept'
    },
    home: {
      search: 'Search',
      download_center: 'Download Center',
      contact_us: 'Contact Us',
      login: 'Login',
      learn_more: 'Learn More'
    },
    search: {
      place_holder: 'NA1 Air Circuit Breaker',
      search_button: 'Search'
    },
    bread: {
      home: 'Home',
      search: 'Search',
      service_center: 'Service Center',
      customer_service: 'Customer Service',
      self_service: 'Enquiries',
      download_center: 'Download Center',
      about_us: 'About Us',
      company: 'Company',
      news_center: 'News Center',
      join_us: 'Careers',
      membership: 'Membership',
      user_center: 'User Center',
      promotions: 'Promotions'
    },
    nav: {
      log_out: 'you have already log out'
    },
    member_center_page: {
      wrong_email: 'Please enter the correct mailbox address.',
      password_not_same: 'Two password entries are inconsistent, please re-enter.',
      logins: 'Logins',
      your_email: 'Your email',
      password: 'Password',
      personal_info: 'Personal Info'
    },
    product_detail_page: {
      return_list: 'Return List',
      overview: 'Overview',
      technical_parameter: 'Technical Parameter',
      install_manual: 'Installation Manual'
    },
    product_list_page: {
      search_button: 'Search',
      search_place_holder: 'Enter the name of Product or model'
    },
    foot_content: {
      customer_service_channel: 'Add Channel'
    },
    form: {
      name: 'Name',
      company: 'Company',
      title: 'Title',
      email: 'Email',
      phone_number: 'Phone Number:（+Area code）',
      region: 'Select your region',
      question_type: 'Question type',
      description: 'Description of relevant information',
      verification_code: 'Verification Code',
      submit: 'Submit Now',
      password: 'Password',
      confirm_password: 'Confirm Password',
      save: 'Save',
      first_name: 'First Name',
      last_name: 'Last Name',
      position: 'Position',
      industry: 'Industry',
      new_password: 'New Password',
      phone_number_code: 'Phone',
      region_code: 'Region',
      role: 'I am a...'
    },
    download_center_page: {
      sample: 'Sample',
      category: 'Product Category',
      sub_category: 'Product Sub Category',
      search_place_holder: 'Search by keyword, product name',
      file_name: 'File Name',
      document_format: 'Document Format',
      update_time: 'Update Time',
      document_size: 'Document Size',
      download: 'Download'
    },
    news_center_page: {
      more_news: 'More',
      read_more: 'Read More',
      return_list: 'Return List',
      add_news_tag: 'Please Choose The News Tags',
      edit_news: 'Edit News Tab',
      title: 'Title',
      seq: 'Seq',
      news_tag: 'News Categories',
      seq_hint: 'Descending according to numbers',
      before_news: 'Previous',
      next_news: 'Next'
    },
    join_us_page: {
      recruitment: 'Recruitment',
      hr: 'Human Resource',
      care_team: 'Care Team',
      city: 'City',
      company: 'Company',
      type: 'Type',
      search_placeholder: 'Search by keyword, product name',
      position: 'Position',
      number: 'Number',
      email: 'Email',
      download_template: 'Download template',
      upload: 'Upload',
      upload_hint: 'Please upload files in. doc or PDF format',
      edit_name: 'Edit Tab name and Status',
      name: 'Name',
      status: 'Status',
      show: 'Show',
      hide: 'Hide'
    },
    custom_form: {
      form_type: 'Form Type',
      form_data: 'Form Data',
      name: 'Name',
      company: 'Company',
      email: 'Email',
      industry: 'Industry',
      region: 'Region',
      time: 'Time',
      position: 'Position',
      type: 'Type',
      number: 'Number',
      title: 'Title',
      phone: 'Phone',
      question_type: 'Question Type',
      subscribe: 'Subscribe',
      recruitment: 'Recruitment',
      self_service: 'Enquiries',
      custom_contact: 'User Message',
      status: 'Status',
      new: 'New',
      processing: 'Processing',
      processed: 'Processed',
      from_page: 'Source'
    },
    contact: {
      add_new_point: 'Add New Point',
      edit_point: 'Edit Point',
      add_new_contact: 'Add New Contact',
      edit_contact: 'Edit Contact',
      confirm_delete_point: 'Are you sure delete the point - ',
      name: 'Name',
      state: 'State',
      type: 'Type',
      address: 'Address',
      contact: 'Contact',
      contact_no: 'Contact No.',
      email: 'Email',
      operation: 'Operation',
      color: 'Color',
      blue: 'Blue',
      red: 'Red',
      green: 'Green',
      orange: 'Orange',
      yellow: 'Yellow',
      purple: 'Purple',
      light_blue: 'Light Blue',
      distributor: 'Distributor',
      location: 'Location',
      edit_address: 'Edit Address'
    }
  }
};
