/**
 * Created by atyun on 8/1/17.
 */

import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelper } from '../../../../helper/http.helper';

@Injectable()
export class ProductService {

  constructor(public http: HttpHelper) {

  }

  public products(search_params: any = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('product/api/products.json?', search_params).pipe(map(data => this.http.extractData(data)));
  }

  public product_detail(search_params: any = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('product/api/products/product_detail.json?', search_params);
  }

  public product_categories(search_params: any = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('product/api/product_categories.json?', search_params).pipe(map(data => this.http.extractData(data)));
  }

  public query_detail_categories(search_params: any = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('product/api/product_categories/query_detail.json?', search_params).pipe(map(data => this.http.extractData(data)));
  }

  public product_labels(search_params: any = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('product/api/product_labels.json?', search_params).pipe(map(data => this.http.extractData(data)));
  }

  public create_shop(create_params: any = {}): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_POST('product/api/shops.json', create_params);
  }

  public remove_shop(id: string): Observable<any> {
    const params = {id};
    return this.http.AUTH_HTTP_DELETE('product/api/shops/' + id + '.json', params);
  }

  public query_faqs(search_params: any = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET(('product/api/faqs.json'), search_params);
  }
}
