
import { Inject, Injectable, InjectionToken, Optional, Provider, SkipSelf } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Chint_I18N_TOKEN } from './chint-i18n-token';
import { ChintI18nInterface } from './chint-i18n.interface';
import { zh_CN } from './languages/zh_CN';

@Injectable({
  providedIn: 'root'
})
export class ChintI18nService {

  constructor(@Inject(Chint_I18N_TOKEN) locale: ChintI18nInterface) {
    this.setLocale(locale || zh_CN);
  }

  private _locale: ChintI18nInterface;

  private _change = new BehaviorSubject<ChintI18nInterface>(this._locale);

  get localChange(): Observable<ChintI18nInterface> {
    return this._change.asObservable();
  }

  get locale(): ChintI18nInterface {
    return this._locale;
  }

  set locale(value: ChintI18nInterface) {
    this._locale = value;
  }

  setLocale(language: ChintI18nInterface) {
    this._locale = language;
    this._change.next(language);
  }

  translate(path, data?) {
    let content = this._getObjectPath(this._locale, path) as string;
    if (typeof content === 'string') {
      if (data) {
        Object.keys(data).forEach((key) => content = content.replace(new RegExp(`%${key}%`, 'g'), data[key]));
      }
      return content;
    }
    return path;
  }

  private _getObjectPath(obj: object, path: string): string | object | any { // tslint:disable-line:no-any
    let res     = obj;
    const paths = path.split('.');
    const depth = paths.length;
    let index   = 0;
    while (res && index < depth) {
      res = res[paths[index++]];
    }
    return index === depth ? res : null;
  }
}

export function CHINT_LOCALE_SERVICE_PROVIDER_FACTORY(exist: ChintI18nService, locale: ChintI18nInterface): ChintI18nService {
  return exist || new ChintI18nService(locale);
}

export const CHINT_I18N_SERVICE_PROVIDER: Provider = {
  provide: ChintI18nService,
  useFactory: CHINT_LOCALE_SERVICE_PROVIDER_FACTORY,
  deps: [[new Optional(), new SkipSelf(), ChintI18nService], Chint_I18N_TOKEN]
};
