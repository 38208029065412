import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelper } from '../../helper/http.helper';

@Injectable()
export class SsoService {

  constructor(private http: HttpHelper,
              private httpClient: HttpClient) {

  }

  public sign_in(username: any, password: any): Observable<any> {
    const params = {username, password};
    return this.httpClient.post('/sso/users/sign_in.json', params);
  }

  // 获取service_ticket
  public get_service_ticket(search_params = {}) {
    return this.http.AUTH_HTTP_GET('/sso/users/service_ticket.json', search_params);
  }

  public logOut(search_params = {}) {
    return this.http.AUTH_HTTP_GET('/sso/users/sign_out.json', search_params);
  }

  public update(id: string, update_params: any = {}): Observable<any> {
    return this.http.AUTH_HTTP_PUT('sso/users/' + id + '.json', update_params).pipe(map(data => this.http.extractData(data, true)));
  }

}
