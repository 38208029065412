var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MainInjectorService } from '../app/services/main-injector.service';
import { HttpHelper } from '../helper/http.helper';
/**
 * 统计注解
 * @param type
 * @param {{}} create_params
 * @returns {(target: any, key: any, descriptor: any) => any}
 */
export function count(type, create_params, replace) {
    if (create_params === void 0) { create_params = {}; }
    if (replace === void 0) { replace = true; }
    return function (target, key, descriptor) {
        var method = descriptor.value;
        descriptor.value = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            var ret = method.apply(this, args);
            /**
             * 发送异步统计数据
             */
            var http = MainInjectorService.getInstance().getInjector().get(HttpHelper);
            /**
             * 动态替换函数中的值以及参数
             */
            if (replace) {
                args.forEach(function (arg, index) {
                    var object_key = Object.keys(create_params)[index];
                    if (object_key) {
                        create_params[object_key] = arg;
                    }
                });
            }
            create_params.url = window.location.href;
            var params = {
                create: __assign({ count_type: type }, create_params)
            };
            http.AUTH_HTTP_POST('/atyun/counters.json', params).subscribe(function (data) {
            });
            return (ret || []).map(function (data) {
            });
        };
        return descriptor;
    };
}
