/**
 * Created by atyun on 7/17/17.
 */

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { HttpHelper } from '../../../helper/http.helper';
import { Content } from '../../../model/common/content';
import { BaseService } from './base.service';

@Injectable()
export class ContentsService extends BaseService {

  // constructor(public http: HttpHelper) {
  //
  // }

  public page = 1;

  public per = 10000000;

  public contents(search_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('atyun/contents.json?', search_params).pipe(map(data => this.http.extractData(data)));
  }

  public contents_with_page(search_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('atyun/contents.json', search_params);
  }

  public create_section(params) {
    return this.http.AUTH_HTTP_UPLOAD_POST('atyun/contents/create_section.json', params);
  }

  public combine(params) {
    return this.http.AUTH_HTTP_UPLOAD_POST('atyun/contents/combine.json', params);
  }

  public update_section(params) {
    return this.http.AUTH_HTTP_UPLOAD_PUT('atyun/contents/update_section.json', params);
  }

  public get_sections(search_params = {}) {
    return this.http.AUTH_HTTP_GET('atyun/contents/sections.json', search_params).pipe(map(data => this.http.extractData(data).contents as Content[]));
  }

  public get_system_sections(search_params = {}) {
    return this.http.AUTH_HTTP_GET('atyun/contents/get_system_sections.json', search_params).pipe(map(data => this.http.extractData(data).contents as Content[]));
  }

  public operate_get_sections(search_params = {}) {
    return this.http.AUTH_HTTP_GET('atyun/manager/contents/sections.json', search_params);
  }

  public update(id: string, update_params: any = {}): Observable<any> {
    update_params.content_id = id;
    return this.http.AUTH_HTTP_UPLOAD_PUT('atyun/contents/' + id + '.json', update_params);
  }

  public operate_update(id: string, update_params: any = {}): Observable<any> {
    update_params.content_id = id;
    return this.http.AUTH_HTTP_UPLOAD_PUT('atyun/manager/contents/' + id + '.json', update_params);
  }

  public create(create_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_POST('atyun/contents.json', create_params);
  }

  public delete_by_id(id: string): Observable<any> {
    const params = {content_id: id};
    return this.http.AUTH_HTTP_DELETE('atyun/contents/' + id + '.json', params);
  }

  public menus(category: string): Observable<any> {
    const params = {category};
    return this.http.AUTH_HTTP_GET('atyun/contents/child_categories.json', params);
  }

  public add_to_template(params): Observable<any> {
    return this.http.AUTH_HTTP_POST('atyun/contents/add_to_template.json', params);
  }

  public query(params): Observable<any> {
    return this.http.AUTH_HTTP_POST('/atyun/graphql', params);
  }

  public upload_query(params) {
    return this.http.AUTH_HTTP_UPLOAD_POST('/atyun/graphql', params);
  }

  public copy(params = {}): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_POST('atyun/manager/contents/copy.json', params);
  }

}
