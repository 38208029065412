import { ChintI18nInterface } from '../chint-i18n.interface';

export const en_US: ChintI18nInterface = {
  locale: 'en_US',

  // 首页
  login: 'Login',
  search: 'Search',
  download_center: 'Download Center',
  contact_us: 'Contact Us',
  learn_more: 'Learn More',
  subscribe: 'Subscribe',
  all_categories: 'All Categories',
  membership: 'Membership',
  log_out: 'Log Out',
  home: 'Home',
  privacy: 'Privacy',
  back_to_home: 'Back to homepage',

  // 解决方案
  solutions: 'Solutions',

  // 产品列表
  product_search_placeholder: 'Enter the name of Product or model',

  // 产品详情
  return_list: 'Return List',
  overview: 'Overview',
  catalog: 'Catalog',
  manual: 'Manual',
  certificate: 'Certificate',
  drawings: 'Drawings',
  technical_patameter: 'Technical Parameter',
  installation_manual: 'Manual',
  video: 'Video',
  // 客服服务
  contacts: 'Contacts',
  contact_no: 'Contact No.',
  e_mail: 'E-mail',
  service_center: 'Service Center',

  // 搜索中心
  all: 'All',
  news: 'News',
  products: 'Products',
  files: 'Files',

  // 下载中心
  sample: 'Sample',
  product_category: 'Product Category',
  product_sub_caregory: 'Product Sub Category',
  download_center_search_placeholder: 'Search by keyword, product name',
  file_name: 'File Name',
  document_format: 'Document Format',
  update_time: 'Update Time',
  document_size: 'Document Size',
  download: 'Download',
  category: 'Category',

  // 关于我们
  about_us: 'About Us',

  // 新闻中心
  read_more: 'Read More',
  more_news: 'More',
  news_center: 'News Center',

  // 加入我们
  recruitment: 'Recruitment',
  hunman_resource: 'Human Resource',
  care_team: 'Care Team',
  city: 'City',
  type: 'Type',
  position: 'Position',
  number: 'Number',
  download_template: 'Download Template',
  upload: 'Upload CVs',
  join_us_warn: 'Please upload files in doc or PDF format',
  details: 'Details',
  position_search_placeholder: 'Search the position',

  // 会员详情
  logins: 'Logins',
  member_detail_warn: 'Please wait for the admin to review your membership privileges.',
  your_email: 'Your email',
  password: 'Password',
  confirm_password: 'Confirm password',
  save: 'Save',
  personal_info: 'Personal info',
  select_your_industry: 'Select your industry',
  select_your_country: 'Select your country',

  // 登录
  register_now: 'No Account? Register Now',
  forgot_password: 'Forgot Password',
  password_length_error: 'Password length must in 6-20',
  password_error: 'Incorrect Password',
  email_check: 'Your email has not been checked',
  account_ban: 'Your account has been disabled, Please contact us if you have any questions',
  no_account: 'No such account',

  // 注册
  existing_accounts: 'Existing accounts',
  profile: 'I am a...',
  policy: 'Accept User Policy',
  submit: 'Submit',
  link_expired: 'The current link has expired, please re-initiate',

  // 注册完发送邮箱验证信息
  send_email: 'We have sent you',
  send_register_email: 'an email with a link to to complete your registration.',
  resend: 'Resend',
  after: 'After',
  second: 's',

  // 忘记密码
  register: 'Register',

  // 修改密码发送邮箱验证信息
  send_reset_email: 'an email with a link to to reset your password.',

  // 重置密码
  new_password: 'New Password',

  // 订阅表单
  name: 'Name',
  company: 'Company',
  email: 'Email',
  industry: 'Industry',
  region: 'Region',

  // Self Service表单
  phone_number: 'Phone Number:(+Area code)',
  select_your_region: 'Select your region',
  question_type: 'Question Type',
  desc_of_info: 'Description of relevant information',
  verification_code: 'Verification Code',
  submit_now: 'Submit Now',

  // Contact US表单
  title: 'Title',

  // 表单错误信息
  item_required: 'This item is required',
  email_wrong: 'Enter a valid business email',
  fields_required: 'Fields marked with * are required',
  enter_correct_mail: 'Please enter the correct mailbox address',
  enter_password: 'Please enter password',
  enter_graphic_code: 'Please enter the correct graphic validation code.',
  password_re_enter: 'Two password entries are inconsistent, please re-enter.',
  agree_use_policy: 'Please agree to the use policy first.',

  // 表单成功信息
  reset_succeed: 'Reset Password successfully',
  code_send_succeed: 'Verification code was sent successfully',
  login_succeed: 'Login successfully',
  log_out_succeed: 'You have already log out',
  sign_succeed: 'Sign successfully',
  submit_succeed: 'Submit Successfully',
  upload_succeed: 'Upload Successfully',
  update_succeed: 'Update Successfully',

  // 月份缩写
  Jan: 'Jan.',
  Feb: 'Feb.',
  Mar: 'Mar.',
  Apr: 'Apr.',
  May: 'May',
  Jun: 'Jun.',
  Jul: 'Jul.',
  Aug: 'Aug.',
  Sept: 'Sept.',
  Oct: 'Oct.',
  Nov: 'Nov.',
  Dec: 'Dec.',

  // 月份
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May_full: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',

  accept: 'Accept',
  before_news: 'Previous',
  next_news: 'Next',

  reset_email_send: 'we have send a email to your account, please check the email to perform the next step'
};
