import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { zh_CN } from 'at-ng';
import { Chint_I18N_TOKEN } from '../domain/chint/i18n/chint-i18n-token';
// import { I18nPipe } from '../../modules/domain/chint/i18n/';
import { CHINT_I18N_SERVICE_PROVIDER } from '../domain/chint/i18n/chint-i18n.service';

@NgModule({
  declarations: [

  ],
  imports: [
    CommonModule
  ],
  providers: [
    {provide: Chint_I18N_TOKEN, useValue: zh_CN},
    CHINT_I18N_SERVICE_PROVIDER
  ],
  exports: []
})
export class ChintI18nModule {
}
