import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelper } from '../../../helper/http.helper';
import { Project } from '../../../model/common/project';
import { GraphqlBuilder } from '../../grapql/graphql_builder';
import { ContentsService } from './contents.service';

@Injectable()
export class ProjectService {

  constructor(private http: HttpHelper, private content_service: ContentsService) {
  }

  public projects(search_params = {}): Observable<any[]> {
    return this.http.AUTH_HTTP_GET('atyun/projects.json?per=10000', search_params).pipe(map(data => this.http.extractData(data).projects));
  }

  public manage_projects(search_params = {}): Observable<any[]> {
    return this.http.AUTH_HTTP_GET('atyun/manager/projects.json', search_params);
  }

  // 运营端使用
  public operate_get_projects(search_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('atyun/manager/projects.json', search_params);
  }

  public current_version(search_params = {}): Observable<Project> {
    return this.http.AUTH_HTTP_GET('atyun/projects/current_version.json?per=10000', search_params).pipe(map(data => data.project));
  }

  public update(id: string, update_params:any = {}): Observable<any> {
    update_params.project_id = id;
    return this.http.AUTH_HTTP_UPLOAD_PUT('atyun/projects/' + id + '.json', update_params);
  }

  public delete(id: string): Observable<any> {
    const params = {project_id: id};
    return this.http.AUTH_HTTP_DELETE('atyun/projects/' + id + '.json', params);
  }

  public create(create_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_POST('atyun/projects.json', create_params);
  }

  public copy(params = {}): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_POST('atyun/projects/copy.json', params);
  }

  public full_search_projects(search_params = {}) {
    const query = new GraphqlBuilder(Project);
    const body = query.getProperties();
    const search = query.query().build('projectFull', body);
    const params = {
      query: search,
      variables: {search: {...search_params}}
    };
    return this.content_service.query(params);
  }

  public member_get_projects(search_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('atyun/projects/member_permission_project.json', search_params).pipe(map(data => this.http.extractData(data)));
  }

  public copy_content(search_params = {}): Observable<any[]> {
    return this.http.AUTH_HTTP_GET('atyun/projects/copy_content.json', search_params).pipe(map(data => this.http.extractData(data)));
  }

}
