import { RouterModule } from '@angular/router';
import { of } from 'rxjs';
/**
 * Created by atyun on 7/25/17.
 */
var PreloadSelectedModulesList = /** @class */ (function () {
    function PreloadSelectedModulesList() {
    }
    PreloadSelectedModulesList.prototype.preload = function (route, load) {
        var current_path = window.location.pathname;
        var currentFlat = ['/login', '/home'].includes(current_path);
        return currentFlat && route.data && route.data.preload ? load() : of(null);
    };
    return PreloadSelectedModulesList;
}());
export { PreloadSelectedModulesList };
var ɵ0 = { preload: false }, ɵ1 = { preload: false }, ɵ2 = { preload: false }, ɵ3 = { preload: true }, ɵ4 = { preload: false }, ɵ5 = { preload: false }, ɵ6 = { preload: false };
var appRoutes = [
    { path: 'sites', loadChildren: '../modules/site-render/site-render.module#SiteRenderModule', data: ɵ0 },
    { path: 'dashboard', loadChildren: '../modules/dashboard/dashboard.module#DashboardModule', data: ɵ1 },
    { path: 'chint', loadChildren: '../modules//domain/chint/chint.module#ChintModule', data: ɵ2 },
    { path: 'login', loadChildren: '../modules/login/login.module#LoginModule' },
    { path: 'home', loadChildren: '../modules/home/home.module#HomeModule' },
    {
        path: 'index', loadChildren: '../modules/cms/cms.module#CmsModule',
        data: ɵ3
    },
    { path: 'operate', loadChildren: '../modules/operate/operate.module#OperateModule', data: ɵ4 },
    { path: '', loadChildren: '../modules//domain/chint/chint.module#ChintModule', data: ɵ5 },
    { path: '**', loadChildren: '../modules/dashboard/dashboard.module#DashboardModule', data: ɵ6 }
];
export var routing = RouterModule.forRoot(appRoutes, {
    preloadingStrategy: PreloadSelectedModulesList,
});
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
