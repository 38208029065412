import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { LoadingBarHttpModule } from '@ngx-loading-bar/http';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { AT_I18N, AtModule, zh_CN as at_zh_CN } from 'at-ng';
import { environment } from '../environments/environment';
import { DataHelper } from '../helper/data.helper';
import { HttpHelper } from '../helper/http.helper';
import { ResponseInterceptor } from '../interceptor/response.interceptor';
import { TokenInterceptor } from '../interceptor/token.interceptor';
import { Chint_I18N_TOKEN } from '../modules/domain/chint/i18n/chint-i18n-token';
import { zh_CN as chint_zh_CN } from '../modules/domain/chint/i18n/languages/zh_CN';
import { ChintIndexComponent } from '../modules/domain/chint/pages/chint-index/chint-index.component';
import { ChintI18nModule } from '../modules/i18n/chint-i18n.module';
import { I18nModule } from '../modules/i18n/i18n.module';
import { PreloadSelectedModulesList, routing } from '../routes/main.routes';
import { AppComponent } from './app.component';
import { I18N_TOKEN } from './i18n/i18n-token';
import { zh_CN } from './i18n/languages/zh_CN';
import { ErrorComponent } from './pages/error/error.component';
import { SanitizeUrlPipe } from './pipes/sanitize-url.pipe';
import { ContentsService } from './services/content/contents.service';
import { CounterService } from './services/content/counter.service';
import { ImageService } from './services/content/image.service';
import { ImageCategoryService } from './services/content/image_category.service';
import { ProjectConfigService } from './services/content/project-config.service';
import { ProjectService } from './services/content/project.service';
import { TemplatesService } from './services/content/template.service';
import { UserService } from './services/content/users.service';
import { MemberService } from './services/members/member.service';
import { SsoService } from './services/sso.service';
import { SiteNavigatorComponent } from './site-navigator/site-navigator.component';
import { PageNotFoundComponent } from '../modules/domain/chint/pages/pageNotFound/pageNotFound.component';
import { ChintNotfoundNavComponent } from '../modules/domain/chint/pages/pageNotFound/chint-notfound-nav/chint-notfound-nav.component';
import { ChintNotfoundFooterComponent } from '../modules/domain/chint/pages/pageNotFound/chint-notfound-footer/chint-notfound-footer.component';
import { GlobalService } from './services/global.service';
import { ProjectMenuService } from './services/content/project-menu.service';
import { ProductService } from '../modules/domain/chint/services/product.service';
import { NewsLetterService } from '../modules/domain/chint/services/news-letter.service';
import { SectionMenuComponent } from './components/section-menu/section-menu.component';
import { MenuGroupComponent } from './components/menu-group/menu-group.component';
import { TextComponent } from './text/text.component';
import { MenuGroupItemComponent } from './components/menu-group/menu-group-item/menu-group-item.component';
import { ImageComponent } from './components/image/image.component';
import { ItemEmptyAdderComponent } from './components/item-empty-adder/item-empty-adder.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CkeditorModule } from '../modules/ckeditor/ckeditor.module';
import { MixinModule } from '../modules/mixin/mixin.module';
import { ShareElementModule } from '../modules/share-element/share-element.module';
import { SectionMenuModule } from '../modules/section-menu/section-menu.module';
import { AutoSaveService } from './services/auto-save.service';
import { BasicGraphqlService } from './services/content/basic-graphql.service';

export const httpInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true}
];
const other_config = [];
//
// const env = process.env.NODE_ENV || 'dev'
// if (env === 'web-production') {
//   Raven.config('https://06951d245b5d4d80b8dbe138cac924bf@sentry.doteetv.com.cn/31').install();
// } else if (env !== 'development') {
//   Raven.config('https://d362308b1e0441d9b20b316ddd080f5d@sentry.doteetv.com.cn/30').install();
// }
//
// export class RavenErrorHandler implements ErrorHandler {
//   handleError(err: any): void {
//     Raven.captureException(err);
//   }
// }

// {
//   provide: ErrorHandler,
//     useClass: RavenErrorHandler
// }

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    SanitizeUrlPipe,
    SiteNavigatorComponent,
    PageNotFoundComponent,
    ChintNotfoundNavComponent,
    ChintNotfoundFooterComponent,
    // SectionMenuComponent,
    // MenuGroupComponent,
    // TextComponent,
    // MenuGroupItemComponent,
    // ItemEmptyAdderComponent
  ],
  imports: [
    routing,
    OverlayModule,
    HttpClientModule,
    AtModule,
    I18nModule,
    BrowserAnimationsModule,
    LoadingBarRouterModule,
    LoadingBarHttpModule,
    CommonModule,
    FormsModule,
    // ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production}),
    BrowserModule,
    ChintI18nModule,
    DragDropModule,
    ShareElementModule,
    MixinModule,
    SectionMenuModule
  ],
  providers: [HttpHelper,
    TemplatesService, ContentsService, CounterService, ImageService, ImageCategoryService, ProjectService, ProjectConfigService,
    {provide: I18N_TOKEN, useValue: zh_CN},
    {provide: Chint_I18N_TOKEN, useValue: chint_zh_CN},
    {provide: AT_I18N, useValue: at_zh_CN},
    DataHelper, httpInterceptorProviders, PreloadSelectedModulesList, ...other_config,
    SsoService, UserService, MemberService, GlobalService,ProjectMenuService, MemberService, ProductService, NewsLetterService,
    AutoSaveService, BasicGraphqlService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
