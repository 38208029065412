import { ModuleWithProviders } from '@angular/core';
import { PreloadingStrategy, Route, RouterModule, Routes } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ErrorComponent } from '../app/pages/error/error.component';
import { SiteNavigatorComponent } from '../app/site-navigator/site-navigator.component';

/**
 * Created by atyun on 7/25/17.
 */

export class PreloadSelectedModulesList implements PreloadingStrategy {
  preload(route: Route, load: Function): Observable<any> {
    const current_path = window.location.pathname;
    const currentFlat = ['/login', '/home'].includes(current_path);
    return currentFlat && route.data && route.data.preload ? load() : of(null);
  }
}

const appRoutes: Routes = [
  {path: 'sites', loadChildren: '../modules/site-render/site-render.module#SiteRenderModule', data: {preload: false}},
  {path: 'dashboard', loadChildren: '../modules/dashboard/dashboard.module#DashboardModule', data: {preload: false}},
  {path: 'chint', loadChildren: '../modules//domain/chint/chint.module#ChintModule', data: {preload: false}},
  {path: 'login', loadChildren: '../modules/login/login.module#LoginModule'},
  {path: 'home', loadChildren: '../modules/home/home.module#HomeModule'},
  {
    path: 'index', loadChildren: '../modules/cms/cms.module#CmsModule',
    data: {preload: true}
  },
  {path: 'operate', loadChildren: '../modules/operate/operate.module#OperateModule', data: {preload: false}},
{path: '', loadChildren: '../modules//domain/chint/chint.module#ChintModule', data: {preload: false}},
  {path: '**', loadChildren: '../modules/dashboard/dashboard.module#DashboardModule', data: {preload: false}}
];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes, {
  preloadingStrategy: PreloadSelectedModulesList,
  // 因为产品列表页不往顶部滚动，所以关了单独写window.scrollTo(0,0)
  // scrollPositionRestoration: 'enabled'
});
