import { Injectable } from '@angular/core';
import { Plugin } from '../../../model/common/plugin';
import { GraphqlBuilder } from '../../grapql/graphql_builder';
import { GlobalService } from '../global.service';
import { ContentsService } from './contents.service';

@Injectable()
export class UserPluginService {

  constructor(private content_service: ContentsService, private global_service: GlobalService) {
  }

  /**
   * get all user plugins
   * @param {{}} search_params
   * @returns {Observable<any>}
   */
  public my_plugins(search_params = {}) {
    const query = new GraphqlBuilder(Plugin);
    const body = query.getProperties();
    const search = query.query().build('plugin', body);
    search_params['user_plugins.user_id'] = this.global_service.$current_user.id;
    const params = {
      query: search,
      variables: {search: {...search_params}}
    };
    return this.content_service.query(params);
  }

  public plugins(search_params = {}) {
    const query = new GraphqlBuilder(Plugin);
    const body = query.getProperties();
    const search = query.query().build('pluginFull', body);
    const params = {
      query: search,
      variables: {search: {...search_params}}
    };
    return this.content_service.query(params);
  }

}
