var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { count } from '../../anotations/count.anotation';
import { DataHelper } from '../../helper/data.helper';
import { ProjectConfig } from '../../model/common/project_config';
import { I18nService } from '../i18n/i18n.service';
import { CounterService } from './content/counter.service';
import { ImageService } from './content/image.service';
import { ImageCategoryService } from './content/image_category.service';
import { TemplatesService } from './content/template.service';
import { en_US } from '../i18n/languages/en_US';
import { zh_CN } from '../i18n/languages/zh_CN';
import { AtI18nService, en_US as at_en_US, zh_CN as at_zh_CN } from 'at-ng';
import { ChintI18nService } from '../../modules/domain/chint/i18n/chint-i18n.service';
import { ar_SA } from '../../modules/domain/chint/i18n/languages/ar_SA';
import { en_US as chint_en_US } from '../../modules/domain/chint/i18n/languages/en_US';
import { es_ES } from '../../modules/domain/chint/i18n/languages/es_ES';
import { fr_FR } from '../../modules/domain/chint/i18n/languages/fr_FR';
import { it_IT } from '../../modules/domain/chint/i18n/languages/it_IT';
import { pt_PT } from '../../modules/domain/chint/i18n/languages/pt_PT';
import { ru_RU } from '../../modules/domain/chint/i18n/languages/ru_RU';
import { tr_TR } from '../../modules/domain/chint/i18n/languages/tr_TR';
import { zh_CN as chint_zh_CN } from '../../modules/domain/chint/i18n/languages/zh_CN';
import * as i0 from "@angular/core";
import * as i1 from "../../helper/data.helper";
import * as i2 from "./content/template.service";
import * as i3 from "./content/counter.service";
import * as i4 from "../i18n/i18n.service";
import * as i5 from "./content/image.service";
import * as i6 from "./content/image_category.service";
import * as i7 from "at-ng";
import * as i8 from "@angular/router";
import * as i9 from "../../modules/domain/chint/i18n/chint-i18n.service";
var getUrl = require('speakingurl');
var SaveStatus = /** @class */ (function () {
    function SaveStatus() {
        this.title = '正在编辑...';
        this.isSaving = false;
    }
    SaveStatus.prototype.saving = function (value) {
        var _this = this;
        setTimeout(function (_) {
            _this.isSaving = value;
            if (!value) {
                setTimeout(function (_) {
                    _this.title = '正在编辑...';
                }, 400);
            }
        });
        return this;
    };
    SaveStatus.prototype.text = function (value) {
        var _this = this;
        setTimeout(function (_) {
            _this.title = value;
        });
        return this;
    };
    return SaveStatus;
}());
var GlobalService = /** @class */ (function () {
    function GlobalService(data_helper, template_service, counter_service, i18n, image_service, image_category_service, atI18NService, router, chint_i18n_service) {
        var _this = this;
        this.data_helper = data_helper;
        this.template_service = template_service;
        this.counter_service = counter_service;
        this.i18n = i18n;
        this.image_service = image_service;
        this.image_category_service = image_category_service;
        this.atI18NService = atI18NService;
        this.router = router;
        this.chint_i18n_service = chint_i18n_service;
        this.locale_key = { 'zh_CN': zh_CN, 'en_US': en_US };
        this.atI18Nlocale = { 'zh_CN': at_zh_CN, 'en_US': at_en_US };
        this.chint_Locale_key = { 'zh_CN': chint_zh_CN, 'en_US': chint_en_US, 'pt-PT': pt_PT, 'es-ES': es_ES, 'fr-FR': fr_FR, 'ru-RU': ru_RU, 'it-IT': it_IT, 'tr-TR': tr_TR, 'ar-SA': ar_SA };
        this.configDrawerSubject = new Subject();
        this.first = true;
        this.modal = 'edit';
        // search terms
        this.searchTerms = new Subject();
        // 板块配置的弹窗
        this.config_drawer_visible = false;
        // 可撤销历史列表
        this.revoke_template_list = [];
        // 现在的template所在的位置
        this.revoke_index = 0;
        // 保存状态
        this.saveStatus = new SaveStatus();
        /**
         *   config drawer 相关配置
         */
        this.plugins = ['basic_config'];
        this.themes = [];
        this.image_index = 0;
        this.swiper_index = 0;
        // 是否在编辑轮播图图片
        this.edit_carousel = false;
        this.sunlight_index = 0;
        /**
         * 重新渲染
         * @type {Function}
         */
        this.render_contents = Function.prototype;
        this._current_project_config = null;
        this._template_select_list = new BehaviorSubject([]);
        this._system_categories_lib = new BehaviorSubject([]);
        this._user_image_lib = new BehaviorSubject([]);
        this._alternative_image_lib = new BehaviorSubject([]);
        this._content_template_list = new BehaviorSubject([]);
        this._system_content_list = new BehaviorSubject([]);
        this._project_menu = new BehaviorSubject(null);
        this._classifications = new BehaviorSubject([]);
        this._product_categories = new BehaviorSubject([]);
        this._product_labels = new BehaviorSubject([]);
        this._content_labels = new BehaviorSubject([]);
        this._shops = new BehaviorSubject([]);
        this.i18n.setLocale(this.locale_key[sessionStorage.getItem('locale') || 'zh_CN']);
        this.i18n.localChange.subscribe(function (locale) {
            _this.locale = locale;
        });
        this.atI18NService.setLocale(this.atI18Nlocale[sessionStorage.getItem('locale') || 'zh_CN']);
        this.chint_i18n_service.setLocale(this.chint_Locale_key.en_US);
        this.chint_i18n_service.localChange.subscribe(function (locale) {
            _this.chint_local = locale;
        });
    }
    Object.defineProperty(GlobalService.prototype, "loginTemplateId", {
        get: function () {
            return this._login_template_id;
        },
        set: function (id) {
            this._login_template_id = id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "device_type", {
        get: function () {
            if (this.modal !== 'edit') {
                this._pc_editor = window.document.body.clientWidth > 781 ? true : false;
            }
            return window.document.body.clientWidth > 781 ? 'pc' : 'mobile';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "pc_editor", {
        get: function () {
            return this._pc_editor;
        },
        set: function (pc_editor) {
            this._pc_editor = pc_editor;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "edit_modal", {
        get: function () {
            return this.modal === 'edit';
        },
        enumerable: true,
        configurable: true
    });
    GlobalService.prototype.addProjectCounter = function () {
        if (this.current_project) {
            var params = {
                create: {
                    count_type: 'project_active',
                    resource_type: 'Project',
                    resource_id: this.current_project.id
                }
            };
            this.counter_service.project_count(params).subscribe(function (data) {
            });
        }
    };
    Object.defineProperty(GlobalService.prototype, "template_animation", {
        get: function () {
            if (this.editing_template && this.editing_template.template_animation) {
                return this.editing_template.template_animation;
            }
            else {
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "background_animation", {
        get: function () {
            if (this.editing_template && this.editing_template.background_animation) {
                return this.editing_template.background_animation;
            }
            else {
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "current_project", {
        get: function () {
            var data = localStorage.getItem('current_project');
            var _current_project = JSON.parse(data);
            return _current_project;
        },
        set: function (value) {
            localStorage.setItem('current_project', JSON.stringify(value));
        },
        enumerable: true,
        configurable: true
    });
    GlobalService.prototype.user_ids = function (type) {
        return [];
        // if (type === 'news') {
        //   if (this.current_project.shop_id == this.current_project.main_shop_id) {
        //     const ids = this.current_project.news_share_ids || [];
        //     ids.push(this.current_project.user_id)
        //     return ids;
        //   } else {
        //     return this.current_project.news_shared_by ? [this.$current_user_id || this.current_project.user_id, this.current_project.manager_id] : [this.$current_user_id || this.current_project.user_id];
        //   }
        // } else {
        //   if (this.current_project.shop_id == this.current_project.main_shop_id) {
        //     const ids = this.current_project.document_share_ids || [];
        //     ids.push(this.current_project.user_id)
        //     return ids;
        //   } else {
        //     return this.current_project.document_shared_by ? [this.$current_user_id || this.current_project.user_id, this.current_project.manager_id] : [this.$current_user_id || this.current_project.user_id];
        //   }
        // }
    };
    GlobalService.prototype.tmp_user_ids = function (type) {
        if (type === void 0) { type = ''; }
        if (this.$current_user && this.$current_user.post === '管理员') {
            return [];
        }
        else {
            if (type === 'document' && !this.current_project.document_shared_by) {
                return [this.current_project.user_id];
            }
            else {
                return [this.current_project.user_id, this.current_project.manager_id];
            }
        }
    };
    Object.defineProperty(GlobalService.prototype, "current_project_config", {
        get: function () {
            return this._current_project_config || new ProjectConfig();
        },
        set: function (value) {
            // this.generateMetaContent(value);
            this._current_project_config = value;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * meta tag generator from project configs
     * @param config
     */
    GlobalService.prototype.generateMetaContent = function (config) {
        var _this = this;
        var meta_name = [{ name: 'name', column: 'title' }, { name: 'keywords', column: 'keyword' }, {
                name: 'description',
                column: 'desc'
            }];
        // 设置name的meta
        meta_name.forEach(function (name) {
            var meta = document.createElement('meta');
            meta.content = _this.current_project[name.column];
            meta.name = name.name;
            document.getElementsByTagName('head')[0].appendChild(meta);
        });
        // 设置image 之类的
        var og_name = [
            { name: 'og:image', project: false, column: 'share_pic' },
            { name: 'og:title', project: true, column: 'title' },
            { name: 'og:site_name', project: true, column: 'name' },
            { name: 'og:description', project: true, column: 'desc' }
        ];
        og_name.forEach(function (tag) {
            var value = tag.project ? _this.current_project[tag.column] : config[tag.column];
            var meta = document.createElement('meta');
            meta.content = value;
            meta.setAttribute('property', tag.name);
            document.getElementsByTagName('head')[0].appendChild(meta);
        });
        // itemprop
        var item_prop = [
            { name: 'name', column: 'name', project: true },
            { name: 'image', column: 'share_pic', project: false },
            { name: 'description', project: true, column: 'desc' }
        ];
        item_prop.forEach(function (item) {
            var value = item.project ? _this.current_project[item.column] : config[item.column];
            var meta = document.createElement('meta');
            meta.content = value;
            meta.setAttribute('itemprop', item.name);
            document.getElementsByTagName('head')[0].appendChild(meta);
        });
    };
    Object.defineProperty(GlobalService.prototype, "$current_form_session", {
        get: function () {
            return JSON.parse(sessionStorage.getItem('form_session_info'));
        },
        set: function (value) {
            sessionStorage.setItem('form_session_info', value);
        },
        enumerable: true,
        configurable: true
    });
    GlobalService.prototype.addFormSessionKey = function (key, value) {
        var session = this.$current_form_session;
        if (!session) {
            session = {};
        }
        session[key] = value;
        this.$current_form_session = JSON.stringify(session);
    };
    Object.defineProperty(GlobalService.prototype, "$current_user", {
        /**
         * 当前登录用户
         */
        get: function () {
            var user = localStorage.getItem('user');
            user = JSON.parse(user);
            return user;
        },
        set: function (value) {
            localStorage.setItem('user', JSON.stringify(value));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "$product_user", {
        /**
         * 商品管理平台用户
         */
        get: function () {
            var product_user = localStorage.getItem('product_user');
            product_user = JSON.parse(product_user);
            return product_user;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "$current_shop", {
        get: function () {
            var current_shop = localStorage.getItem('current_shop');
            current_shop = JSON.parse(current_shop);
            return current_shop;
        },
        set: function (value) {
            localStorage.setItem('current_shop', JSON.stringify(value));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "$current_user_id", {
        get: function () {
            return localStorage.getItem('current_user_id');
        },
        // 当前管理站点的定制平台用户id，不是当前登录用户的Id
        set: function (value) {
            localStorage.setItem('current_user_id', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "ProjectId", {
        set: function (value) {
            this._project_id = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "projectID", {
        get: function () {
            if (this._project_id) {
                return this._project_id;
            }
            var hostname = window.location.hostname;
            var site = hostname.split('.')[0];
            var id = site.split('site-')[1];
            return this.data_helper.encodeId(id);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "SystemCategories", {
        get: function () {
            return this._system_categories_lib.asObservable();
        },
        set: function (value) {
            this._system_categories_lib.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "UserImage", {
        get: function () {
            return this._user_image_lib.asObservable();
        },
        set: function (value) {
            this._user_image_lib.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "AlternativeImage", {
        get: function () {
            return this._alternative_image_lib.asObservable();
        },
        set: function (value) {
            this._alternative_image_lib.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "template_select_list", {
        /**
         * return cache templates
         * @returns {any}
         */
        get: function () {
            return this._template_select_list.asObservable();
        },
        set: function (value) {
            var templates = value;
            if (!this.current_project_config.document_center_status) {
                templates = templates.filter(function (item) {
                    return ['document_center', 'document_search', 'document_detail'].indexOf(item.business_category) === -1;
                });
            }
            this._template_select_list.next(templates);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "content_template_list", {
        get: function () {
            return this._content_template_list.asObservable();
        },
        set: function (value) {
            this._content_template_list.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "classifications", {
        get: function () {
            return this._classifications.asObservable();
        },
        set: function (value) {
            this._classifications.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "content_labels", {
        get: function () {
            return this._content_labels.asObservable();
        },
        set: function (value) {
            this._content_labels.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "shops", {
        get: function () {
            return this._shops.asObservable();
        },
        set: function (value) {
            this._shops.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "product_labels", {
        get: function () {
            return this._product_labels.asObservable();
        },
        set: function (value) {
            this._product_labels.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "system_content_list", {
        /**
         * 获取系统板块
         */
        get: function () {
            return this._system_content_list.asObservable();
        },
        set: function (value) {
            this._system_content_list.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "product_categories", {
        /**
        *  获取商品分类
        */
        get: function () {
            return this._product_categories.asObservable();
        },
        set: function (value) {
            this._product_categories.next(value);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * revoke content's change
     */
    GlobalService.prototype.set_editing_template = function () {
        if (this.revoke_index == this.revoke_template_list.length - 1) {
            this.revoke_template_list.push(this.data_helper.deepCopy(this.editing_template));
            this.revoke_index += 1;
        }
        else {
            var list_length = this.data_helper.deepCopy(this.revoke_template_list).length;
            var revoke_index = this.data_helper.deepCopy(this.revoke_index);
            this.revoke_template_list.splice(revoke_index + 1, list_length - revoke_index - 1, this.data_helper.deepCopy(this.editing_template));
            this.revoke_index = this.data_helper.deepCopy(this.revoke_template_list).length - 1;
        }
    };
    Object.defineProperty(GlobalService.prototype, "html_fontSize", {
        get: function () {
            return parseFloat(window.document.documentElement.style.fontSize.replace('px', ''));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "ProjectMenu", {
        get: function () {
            return this._project_menu.asObservable();
        },
        set: function (value) {
            this._project_menu.next(value);
        },
        enumerable: true,
        configurable: true
    });
    GlobalService.prototype.sectionBackgroundMode = function (content) {
        return {
            'y-section--tiling': content.is_tiling,
            'y-section--locked': content.is_locked,
            'y-section--normal': !content.is_tiling && !content.is_locked && content.alignment === ''
        };
    };
    GlobalService.prototype.sectionBackground = function (content) {
        return content.background ? 'url(' + content.background + ')' : content.background_color;
    };
    GlobalService.prototype.goTo = function (menu, current_menu) {
        var _this = this;
        if (current_menu && current_menu.name) {
            localStorage.setItem('current_menu', current_menu.name);
        }
        var template_id;
        if (menu.link && typeof (menu.link) === 'string') {
            window.open(menu.link);
            return;
        }
        if (typeof (menu) === 'string') {
            switch (menu) {
                case 'Products Center':
                    template_id = this.current_project_config.product_center_page_id;
                    break;
                case 'Services Center':
                    template_id = this.current_project_config.service_center_page_id;
                    break;
                case 'About Us':
                    template_id = this.current_project_config.about_us_page_id;
                    break;
                case 'Join Us':
                    template_id = this.current_project_config.join_us_page_id;
                    break;
                case 'contact_us':
                    template_id = this.current_project_config.contact_us_page_id;
                    break;
                case 'news':
                    template_id = this.current_project_config.news_center_page_id;
                    break;
                case 'download':
                    template_id = this.current_project_config.download_center_page_id;
                    break;
                case 'membership':
                    template_id = this.current_project_config.membership_page_id;
                    break;
                case 'login':
                    template_id = this.current_project_config.login_page_id;
                    break;
                case 'memberCenter':
                    template_id = this.current_project_config.member_center_page_id;
                    break;
            }
        }
        else {
            template_id = menu.template_id;
        }
        if (template_id) {
            if (this.modal === 'show') {
                this.template_service.templates({ 'search[id]': template_id }).subscribe(function (data) {
                    var template = (data || [])[0];
                    if (menu.target_blank) {
                        if (template.business_category === 'solution') {
                            window.open('/' + template.business_category + '/' + _this.generatePath(template.name, template_id));
                        }
                        else {
                            window.open('/' + _this.generatePath(template.business_category, template_id));
                        }
                    }
                    else {
                        if (template.business_category === 'solution') {
                            _this.router.navigate(['/' + template.business_category + '/' + _this.generatePath(template.name, template_id)]);
                        }
                        else {
                            _this.router.navigate(['/' + _this.generatePath(template.business_category, template_id)]);
                        }
                    }
                });
            }
            else {
                if (menu.target_blank) {
                    window.open("/dashboard/preview/" + this.projectID + "/template/" + template_id);
                }
                else {
                    this.router.navigate(["/dashboard/preview/" + this.projectID + "/template/", template_id]);
                }
            }
        }
        else if (menu === 'home') {
            if (this.modal === 'show') {
                this.router.navigate(['/']);
            }
            else {
                this.router.navigate(["/dashboard/preview/" + this.projectID + "/template/" + this.current_project_config.chint_homepage_id]);
            }
        }
    };
    GlobalService.prototype.getGoToURL = function (menu, current_menu) {
        var _this = this;
        if (current_menu && current_menu.name) {
            localStorage.setItem('current_menu', current_menu.name);
        }
        var template_id;
        var url = '';
        if (menu.link && typeof (menu.link) === 'string') {
            return new Promise(function (resolve) { return resolve(menu.link); });
        }
        if (typeof (menu) === 'string') {
            switch (menu) {
                case 'Products Center':
                    template_id = this.current_project_config.product_center_page_id;
                    break;
                case 'Services Center':
                    template_id = this.current_project_config.service_center_page_id;
                    break;
                case 'About Us':
                    template_id = this.current_project_config.about_us_page_id;
                    break;
                case 'Join Us':
                    template_id = this.current_project_config.join_us_page_id;
                    break;
                case 'contact_us':
                    template_id = this.current_project_config.contact_us_page_id;
                    break;
                case 'news':
                    template_id = this.current_project_config.news_center_page_id;
                    break;
                case 'download':
                    template_id = this.current_project_config.download_center_page_id;
                    break;
                case 'membership':
                    template_id = this.current_project_config.membership_page_id;
                    break;
                case 'login':
                    template_id = this.current_project_config.login_page_id;
                    break;
                case 'memberCenter':
                    template_id = this.current_project_config.member_center_page_id;
                    break;
            }
        }
        else {
            template_id = menu.template_id;
        }
        if (template_id) {
            if (this.modal === 'show') {
                return new Promise(function (resolve) {
                    _this.template_service.templates({ 'search[id]': template_id }).subscribe(function (data) {
                        var template = (data || [])[0];
                        if (template) {
                            if (template.business_category === 'solution') {
                                url = '/' + template.business_category + '/' + _this.generatePath(template.name, template_id);
                            }
                            else {
                                url = '/' + _this.generatePath(template.business_category, template_id);
                            }
                        }
                        resolve(window.location.origin + url + '/');
                    });
                });
            }
            else {
                if (menu.target_blank) {
                    url = "/dashboard/preview/" + this.projectID + "/template/" + template_id;
                }
                else {
                    url = "/dashboard/preview/" + this.projectID + "/template/" + template_id;
                }
            }
        }
        else if (menu === 'home') {
            if (this.modal === 'show') {
                url = '';
            }
            else {
                url = "/dashboard/preview/" + this.projectID + "/template/" + this.current_project_config.chint_homepage_id;
            }
        }
        return new Promise(function (resolve) {
            resolve(window.location.origin + url + '/');
        });
    };
    GlobalService.prototype.goProductDetail = function (product, target_blank) {
        if (target_blank === void 0) { target_blank = false; }
        var product_detail_page_id = this.current_project_config.product_detail_page_id;
        var product_id = product.id;
        if (this.modal === 'show') {
            if (target_blank) {
                window.open('/products/' + this.generatePath(product.name, product_detail_page_id, product_id));
            }
            else {
                this.router.navigate(['/products/' + this.generatePath(product.name, product_detail_page_id, product_id)]);
            }
        }
        else {
            if (target_blank) {
                window.open("/dashboard/preview/" + this.current_project.id + "/template/" + product_detail_page_id + "?product_id=" + product_id);
            }
            else {
                this.router.navigate(["/dashboard/preview/" + this.current_project.id + "/template/" + product_detail_page_id], { queryParams: { product_id: product_id } });
            }
        }
    };
    GlobalService.prototype.getProductDetailURL = function (product, target_blank) {
        if (target_blank === void 0) { target_blank = false; }
        var product_detail_page_id = this.current_project_config.product_detail_page_id;
        var product_id = product.id;
        var url;
        if (this.modal === 'show') {
            url = '/products/' + this.generatePath(product.name, product_detail_page_id, product_id);
        }
        else {
            url = "/dashboard/preview/" + this.current_project.id + "/template/" + product_detail_page_id + "?product_id=" + product_id;
        }
        return location.origin + url;
    };
    GlobalService.prototype.goNewsDetail = function (news, target_blank) {
        if (target_blank === void 0) { target_blank = false; }
        var news_id = news.id;
        if (!this.edit_modal) {
            if (this.modal === 'show') {
                if (target_blank) {
                    window.open('/news/' + this.generatePath(news.title || news.name, this.current_project_config.news_detail_page_id, news_id));
                }
                else {
                    this.router.navigate(['/news/' + this.generatePath(news.title || news.name, this.current_project_config.news_detail_page_id, news_id)]);
                }
            }
            else {
                if (target_blank) {
                    window.open("/dashboard/preview/" + this.projectID + "/template/" + this.current_project_config.news_detail_page_id + "?news_id=" + news_id);
                }
                else {
                    this.router.navigate(["/dashboard/preview/" + this.projectID + "/template/" + this.current_project_config.news_detail_page_id], { queryParams: { news_id: news_id } });
                }
            }
        }
    };
    GlobalService.prototype.getGoNewsDetailURL = function (news, target_blank) {
        if (target_blank === void 0) { target_blank = false; }
        var url = '';
        var news_id = news.id;
        if (!this.edit_modal) {
            if (this.modal === 'show') {
                // url = '/news/' + this.current_project_config.news_detail_page_id + '?news_id=' + news_id;
                url = '/news/' + this.generatePath(news.title || news.name, this.current_project_config.news_detail_page_id, news_id) + '/';
            }
            else {
                url = "/dashboard/preview/" + this.projectID + "/template/" + this.current_project_config.news_detail_page_id + "?news_id=" + news_id;
            }
        }
        return location.origin + url;
    };
    Object.defineProperty(GlobalService.prototype, "dataformat", {
        get: function () {
            return sessionStorage.getItem('locale') === 'en_US' ? 'LL' : 'YYYY-MM-DD';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "shop_ids", {
        get: function () {
            if (this.current_project.shop_id == this.current_project.main_shop_id) {
                var ids = this.current_project.shop_share_ids || [];
                ids.push(this.current_project.shop_id);
                return ids;
            }
            else {
                return this.current_project.product_shared_by ? [this.current_project.shop_id, this.current_project.main_shop_id] : [this.current_project.shop_id];
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalService.prototype, "is_main_shop", {
        get: function () {
            return this.$current_shop.id === this.current_project.main_shop_id;
        },
        enumerable: true,
        configurable: true
    });
    GlobalService.prototype.generatePath = function (url, id, sec_id, thir_id) {
        var urlStr = url.replace(/[\_\&\¥\$]/g, '-');
        var nameArr = getUrl("" + url, {
            custom: {
                '_': '-',
                '&': '-',
                '¥': '-',
                '$': '-'
            }
        }).split('-');
        var urlArr = [];
        nameArr.map(function (item) {
            if (item.length) {
                urlArr.push(item);
            }
        });
        if (!nameArr[0].length) {
            urlArr.push(url);
        }
        if (id) {
            urlArr.push(id);
        }
        if (sec_id) {
            urlArr.push(sec_id);
        }
        if (thir_id) {
            urlArr.push(thir_id);
        }
        return urlArr.join('-').toLocaleLowerCase();
    };
    GlobalService.prototype.getParamsFromUrl = function (url, index, type) {
        var url_arr = url.split('-');
        var params_arr = [];
        var page;
        // url_arr.reverse().map((item, index) => {
        //   // 最多只传三个参数
        //   if (item.includes('page')) {
        //   page = item.split('page')[1];
        //   }
        //   if (!isNaN(Number(item)) && index <= 3) {
        //     params_arr.push(item);
        //   }
        // });
        var reverse_url_arr = url_arr.reverse();
        for (var i = 0; i < reverse_url_arr.length; i++) {
            var item = reverse_url_arr[i];
            // 最多只传三个参数
            if (isNaN(Number(item)) && !item.includes('page')) {
                break;
            }
            if (item.includes('page')) {
                page = item.split('page')[1];
            }
            if (!isNaN(Number(item)) && index <= 3) {
                params_arr.push(item);
            }
        }
        params_arr = params_arr.reverse();
        if (type === 'page') {
            return page;
        }
        return params_arr[index];
    };
    GlobalService.prototype.appendChildToBody = function (code) {
        var script_arr = document.body.querySelectorAll('script');
        for (var i = 0; i < script_arr.length; i++) {
            if (script_arr[i].type === 'application/ld+json') {
                document.body.removeChild(script_arr[i]);
            }
        }
        var script = document.createElement('script');
        script.type = 'application/ld+json';
        script.appendChild(document.createTextNode(JSON.stringify(code)));
        document.body.appendChild(script);
    };
    GlobalService.prototype.appendChildToHead = function (code) {
        var script_arr = document.head.querySelectorAll('script');
        for (var i = 0; i < script_arr.length; i++) {
            if (script_arr[i].type === 'application/ld+json') {
                document.head.removeChild(script_arr[i]);
            }
        }
        var script = document.createElement('script');
        script.type = 'application/ld+json';
        script.appendChild(document.createTextNode(JSON.stringify(code)));
        document.head.appendChild(script);
    };
    GlobalService.prototype.appendCanonicalToHead = function () {
        var link = document.createElement('link');
        link.rel = 'canonical';
        link.href = location.href;
        document.head.appendChild(link);
    };
    GlobalService.prototype.appendTwitterCardToHead = function (metaName, content) {
        var meta_arr = document.head.getElementsByTagName('meta');
        // for (let i =0; i < meta_arr.length; i++) {
        //   if (meta_arr[i].name === `twitter:${metaName}`) {
        //     meta_arr[i].parentNode.removeChild(meta_arr[i]);
        //   }
        // }
        var meta = document.createElement('meta');
        meta.name = "twitter:" + metaName;
        meta.content = content;
        document.head.appendChild(meta);
    };
    GlobalService.prototype.appendOGtoHead = function (property, content) {
        var meta_arr = document.head.getElementsByTagName('meta');
        // for (let i =0; i < meta_arr.length; i++) {
        //   if (meta_arr[i].getAttribute('property') === `og:${property}`) {
        //     meta_arr[i].parentNode.removeChild(meta_arr[i]);
        //   }
        // }
        var meta = document.createElement('meta');
        meta.setAttribute('property', "og:" + property);
        meta.content = content;
        document.head.appendChild(meta);
    };
    GlobalService.prototype.setDocumentTitle = function (title) {
        document.title = title + this.current_project.title_abbr;
    };
    GlobalService.prototype.setDocumentDescription = function (description_meta) {
        var desc_meta = Array.from(document.querySelectorAll('meta')).find(function (item) { return item.name == 'description'; });
        desc_meta.setAttribute('content', description_meta);
    };
    GlobalService.prototype.getTextFromRichText = function (str) {
        var patt = /[0-9]+\.[0-9]+/;
        var target_str = str.replace(/<[^<>]+>/g, '').replace(/&nbsp;/gi, '').replace(patt, '');
        if (target_str.length > 200) {
            target_str = target_str.slice(0, 200) + '...';
        }
        return target_str;
    };
    GlobalService.prototype.appendAToPagination = function (page, searchKey) {
        var base_url = location.href;
        base_url = base_url.split('?')[0];
        if (base_url.includes('-page')) {
            var index = base_url.indexOf('-page');
            base_url = base_url.slice(0, index);
        }
        document.querySelector('at-pagination').querySelectorAll('li').forEach(function (item) {
            if (item.title == 'First Page') {
                if (page != 1) {
                    item.className = item.className.replace('at-pagination__item--active', '');
                }
                else {
                    item.className = item.className + ' at-pagination__item--active';
                }
                item.innerHTML = "<a href='" + base_url + "-page1" + (searchKey ? '?searchKey=' + searchKey : '') + "' style=\"color: " + (page == 1 ? '#fff' : '#3F536E') + "\">1</a>";
            }
            ;
            if (!isNaN(Number(item.title))) {
                if (page == item.title) {
                    item.className = item.className + ' at-pagination__item--active';
                }
                else {
                    item.className = item.className.replace('at-pagination__item--active', '');
                }
                item.innerHTML = "<a href='" + base_url + "-page" + item.title + (searchKey ? '?searchKey=' + searchKey : '') + "' style=\"color: " + (page == item.title ? '#fff' : '#3F536E') + "\">" + item.title + "</a>";
            }
            if (item.title.indexOf('Last Page:') !== -1) {
                var page_arr = item.title.split(':');
                if (page != page_arr[1]) {
                    item.className = item.className.replace('at-pagination__item--active', '');
                }
                else {
                    item.className = item.className + ' at-pagination__item--active';
                }
                item.innerHTML = "<a href='" + base_url + "-page" + page_arr[1] + (searchKey ? '?searchKey=' + searchKey : '') + "' style=\"color: " + (page == page_arr[1] ? '#fff' : '#3F536E') + "\">" + page_arr[1] + "</a>";
            }
        });
    };
    GlobalService.prototype.removeAttribute = function () {
        Array.from(document.querySelectorAll('a')).filter(function (item) { return item.getAttribute('href') === 'void'; }).forEach(function (a) { return a.removeAttribute('href'); });
    };
    GlobalService.prototype.getQueryValue = function (queryName) {
        var reg = new RegExp("(^|&)" + queryName + "=([^&]*)(&|$)", "i");
        var val = window.location.search.substr(1).match(reg);
        if (val !== null) {
            return decodeURI(val[2]);
        }
        else {
            return null;
        }
    };
    GlobalService.prototype.getScreenSize = function () {
        var width = window.screen.availWidth;
        var size = 'medium';
        if (width < 720) {
            size = 'small';
        }
        else if (width > 960) {
            size = 'large';
        }
        return size;
    };
    GlobalService.ngInjectableDef = i0.defineInjectable({ factory: function GlobalService_Factory() { return new GlobalService(i0.inject(i1.DataHelper), i0.inject(i2.TemplatesService), i0.inject(i3.CounterService), i0.inject(i4.I18nService), i0.inject(i5.ImageService), i0.inject(i6.ImageCategoryService), i0.inject(i7.AtI18nService), i0.inject(i8.Router), i0.inject(i9.ChintI18nService)); }, token: GlobalService, providedIn: "root" });
    __decorate([
        count('link', { resource_id: '', project_id: '', resource_type: 'Template' }),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Object]),
        __metadata("design:returntype", void 0)
    ], GlobalService.prototype, "goTo", null);
    return GlobalService;
}());
export { GlobalService };
