import { Injectable, Injector } from '@angular/core';
import { Content } from '../../model/common/content';
import { LinkedList } from '../object-version-manage/linked-list';

@Injectable()
export class MainInjectorService {
  static instance: MainInjectorService;
  static isCreating: Boolean = false;
  private _injector: Injector;
  private _plugins = [];
  private _contentHistories: LinkedList<Content>  = new LinkedList();

  constructor() {
    if (!MainInjectorService.isCreating) {
      throw new Error('只能存在一个实例');
    }
  }

  public static getInstance() {
    if (MainInjectorService.instance == null) {
      MainInjectorService.isCreating = true;
      MainInjectorService.instance = new MainInjectorService();
      MainInjectorService.isCreating = false;
    }

    return MainInjectorService.instance;
  }

  public setInjector(injector: Injector) {
    this._injector = injector;
  }

  public getInjector(): Injector {
    return this._injector;
  }

  get plugins(): any[] {
    return this._plugins;
  }

  set plugins(value: any[]) {
    this._plugins = value;
  }

  get contentHistories(): LinkedList<Content> {
    return this._contentHistories;
  }

  set contentHistories(value: LinkedList<Content>) {
    this._contentHistories = value;
  }
}
