/**
 * 图片
 */
export class Image {
  file: File | string;
  thumbnail: string;
  id: string;
  status: string;
  created_at: string;
  file_size: string;
  width: string;
  height: string;

  constructor() {
    this.file = null;
    this.thumbnail = null;
    this.id = null;
    this.status = null;
    this.created_at = null;
  }
}
