import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SanitizeHtmlPipe } from '../../app/pipes/sanitize-html.pipe';

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule, JsonpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { DragItemContainerComponent } from '../../app/components/drag-item-container/drag-item-container.component';
import { DragItemComponent } from '../../app/components/drag-item/drag-item.component';
import { MenuGroupItemComponent } from '../../app/components/menu-group/menu-group-item/menu-group-item.component';
import { MenuGroupComponent } from '../../app/components/menu-group/menu-group.component';
import { HoverClassDirective } from '../../app/diretives/hover-class.directive';
import { ImagePreviewDirective } from '../../app/diretives/image_preview.directive';
import { NavLinkDirective } from '../../app/diretives/nav-link.directive';
import { CdnAssetsPipe } from '../../app/pipes/cdn-assets.pipe';
import { DynamicDataParserPipe } from '../../app/pipes/dynamic-data-parser.pipe';
import { KeysPipe } from '../../app/pipes/keys.pipe';
import { SelectJsonPipe } from '../../app/pipes/select_array';
import { SystemStylePipe } from '../../app/pipes/system-style.pipe';
import { ContentsService } from '../../app/services/content/contents.service';
import { CounterService } from '../../app/services/content/counter.service';
import { CustomFormService } from '../../app/services/content/custom-form.service';
import { ImageService } from '../../app/services/content/image.service';
import { ImageCategoryService } from '../../app/services/content/image_category.service';
import { MessageMailService } from '../../app/services/content/message_mail.service';
import { ProjectService } from '../../app/services/content/project.service';
import { TemplateInstanceService } from '../../app/services/content/template-instance.service';
import { TemplatesService } from '../../app/services/content/template.service';
import { UserPluginService } from '../../app/services/content/user-plugin.service';
import { UserService } from '../../app/services/content/users.service';
import { GlobalService } from '../../app/services/global.service';
import { GraphqlBuilderService } from '../../app/services/graphql_builder.service';
import { CmsUserService } from '../../app/services/user/user.service';
import { DataHelper } from '../../helper/data.helper';
import { HttpHelper } from '../../helper/http.helper';
import { CmsAccountInterceptor } from '../../interceptor/cms_login.intercept';
import { AtModule } from 'at-ng';

@NgModule({
  imports: [
    FormsModule,
    HttpClientModule,
    CommonModule,
    AtModule
  ],
  declarations: [
    ImagePreviewDirective,
    DynamicDataParserPipe,
    SanitizeHtmlPipe, SystemStylePipe, KeysPipe, HoverClassDirective,
    SelectJsonPipe,
    CdnAssetsPipe,
    DragItemComponent,
    DragItemContainerComponent,
    MenuGroupComponent,
    MenuGroupItemComponent,
    NavLinkDirective,
  ],
  exports: [ImagePreviewDirective, SanitizeHtmlPipe, CdnAssetsPipe, DragItemComponent,
    DragItemContainerComponent,
    MenuGroupComponent,
    MenuGroupItemComponent,
    DynamicDataParserPipe, SelectJsonPipe, FormsModule, SystemStylePipe,
    KeysPipe, HoverClassDirective, NavLinkDirective],
  providers: [GlobalService, HttpHelper, DataHelper, CounterService,
    UserPluginService,
    TemplatesService, ImageService, ImageCategoryService, GraphqlBuilderService, MessageMailService]

})
export class MixinModule {
}
