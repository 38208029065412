import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import { HttpHelper } from '../../../helper/http.helper';

@Injectable()
export class MemberService {

  constructor(private http: HttpHelper) {
  }

  public members(search_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('atyun/members.json?', search_params).pipe(map(data => this.http.extractData(data)));
  }

  public update(id: any, update_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_PUT('atyun/members/' + id + '.json', update_params);
  }

  public delete(id: string): Observable<any> {
    const params = {member_id: id};
    return this.http.AUTH_HTTP_DELETE('atyun/members/' + id + '.json', params);
  }

  public create(create_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_POST('atyun/members.json', create_params);
  }

  public batch_delete(params): Observable<any> {
    return this.http.AUTH_HTTP_DELETE('atyun/members/batch_delete.json', params);
  }

  public login(params = {}): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_POST('atyun/members/login.json', params);
  }

  public send_validate_code(phone) {
    const params = {
      phone
    };
    return this.http.AUTH_HTTP_POST('atyun/members/send_phone_code.json', params);
  }

  public send_email_code(params: any): Observable<any> {

    return this.http.AUTH_HTTP_POST('atyun/members/send_email_code.json', params);
  }

  public reset_password_email(params: any): Observable<any> {
    return this.http.AUTH_HTTP_POST('atyun/members/reset_password_email.json', params);
  }

  public get $current_member(): any {
    let member = localStorage.getItem('member');
    member = JSON.parse(member);
    return member;
  }

  public set $current_member(value) {
    localStorage.setItem('member', JSON.stringify(value));
  }

  public getPassword(cms_login_config_id): any {
    const password = localStorage.getItem('cms_login_config_password' + cms_login_config_id);
    return password;
  }

  public setPassword(cms_login_config_id, value): void {
    localStorage.setItem('cms_login_config_password' + cms_login_config_id, value);
  }

  public check_member_code(params = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('atyun/members/check_member_code.json?', params);
  }

  public active_pass_email(params: any): Observable<any> {
    return this.http.AUTH_HTTP_POST('atyun/members/active_pass_email.json', params);
  }

  public member_login_ticket(params: any): Observable<any> {
    return this.http.AUTH_HTTP_GET('atyun/members/member_login_ticket.json', params);
  }

  public sign_out(id: string): Observable<any> {
    const params = {id}
    return this.http.AUTH_HTTP_GET('atyun/members/sign_out.json?', params);
  }

  public check_member_status(id: string): Observable<any> {
    const params = {id}
    return this.http.AUTH_HTTP_GET('atyun/members/check_member_status.json?', params);
  }

}
