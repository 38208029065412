import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { QRCodeModule } from 'angular2-qrcode';
import { AtModule } from 'at-ng';
import { ButtonComponent } from '../../app/components/button/button.component';
import { CookLoadingComponent } from '../../app/components/cook-loading/cook-loading.component';
import { DragGridContainerDirective } from '../../app/components/drag-grid/drag-grid-container.directive';
import { DragGridItemComponent } from '../../app/components/drag-grid/drag-grid-item/drag-grid-item.component';
import { DragGridComponent } from '../../app/components/drag-grid/drag-grid.component';
import { RightClickMenuComponent } from '../../app/components/drag-grid/right-click-menu/right-click-menu.component';
import { DragMoveItemDirective } from '../../app/components/drag-move-item/drag-move-item.component';
import { EmptyBoxComponent } from '../../app/components/empty-box/empty-box.component';
import { EmptyDataComponent } from '../../app/components/empty-data/empty-data.component';
import { FileButtonComponent } from '../../app/components/file-button/file-button.component';
import { FormEditorComponent } from '../../app/components/form-editor/form-editor.component';
import { HandleCoverComponent } from '../../app/components/handle-cover/handle-cover.component';
import { ImageModalComponent } from '../../app/components/image-modal/image-modal.component';
import { ImageComponent } from '../../app/components/image/image.component';
import { ItemEmptyAdderComponent } from '../../app/components/item-empty-adder/item-empty-adder.component';
import { MapEditorComponent } from '../../app/components/map-editor/map-editor.component';
import { NavMobileComponent } from '../../app/components/nav-mobile/nav-mobile.component';
import { NavModalComponent } from '../../app/components/nav-modal/nav-modal.component';
import { NavSelectedAnimationComponent } from '../../app/components/nav-selected-animation/nav-selected-animation.component';
import { MenuTitleDropdownComponent } from '../../app/components/nav-title-dropdown/menu-title-dropdown.component';
import { NavTitleDropdownComponent } from '../../app/components/nav-title-dropdown/nav-title-dropdown.component';
import { ResizeHandleComponent } from '../../app/components/resize-handle/resize-handle.component';
import { TitleTestComponentsComponent } from '../../app/components/title-test-components/title-test-components.component';
import { AssetEditorDirective } from '../../app/diretives/asset-editor.direvtive';
import { MalihuScrollbarDirective } from '../../app/diretives/beautiful-scroll/malihu-scroll.directive';
import { CkeditorDirective } from '../../app/diretives/ckeditor.directive';
import { CustomColorDirective } from '../../app/diretives/custom-color.directive';
import { EchartsDirective } from '../../app/diretives/echarts.directive';
import { ImageEditorDirective } from '../../app/diretives/image-editor.directive';
import { InlineCkeditorDirective } from '../../app/diretives/inline-ckeditor.directive';
import { InlineImageEditorDirective } from '../../app/diretives/inline-image-editor.directive';
import { InstgramDirective } from '../../app/diretives/instgram/instgram.directive';
import { MapEditorDirective } from '../../app/diretives/map-editor.directive';
import { MapPreviewDirective } from '../../app/diretives/map-preview.directive';
import { ResizableDirective } from '../../app/diretives/resizeable/resizable.directive';
import { ResizeHandleDirective } from '../../app/diretives/resizeable/resize-handle.directive';
import { SystemColorDirective } from '../../app/diretives/system-color.directive';
import { SystemFontDirective } from '../../app/diretives/system-font.directive';
import { SystemStyleDirective } from '../../app/diretives/system-style.directive';
import { EleButtonComponent } from '../../app/elements/ele-button/ele-button.component';
import { EleCombineComponent } from '../../app/elements/ele-combine/ele-combine.component';
import { EleHrComponent } from '../../app/elements/ele-hr/ele-hr.component';
import { BasicImageComponent } from '../../app/elements/ele-image/basic-image/basic-image.component';
import { EleImageComponent } from '../../app/elements/ele-image/ele-image.component';
import { EleInputAreaComponent } from '../../app/elements/ele-input-area/ele-input-area.component';
import { EleMapComponent } from '../../app/elements/ele-map/ele-map.component';
import { EleTextComponent } from '../../app/elements/ele-text/ele-text.component';
import { ItemWrapperComponent } from '../../app/item-wrapper/item-wrapper.component';
import { HomeWrapperComponent } from '../../app/pages/home-wrapper/home-wrapper.component';
import { CustomFormComponent } from '../../app/pages/homepage/project/custom-form/custom-form.component';
import { ProjectDatasComponent } from '../../app/pages/homepage/project/project-datas/project-datas.component';
import { ProjectDetailComponent } from '../../app/pages/homepage/project/project-detail/project-detail.component';
import { ProjectComponent } from '../../app/pages/homepage/project/project.component';
import { ResourceComponent } from '../../app/pages/homepage/resource/resource.component';
import { CdnAssetsPipe } from '../../app/pipes/cdn-assets.pipe';
import { DateFormatterPipe } from '../../app/pipes/date-formatter.pipe';
import { FileTypePipe } from '../../app/pipes/file-type.pipe';
import { GridSortPipe } from '../../app/pipes/grid-sort.pipe';
import { KeywordPipe } from '../../app/pipes/keyword.pipe';
import { NumToAbcPipe } from '../../app/pipes/num-to-abc.pipe';
import { PrettyJsonPipe } from '../../app/pipes/pretty-json.pipe';
import { WordsLengthLimiterPipe } from '../../app/pipes/words-length-limiter.pipe';
import { SectionWrapperComponent } from '../../app/section-wrapper/section-wrapper.component';
import { YSectionWrapperComponent } from '../../app/sections/y-section-wrapper/y-section-wrapper.component';
import { TextComponent } from '../../app/text/text.component';
import { MixinModule } from '../mixin/mixin.module';
import { CkeditorModule } from '../ckeditor/ckeditor.module';
import { AddLoadingComponent } from '../../app/components/loading/add-loading/add-loading.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MixinModule,
    OverlayModule,
    CkeditorModule,
    AtModule,
    DragDropModule,
    RouterModule,
    QRCodeModule
  ],
  declarations: [MapEditorComponent, MapEditorDirective, TextComponent,
    FileButtonComponent,
    EmptyBoxComponent,

    MalihuScrollbarDirective,
    SystemColorDirective,
    SystemFontDirective,
    CustomColorDirective,

    SystemStyleDirective,
    YSectionWrapperComponent,
    SectionWrapperComponent,
    ItemEmptyAdderComponent,
    ImageComponent,
    InstgramDirective,
    HandleCoverComponent,

    MapPreviewDirective,
    CkeditorDirective,
    InlineCkeditorDirective,
    BasicImageComponent,
    DragGridContainerDirective,
    DragGridItemComponent,
    DragMoveItemDirective,
    DragGridComponent,
    RightClickMenuComponent,
    ItemWrapperComponent,

    ResizeHandleComponent,
    ResizeHandleDirective,
    ResizableDirective,

    GridSortPipe,
    PrettyJsonPipe,

    EleHrComponent,
    EleButtonComponent,
    EleMapComponent,
    EleTextComponent,
    EleImageComponent,
    EleCombineComponent,
    EleInputAreaComponent,
    ProjectComponent,
    CustomFormComponent,
    EmptyDataComponent,
    ProjectDatasComponent,
    EchartsDirective,
    ResourceComponent,
    ProjectDetailComponent,
    InlineImageEditorDirective,
    ImageEditorDirective,
    AssetEditorDirective,

    HomeWrapperComponent,
    KeywordPipe,
    DateFormatterPipe,
    FileTypePipe,
    NumToAbcPipe,
    CookLoadingComponent,
    FormEditorComponent,
    TitleTestComponentsComponent,
    NavModalComponent,
    ButtonComponent,
    NavTitleDropdownComponent,
    MenuTitleDropdownComponent,
    NavMobileComponent,
    NavSelectedAnimationComponent,
    WordsLengthLimiterPipe,
    ImageModalComponent,
    AddLoadingComponent
  ],
  exports: [MapEditorComponent, MapEditorDirective, TextComponent, MapPreviewDirective,
    FileButtonComponent,
    GridSortPipe,
    EmptyBoxComponent,

    SystemColorDirective,
    SystemFontDirective,
    CustomColorDirective,
    CdnAssetsPipe,
    SystemStyleDirective,
    YSectionWrapperComponent,
    SectionWrapperComponent,
    ItemEmptyAdderComponent,
    ImageComponent,
    InstgramDirective,
    HandleCoverComponent,

    CkeditorDirective,
    InlineCkeditorDirective,
    BasicImageComponent,
    PrettyJsonPipe,
    OverlayModule,
    AtModule,

    DragGridContainerDirective,
    DragGridItemComponent,
    DragGridComponent,
    DragMoveItemDirective,
    RightClickMenuComponent,

    ResizeHandleComponent,
    ResizeHandleDirective,
    ResizableDirective,

    ItemWrapperComponent,

    EleHrComponent,
    EleButtonComponent,
    EleMapComponent,
    EleTextComponent,
    EleImageComponent,
    EleCombineComponent,
    EleInputAreaComponent,
    ProjectComponent,
    CustomFormComponent,
    EmptyDataComponent,
    ProjectDatasComponent,
    EchartsDirective,
    ResourceComponent,
    MalihuScrollbarDirective,
    ProjectDetailComponent,
    InlineImageEditorDirective,
    ImageEditorDirective,
    AssetEditorDirective,
    HomeWrapperComponent,
    DateFormatterPipe,
    FileTypePipe,
    KeywordPipe,
    NumToAbcPipe,
    CookLoadingComponent,
    FormEditorComponent,
    NavModalComponent,
    ButtonComponent,
    NavTitleDropdownComponent,
    MenuTitleDropdownComponent,
    NavMobileComponent,
    NavSelectedAnimationComponent,
    WordsLengthLimiterPipe,
    ImageModalComponent,
    AddLoadingComponent
  ]
})
export class ShareElementModule {
}
