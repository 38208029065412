import { ChintI18nInterface } from '../chint-i18n.interface';
// 法语
export const fr_FR: ChintI18nInterface = {
  locale: 'fr_FR',

  // 首页
  login: 'Se connecter',
  search: 'Rechercher',
  download_center: 'Centre de téléchargement',
  contact_us: 'Contactez-nous',
  learn_more: 'En savoir plus',
  subscribe: 'Abonnement',
  all_categories: 'Toutes les catégories',
  membership: 'Accès',
  log_out: 'Déconnexion',
  home: 'Accueil',
  privacy: 'intimité',
  // 解决方案
  solutions: 'Solutions',

  back_to_home: 'Retour à la page d\'accueil',


  // 产品列表
  product_search_placeholder: 'Inserire il nome del prodotto o del modello',

  // 产品详情
  return_list: 'Liste de retour',
  overview: 'Présentation',
  catalog: 'Catalog',
  manual: 'Manual',
  certificate: 'Certificate',
  drawings: 'Drawings',
  technical_patameter: 'Paramètres Techniques',
  installation_manual: 'Manuel',
  video: 'vídeo',
  // 客服服务
  contacts: 'Contacts',
  contact_no: 'Numéro de contact',
  e_mail: 'Courrier électronique',
  service_center: ' Service Center',

  // 搜索中心
  all: 'Tout',
  news: 'Actualités',
  products: 'Produits',
  files: 'Fichiers',

  // 下载中心
  sample: 'échantillon',
  product_category: 'Catégorie de produit',
  product_sub_caregory: 'Sous - catégorie de produits',
  download_center_search_placeholder: 'Recherche de nom de produit',
  file_name: 'Nom de fichier',
  document_format: 'Format de document',
  update_time: 'Update',
  document_size: 'Taille du document',
  download: 'Télécharger',
  category: 'Catégorie',

  // 关于我们
  about_us: 'Nous Concernant',

  // 新闻中心
  read_more: 'Lire plus d \'informations',
  more_news: 'Autres',
  news_center: 'Centre de presse',

  // 加入我们
  recruitment: 'Recrutement',
  hunman_resource: 'Ressources Humaines',
  care_team: 'équipe médicale',
  city: 'Ville',
  type: 'Type',
  position: 'Fonctions',
  number: 'Quantité',
  download_template: 'Modèle de téléchargement',
  upload: 'Télécharger le curriculum vitae',
  join_us_warn: 'Veuillez télécharger le document ou le fichier PDF',
  details: 'Détails',
  position_search_placeholder: 'Localisation',

  // 会员详情
  logins: 'Nom de connexion',
  member_detail_warn: 'Veuillez attendre que l \'administrateur vérifie vos privilèges.',
  your_email: 'Votre e - mail',
  password: 'Mot de passe',
  confirm_password: 'Confirm password',
  save: 'Enregistrer',
  personal_info: 'Informations personnelles',
  select_your_industry: 'Choisissez votre métier.',
  select_your_country: 'Choisir votre pays',

  // 登录
  register_now: 'Pas de compte?Enregistrement immédiat',
  forgot_password: 'Oublier le mot de passe',
  password_length_error: 'La longueur du mot de passe doit être entre 6 et 20 caractères',
  password_error: 'Mot de passe incorrect',
  email_check: 'Votre e - mail n \'est pas vérifié',
  account_ban: 'Votre boîte aux lettres est en cours de révision',
  no_account: 'Aucun compte',

  // 注册
  existing_accounts: 'Comptes Existants',
  profile: 'Je suis un...',
  policy: 'Acceptation de la stratégie utilisateur',
  submit: 'Envoyer',
  link_expired: 'Le lien actuel est expiré, veuillez le redémarrer.',

  // 注册完发送邮箱验证信息
  send_email: '',
  send_register_email: 'Nous vous avons envoyé un e - mail contenant un lien vers la fin de l \'enregistrement.',
  resend: 'Retransmission',
  after: '',
  second: 'secondes plus tard',

  // 忘记密码
  register: 'Enregistrement',

  // 修改密码发送邮箱验证信息
  send_reset_email: 'Nous vous avons envoyé un courriel contenant un lien avec le mot de passe de remplacement.',

  // 重置密码
  new_password: 'Nouveau mot de passe',

  // 订阅表单
  name: 'Nom',
  company: 'Unité',
  email: 'Courrier électronique',
  industry: 'Industrie',
  region: 'Zone',

  // Self Service表单
  phone_number: 'Numéro de téléphone: (+ Code eara)',
  select_your_region: 'Choisissez votre région',
  question_type: 'Type de problème',
  desc_of_info: 'Note d \'information',
  verification_code: 'Code d \'authentification',
  submit_now: 'Présentation immédiate',

  // Contact US表单
  title: 'Titre',

  // 表单错误信息
  item_required: 'Article à remplir',
  email_wrong: 'Saisissez un courrier électronique commercial valable',
  fields_required: 'Les champs marqués * sont nécessaires',
  enter_correct_mail: 'S\'il vous plaît entrer la bonne adresse email',
  enter_password: 'Entrez le mot de passe',
  enter_graphic_code: 'Veuillez saisir le code d \'authentification graphique correct.',
  password_re_enter: 'Les deux entrées de mot de passe sont incohérentes, veuillez les saisir à nouveau.',
  agree_use_policy: 'Veuillez accepter la politique.',

  // 表单成功信息
  reset_succeed: 'Réinitialiser le mot de passe avec succès',
  code_send_succeed: 'Transmission réussie du Code d \'authentification',
  login_succeed: 'Connexion réussie',
  log_out_succeed: 'Tu l \'as annulé',
  sign_succeed: 'Enregistrement réussi',
  submit_succeed: 'Soumission réussie',
  upload_succeed: 'Téléchargement réussi',
  update_succeed: 'Mise à jour réussie',

  // 月份缩写
  Jan: 'Janv.',
  Feb: 'Fév.',
  Mar: 'Mar.',
  Apr: 'Avr.',
  May: 'Mai.',
  Jun: 'Juin.',
  Jul: 'Jul.',
  Aug: 'Août',
  Sept: 'Sept.',
  Oct: 'Oct.',
  Nov: 'Nov.',
  Dec: 'Déc.',

  // 月份
  January: 'Janvier',
  February: 'Février',
  March: 'Mars',
  April: 'Avril',
  May_full: 'Mai',
  June: 'Juin',
  July: 'Juillet',
  August: 'Août',
  September: 'Septembre',
  October: 'Octobre',
  November: 'Novembre',
  December: 'Décembre',

  accept: 'Accept',
  before_news: 'Previous',
  next_news: 'Next',

  reset_email_send: 'we have send a email to your account, please check the email to perform the next step'
};
