import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { zh_CN } from 'at-ng';
import { I18N_TOKEN } from '../../app/i18n/i18n-token';
import { I18nPipe } from '../../app/i18n/i18n.pipe';
import { DZ_I18N_SERVICE_PROVIDER } from '../../app/i18n/i18n.service';

@NgModule({
  declarations: [
    I18nPipe
  ],
  imports: [
    CommonModule
  ],
  providers: [
    {provide: I18N_TOKEN, useValue: zh_CN},
    DZ_I18N_SERVICE_PROVIDER
  ],
  exports: [I18nPipe]
})
export class I18nModule {
}
