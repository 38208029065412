/**
 * Created by cml on 2017/12/5.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
var ImageCategoryService = /** @class */ (function (_super) {
    __extends(ImageCategoryService, _super);
    function ImageCategoryService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // constructor(private http: HttpHelper) {
    //
    // }
    ImageCategoryService.prototype.ImageCategorys = function (search_params) {
        var _this = this;
        if (search_params === void 0) { search_params = {}; }
        return this.http.AUTH_HTTP_GET('atyun/image_categories.json?per=10000', search_params).pipe(map(function (data) { return _this.http.extractData(data).image_categories; }));
    };
    ImageCategoryService.prototype.update = function (id, update_params) {
        if (update_params === void 0) { update_params = {}; }
        update_params.image_category_id = id;
        return this.http.AUTH_HTTP_PUT('atyun/image_categories/' + id + '.json', update_params);
    };
    ImageCategoryService.prototype.create = function (create_params) {
        if (create_params === void 0) { create_params = {}; }
        return this.http.AUTH_HTTP_POST('atyun/image_categories.json', create_params);
    };
    return ImageCategoryService;
}(BaseService));
export { ImageCategoryService };
