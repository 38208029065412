export class GraphqlBuilder<T> {

  /**
   * query example
   * @type {string}
   */
// 'query image($search: JsonStringType!,$page: JsonStringType!)
// { image(search_params: $search,page: $page){ total_pages }}'

  /**
   * update example
   * @type {string}
   */
//   update_string = '
//   mutation contentUpdate($input: ContentUpdateInput!){
//   contentUpdate(input: $input){ items { id} }}
// '
//   variables= {"input" => {"content" => [
//
//     {"id" => 7727, "content_name" => "asd",
//   "template" => {"id" => 442, "name" => "就业面"},
// "child_contents" => [
//   {"content_name" => "xxxcasdx"},
// {"id" => 7842, "content_name" => "xx"}, {"id" => 7840, "content_name" => "xx"}]}]}}

  graphql_type = 'query';

  grapqql_name = '';

  resource_type = null;

  models = {};

  // register model ,such as content. template ..

  register(name, klass) {
    this.models[name] = klass;
  }

  build(resource_name, body, args = ['$search']) {
    return `${this.graphql_type} ${resource_name}($search: JsonStringType!)
              { ${resource_name}(search_params: $search){
              total_pages
              total_count
               items{
                ${body}
              }
              }}`;
  }

  output(T) {
    return this;
  }

  getProperties() {
    const obj = new this.resource_type();
    const properties = Object.getOwnPropertyNames(obj);
    // TODO check the type and build the output type
    const property_types = properties.map(p => {
      return typeof obj[p];
    });
    // return "p1 p2 p3"
    return properties.toString().split(',').join(' ');
  }

  constructor(klass: T) {
    this.resource_type = klass;
    this.getProperties();
  }

  query() {
    this.graphql_type = 'query';
    return this;
  }

  update() {
    this.graphql_type = 'mutation';
    return this;
  }
}
