/**
 * Created by atyun on 7/17/17.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
var ContentsService = /** @class */ (function (_super) {
    __extends(ContentsService, _super);
    function ContentsService() {
        // constructor(public http: HttpHelper) {
        //
        // }
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.page = 1;
        _this.per = 10000000;
        return _this;
    }
    ContentsService.prototype.contents = function (search_params) {
        var _this = this;
        if (search_params === void 0) { search_params = {}; }
        return this.http.AUTH_HTTP_GET('atyun/contents.json?', search_params).pipe(map(function (data) { return _this.http.extractData(data); }));
    };
    ContentsService.prototype.contents_with_page = function (search_params) {
        if (search_params === void 0) { search_params = {}; }
        return this.http.AUTH_HTTP_GET('atyun/contents.json', search_params);
    };
    ContentsService.prototype.create_section = function (params) {
        return this.http.AUTH_HTTP_UPLOAD_POST('atyun/contents/create_section.json', params);
    };
    ContentsService.prototype.combine = function (params) {
        return this.http.AUTH_HTTP_UPLOAD_POST('atyun/contents/combine.json', params);
    };
    ContentsService.prototype.update_section = function (params) {
        return this.http.AUTH_HTTP_UPLOAD_PUT('atyun/contents/update_section.json', params);
    };
    ContentsService.prototype.get_sections = function (search_params) {
        var _this = this;
        if (search_params === void 0) { search_params = {}; }
        return this.http.AUTH_HTTP_GET('atyun/contents/sections.json', search_params).pipe(map(function (data) { return _this.http.extractData(data).contents; }));
    };
    ContentsService.prototype.get_system_sections = function (search_params) {
        var _this = this;
        if (search_params === void 0) { search_params = {}; }
        return this.http.AUTH_HTTP_GET('atyun/contents/get_system_sections.json', search_params).pipe(map(function (data) { return _this.http.extractData(data).contents; }));
    };
    ContentsService.prototype.operate_get_sections = function (search_params) {
        if (search_params === void 0) { search_params = {}; }
        return this.http.AUTH_HTTP_GET('atyun/manager/contents/sections.json', search_params);
    };
    ContentsService.prototype.update = function (id, update_params) {
        if (update_params === void 0) { update_params = {}; }
        update_params.content_id = id;
        return this.http.AUTH_HTTP_UPLOAD_PUT('atyun/contents/' + id + '.json', update_params);
    };
    ContentsService.prototype.operate_update = function (id, update_params) {
        if (update_params === void 0) { update_params = {}; }
        update_params.content_id = id;
        return this.http.AUTH_HTTP_UPLOAD_PUT('atyun/manager/contents/' + id + '.json', update_params);
    };
    ContentsService.prototype.create = function (create_params) {
        if (create_params === void 0) { create_params = {}; }
        return this.http.AUTH_HTTP_UPLOAD_POST('atyun/contents.json', create_params);
    };
    ContentsService.prototype.delete_by_id = function (id) {
        var params = { content_id: id };
        return this.http.AUTH_HTTP_DELETE('atyun/contents/' + id + '.json', params);
    };
    ContentsService.prototype.menus = function (category) {
        var params = { category: category };
        return this.http.AUTH_HTTP_GET('atyun/contents/child_categories.json', params);
    };
    ContentsService.prototype.add_to_template = function (params) {
        return this.http.AUTH_HTTP_POST('atyun/contents/add_to_template.json', params);
    };
    ContentsService.prototype.query = function (params) {
        return this.http.AUTH_HTTP_POST('/atyun/graphql', params);
    };
    ContentsService.prototype.upload_query = function (params) {
        return this.http.AUTH_HTTP_UPLOAD_POST('/atyun/graphql', params);
    };
    ContentsService.prototype.copy = function (params) {
        if (params === void 0) { params = {}; }
        return this.http.AUTH_HTTP_UPLOAD_POST('atyun/manager/contents/copy.json', params);
    };
    return ContentsService;
}(BaseService));
export { ContentsService };
