var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { map } from 'rxjs/operators';
import { GraphqlBuilderService } from '../graphql_builder.service';
var BasicGraphqlService = /** @class */ (function () {
    function BasicGraphqlService(graphql_service) {
        this.graphql_service = graphql_service;
    }
    BasicGraphqlService.prototype.register = function () {
        this.graphql_service.register(this.KlassName, this.Klass);
    };
    BasicGraphqlService.prototype.query = function (search_params, args, search, pagenation) {
        var _this = this;
        if (search_params === void 0) { search_params = {}; }
        if (search === void 0) { search = 'search'; }
        if (pagenation === void 0) { pagenation = false; }
        var body = this.graphql_service.getProperties(this.KlassName);
        var query = this.graphql_service.build(this.KlassName, search == 'search' ? body : 'id', search, pagenation);
        var params = {
            query: query,
            variables: __assign({ search: search_params }, args)
        };
        return this.graphql_service.Query(params).pipe(map(function (data) {
            var resource = _this.graphql_service.toCamel(_this.KlassName);
            if ((data[resource].status || {}).code == '40100') {
                _this.graphql_service.AtMessageService.warning({
                    message: '登录信息过期，请重新登录'
                });
                window.localStorage.clear();
                _this.graphql_service.router.navigate(['/login']);
            }
            return data;
        }));
    };
    BasicGraphqlService.prototype.query_update = function (group) {
        var _this = this;
        var params = {};
        var body = this.graphql_service.getProperties(this.KlassName);
        var query = this.graphql_service.build(this.KlassName, body, 'update');
        params = {
            query: query,
            variables: {
                input: {}
            }
        };
        params.variables.input[this.KlassName.toLowerCase()] = __assign({}, group);
        return this.graphql_service.uploadQuery(params).pipe(map(function (data) { return data[_this.KlassName + 'Update'] || {}; }));
    };
    return BasicGraphqlService;
}());
export { BasicGraphqlService };
