/**
 * Created by cml on 2018/1/19.
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelper } from '../../../helper/http.helper';

@Injectable()
export class MessageMailService {

    constructor(private http: HttpHelper) {

    }

    public create(create_params = {}): Observable<any> {
        return this.http.AUTH_HTTP_POST('atyun/projects/message_mail.json', create_params);
    }

}
