import { ChintI18nInterface } from '../chint-i18n.interface';
// 葡萄牙语
export const pt_PT: ChintI18nInterface = {
  locale: 'pt_PT',

  // 首页
  login: 'Iniciar sessão',
  search: 'Pesquisar',
  download_center: 'Centro de Downloads',
  contact_us: 'Contato',
  learn_more: 'Saiba Mais',
  subscribe: 'Subscrever',
  all_categories: 'Todas as categorias',
  membership: 'Membros',
  log_out: 'Terminar sessão',
  home: 'Pessoal',
  privacy: 'privacidade',
  back_to_home: 'Voltar para a página inicial',
  // 解决方案
  solutions: 'Soluções',

  // 产品列表
  product_search_placeholder: 'Insira o Nome do Produto ou modelo',

  // 产品详情
  return_list: 'Lista de resultados',
  overview: 'Visão Geral',
  catalog: 'Catálogo',
  manual: 'Manual',
  certificate: 'Certificado',
  drawings: 'Desenhos',
  technical_patameter: 'Parâmetro técnico',
  installation_manual: 'Manual de instalação',
  video: 'vídeo',
  // 客服服务
  contacts: 'Contactos',
  contact_no: 'Número de contacto.',
  e_mail: 'E-mail',
  service_center: 'Centro de serviço',

  // 搜索中心
  all: 'Todos',
  news: 'Notícias',
  products: 'Produtos',
  files: 'Arquivos',

  // 下载中心
  sample: 'Amostra',
  product_category: 'Categoria de produtos',
  product_sub_caregory: 'Subcategoria do Produto',
  download_center_search_placeholder: 'Pesquisar por Palavras -chave, Nome do Produto',
  file_name: 'Nome do arquivo',
  document_format: 'Formato do Documento',
  update_time: 'Última Atualização',
  document_size: 'Tamanho do Documento',
  download: 'Baixar',
  category: 'Categoria',

  // 关于我们
  about_us: 'Sobre Nós',

  // 新闻中心
  read_more: 'Ler Mais',
  more_news: 'More',
  news_center: 'Centro de Notícias',

  // 加入我们
  recruitment: 'Recrutamento',
  hunman_resource: 'Recursos Humanos',
  care_team: 'Equipa médica.',
  city: 'Cidade',
  type: 'Tipo',
  position: 'Posição',
  number: 'Número',
  download_template: 'Baixar Modelo',
  upload: 'Upload',
  join_us_warn: 'Por favor, envie os arquivos em formato doc ou PDF',
  details: 'Detalhes',
  position_search_placeholder: 'Procure Na posição',

  // 会员详情
  logins: 'Logins',
  member_detail_warn: 'Por favor, aguarde o Administrador para rever suas permissões de membro.',
  your_email: 'Seu e -mail',
  password: 'Senha',
  confirm_password: 'Confirmar senha',
  save: 'Salvar',
  personal_info: 'Informação pessoal',
  select_your_industry: 'Escolha a sua indústria',
  select_your_country: 'Escolha o seu país',

  // 登录
  register_now: 'Sem conta? Registre-se Agora',
  forgot_password: 'Esqueci a Senha',
  password_length_error: 'A senha deve ser entre 6 e 20 caracteres de longitud.',
  password_error: 'Palavra-passe incorreta',
  email_check: 'O SEU e -mail não FOI verificado',
  account_ban: 'Sua caixa de correio está em revisão',
  no_account: 'Essa conta não existe',

  // 注册
  existing_accounts: 'Contas existentes',
  profile: 'Eu sou um...',
  policy: 'Aceitar a Política do Utilizador',
  submit: 'Enviar',
  link_expired: 'O link atual expirou, por favor, re -inicie a aposta.',

  // 注册完发送邮箱验证信息
  send_email: '',
  send_register_email: 'Enviamos um e -mail com um link para completar o seu registo.',
  resend: 'Reenviar',
  after: '',
  second: 'segundos',

  // 忘记密码
  register: 'Registo',

  // 修改密码发送邮箱验证信息
  send_reset_email: 'Enviamos um e -mail com um link para redefinir sua senha.',

  // 重置密码
  new_password: 'Nova senha',

  // 订阅表单
  name: 'Nome',
  company: 'Empresa',
  email: 'Email',
  industry: 'Indústria',
  region: 'Região',

  // Self Service表单
  phone_number: 'Número de telefone:(+ código de area)',
  select_your_region: 'Escolha a sua região',
  question_type: 'Tipo de pergunta',
  desc_of_info: 'Descrição das informações relevantes',
  verification_code: 'Código de verificação',
  submit_now: 'Enviar agora',

  // Contact US表单
  title: 'Cargo',

  // 表单错误信息
  item_required: 'Este item é necessário.',
  email_wrong: 'Insira um email corporativo válido',
  fields_required: 'Os campos marcados com * são obrigatórios',
  enter_correct_mail: 'Por favor, insira o endereço de email correto',
  enter_password: 'Insira a senha',
  enter_graphic_code: 'Insira o código de validação gráfica correto',
  password_re_enter: 'As senhas são inconsistentes, por favor, insira novamente.',
  agree_use_policy: 'Por favor, Concorde com a política de utilização EM primeiro Lugar.',

  // 表单成功信息
  reset_succeed: 'A senha foi redefinida com sucesso',
  code_send_succeed: 'Código de verificação enviado com sucesso',
  login_succeed: 'Login bem sucedido',
  log_out_succeed: 'Você já TEM log out',
  sign_succeed: 'Cadastro bem sucedido',
  submit_succeed: 'Enviado com sucesso',
  upload_succeed: 'Envio com SUCESSO',
  update_succeed: 'Actualizar com SUCESSO',

  // 月份缩写
  Jan: 'Jan.',
  Feb: 'Fev.',
  Mar: 'Mar.',
  Apr: 'Abr.',
  May: 'Mai.',
  Jun: 'Jun.',
  Jul: 'Jul.',
  Aug: 'Ago.',
  Sept: 'Set.',
  Oct: 'Out.',
  Nov: 'Nov.',
  Dec: 'Dez.',

  // 月份
  January: 'Janeiro',
  February: 'Fevereiro',
  March: 'Março',
  April: 'Abril',
  May_full: 'Maio ',
  June: 'Junho',
  July: 'Julho',
  August: 'Agosto',
  September: 'Setembro',
  October: 'Outubro',
  November: 'Novembro',
  December: 'Dezembro',

  accept: 'Accept',
  before_news: 'Previous',
  next_news: 'Next',

  reset_email_send: 'we have send a email to your account, please check the email to perform the next step'
};
