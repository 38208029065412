import { ChintI18nInterface } from '../chint-i18n.interface';
// 西班牙语
export const es_ES: ChintI18nInterface = {
  locale: 'es_ES',

  // 首页
  login: 'Iniciar sesión',
  search: 'Buscar',
  download_center: 'Centro de descargas',
  contact_us: 'Contáctanos',
  learn_more: 'Información Adicional',
  subscribe: 'Suscribirse',
  all_categories: 'Todas las categorías',
  membership: 'Membresía',
  log_out: 'Cerrar sesión',
  home: 'Inicio',
  privacy: 'intimidad',
  
  back_to_home: 'Volver a la página principal',

  // 解决方案
  solutions: 'Soluciones',

  // 产品列表
  product_search_placeholder: 'Ingrese el nombre del producto o modelo',

  // 产品详情
  return_list: 'Volver a Menú Inicial',
  overview: 'Visión general',
  catalog: 'Catálogo',
  manual: 'Manual',
  certificate: 'Certificado',
  drawings: 'Dibujos',
  technical_patameter: 'Parámetros técnicos',
  installation_manual: 'Manual de instalación',
  video: 'Video',
  // 客服服务
  contacts: 'Contacto',
  contact_no: 'Teléfono de contacto',
  e_mail: 'Email',
  service_center: 'Centro de servicio',

  // 搜索中心
  all: 'Todo',
  news: 'Noticias',
  products: 'Productos',
  files: 'Archivos',

  // 下载中心
  sample: 'Muestra',
  product_category: 'Categoría de productos',
  product_sub_caregory: 'Subcategorías de productos',
  download_center_search_placeholder: 'Buscar por palabra clave o nombre del producto',
  file_name: 'Nombre del archivo',
  document_format: 'Formato del documento',
  update_time: 'Hora de Actualización',
  document_size: 'Tamaño del documento',
  download: 'Descargar',
  category: 'Categoría',

  // 关于我们
  about_us: 'Quiénes Somos',

  // 新闻中心
  read_more: 'Leer más información',
  more_news: 'Más',
  news_center: 'Centros de información',

  // 加入我们
  recruitment: 'Trabaje con Nosotros',
  hunman_resource: 'Recursos Humanos',
  care_team: 'Equipo médico',
  city: 'Ciudad',
  type: 'Tipo',
  position: 'Cargo',
  number: 'Cantidad',
  download_template: 'Descargar formato',
  upload: 'Cargar',
  join_us_warn: 'Por favor, cargue el archivo en formatoWORD o PDF',
  details: 'Detalles',
  position_search_placeholder: 'Buscar ubicación',

  // 会员详情
  logins: 'Nombre de registro',
  member_detail_warn: 'Porfavor esperar a que el administrador realice la evaluacion de registro',
  your_email: 'Su email',
  password: 'Contraseña',
  confirm_password: 'Confirmar contraseña',
  save: 'Guardar',
  personal_info: 'Información personal',
  select_your_industry: 'Elija su industria',
  select_your_country: 'Seleccione su país',

  // 登录
  register_now: '¿No tiene cuenta? Regístrese Ahora',
  forgot_password: '¿Olvidé su contraseña?',
  password_length_error: 'La longitud de la contraseña debe ser entre 6 y 20 letras.',
  password_error: 'Contraseña incorrecta',
  email_check: 'Su correo electrónico no ha sido revisado',
  account_ban: 'Su buzón está bajo revisión',
  no_account: 'No hay tal cuenta',

  // 注册
  existing_accounts: 'Cuenta existente',
  profile: 'Soy un...',
  policy: 'Acepta la política de uso y privacidad',
  submit: 'Enviar',
  link_expired: 'El enlace actual ha caducado,por favor reinicia.',

  // 注册完发送邮箱验证信息
  send_email: '',
  send_register_email: 'Le hemos enviado un e-mail con un enlace para completar su registro.',
  resend: 'Enviar de nuevo',
  after: '',
  second: 'segundos',

  // 忘记密码
  register: 'Registro',

  // 修改密码发送邮箱验证信息
  send_reset_email: 'Le hemos enviado un e-mail con un enlace para reestablecer su contraseña.',

  // 重置密码
  new_password: 'Nueva contraseña',

  // 订阅表单
  name: 'Nombre',
  company: 'Empresa',
  email: 'Correo electrónico',
  industry: 'Industria',
  region: 'Región',

  // Self Service表单
  phone_number: 'Tel.:(+código de área)',
  select_your_region: 'Seleccione su región.',
  question_type: 'Tipo de pregunta',
  desc_of_info: 'Nota de Interés',
  verification_code: 'Código de verificación',
  submit_now: 'Enviar ahora',

  // Contact US表单
  title: 'Cargo',

  // 表单错误信息
  item_required: 'Dato Obligatorio',
  email_wrong: 'Introducir correo electrónico válido',
  fields_required: 'Los campos marcados con * son obligatorios',
  enter_correct_mail: 'Por favor ingrese la dirección correcta',
  enter_password: 'Introduzca la contraseña.',
  enter_graphic_code: 'Introduzca el código de validación gráfico correcto',
  password_re_enter: 'Las dos contraseñas introducidas no son iguales, por favor vuelva a escribirlas.',
  agree_use_policy: 'Por favor,acepte la política.',

  // 表单成功信息
  reset_succeed: 'La contraseña se reestableció correctamente.',
  code_send_succeed: 'Código de verificación enviado satisfactoriamente',
  login_succeed: 'Acceso concedido',
  log_out_succeed: 'Ya lo cancelaste.',
  sign_succeed: 'Inscrito exitosamente',
  submit_succeed: 'Enviado exitosamente',
  upload_succeed: 'Cargar conéxito',
  update_succeed: 'Actualizar conéxito',

  // 月份缩写
  Jan: 'ENE.',
  Feb: 'FEB.',
  Mar: 'MAR.',
  Apr: 'ABR.',
  May: 'MAY.',
  Jun: 'JUN.',
  Jul: 'JUL.',
  Aug: 'AGO.',
  Sept: 'SEP.',
  Oct: 'OCT.',
  Nov: 'NOV.',
  Dec: 'DIC.',

  // 月份
  January: 'ENERO',
  February: 'FEBRERO',
  March: 'MARZO',
  April: 'ABRIL',
  May_full: 'MAYO',
  June: 'JUNIO',
  July: 'JULIO',
  August: 'AGOSTO',
  September: 'SEPTIEMBRE',
  October: 'OCTUBRE',
  November: 'NOVIEMBRE',
  December: 'DICIEMBRE',

  accept: 'Accept',
  before_news: 'Previous',
  next_news: 'Next',

  reset_email_send: 'we have send a email to your account, please check the email to perform the next step'
};
