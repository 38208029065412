var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Plugin } from '../../../model/common/plugin';
import { GraphqlBuilder } from '../../grapql/graphql_builder';
import { GlobalService } from '../global.service';
import { ContentsService } from './contents.service';
var UserPluginService = /** @class */ (function () {
    function UserPluginService(content_service, global_service) {
        this.content_service = content_service;
        this.global_service = global_service;
    }
    /**
     * get all user plugins
     * @param {{}} search_params
     * @returns {Observable<any>}
     */
    UserPluginService.prototype.my_plugins = function (search_params) {
        if (search_params === void 0) { search_params = {}; }
        var query = new GraphqlBuilder(Plugin);
        var body = query.getProperties();
        var search = query.query().build('plugin', body);
        search_params['user_plugins.user_id'] = this.global_service.$current_user.id;
        var params = {
            query: search,
            variables: { search: __assign({}, search_params) }
        };
        return this.content_service.query(params);
    };
    UserPluginService.prototype.plugins = function (search_params) {
        if (search_params === void 0) { search_params = {}; }
        var query = new GraphqlBuilder(Plugin);
        var body = query.getProperties();
        var search = query.query().build('pluginFull', body);
        var params = {
            query: search,
            variables: { search: __assign({}, search_params) }
        };
        return this.content_service.query(params);
    };
    return UserPluginService;
}());
export { UserPluginService };
