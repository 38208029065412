import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelper } from '../../../helper/http.helper';

export interface Counter {
  id: string;
  count_type: string;
  user_id: number;
  platform: string;
  ip: string;
  resource_id: string;
  resource_type: string;
  url: string;
  status: string;
  project_id: number;
}

@Injectable()
export class CounterService {

  constructor(private http: HttpHelper) {
  }

  public counters(search_params = {}): Observable<Counter[]> {
    return this.http.AUTH_HTTP_GET('atyun/counters.json?per=10000', search_params).pipe(map(data => this.http.extractData(data).counters));
  }

  public counters_with_projects(search_params = {}): Observable<any[]> {
    return this.http.AUTH_HTTP_GET('atyun/counters/counter_with_projects.json', search_params);
  }

  public update(id: string, update_params: { project_id?: string, [x: string]: any } = {}) {
    update_params.project_id = id;
    return this.http.AUTH_HTTP_UPLOAD_PUT('atyun/counters/' + id + '.json', update_params);
  }

  public delete(id: string): Observable<any> {
    const params = {project_id: id};
    return this.http.AUTH_HTTP_DELETE('atyun/counters/' + id + '.json', params);
  }

  public create(create_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_POST('atyun/counters.json', create_params);
  }

  public project_count(create_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_POST('atyun/counters/project_count.json', create_params);
  }

  public charts(search_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('atyun/counters/charts.json', search_params);
  }
}
