/**
 * Created by icepoint1999 on 7/31/16.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { throwError as observableThrowError } from 'rxjs';
import { api_config } from '../constant/api.config';
import { DataHelper } from './data.helper';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { Router } from '@angular/router';
import { AtMessageService } from 'at-ng';
var _ = require('lodash');
// custom serialize encoder
var MyHttpUrlEncodingCodec = /** @class */ (function (_super) {
    __extends(MyHttpUrlEncodingCodec, _super);
    function MyHttpUrlEncodingCodec() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MyHttpUrlEncodingCodec.prototype.encodeKey = function (k) {
        return encodeURIComponent(k);
    };
    MyHttpUrlEncodingCodec.prototype.encodeValue = function (v) {
        return encodeURIComponent(this.serializeValue(v));
    };
    MyHttpUrlEncodingCodec.prototype.serializeValue = function (v) {
        if (_.isObject(v)) {
            return _.isDate(v) ? v.toISOString() : JSON.stringify(v);
        }
        if (v === null || _.isUndefined(v)) {
            return '';
        }
        return v;
    };
    return MyHttpUrlEncodingCodec;
}(HttpUrlEncodingCodec));
export { MyHttpUrlEncodingCodec };
var HttpHelper = /** @class */ (function () {
    function HttpHelper(http_client, router, message_service, platformId, dataHelper) {
        this.http_client = http_client;
        this.router = router;
        this.message_service = message_service;
        this.platformId = platformId;
        this.dataHelper = dataHelper;
        this.apiConfig = api_config;
    }
    /**
     * 获取response里的data 并且转换成JSON 并且进行status 处理
     * @param res
     * @param rails 调用接口是否抛出错误信息，默认false, 抛出传true
     * @returns {any}
     */
    HttpHelper.prototype.extractData = function (res, raise) {
        if (raise === void 0) { raise = false; }
        if (raise && res.status && (res.status || {}).code != '20000') {
            this.message_service.error({
                message: res.status.message
            });
        }
        return res;
    };
    Object.defineProperty(HttpHelper.prototype, "access_token", {
        get: function () {
            return isPlatformBrowser(this.platformId) ? (this._access_token || localStorage.getItem('access_token')) : '';
        },
        set: function (value) {
            this._access_token = value;
            isPlatformBrowser(this.platformId) ? localStorage.setItem('access_token', value) : '';
        },
        enumerable: true,
        configurable: true
    });
    // public current_user: string = localStorage.getItem('liuyangbao_user')
    /**
     * 报错处理 开发使用
     * @param error
     * @returns {ErrorObservable}
     */
    HttpHelper.prototype.handleError = function (error) {
        // In a real world app, we might use a remote logging infrastructure
        // We'd also dig deeper into the error to get a better message
        var errMsg = (error.message) ? error.message :
            error.status ? error.status + " - " + error.statusText : 'Server error';
        console.error(errMsg); // log to console instead
        return observableThrowError(errMsg);
    };
    /**
     * TODO 有待优化 rxjs
     * 封装自定义get
     */
    HttpHelper.prototype.AUTH_HTTP_GET = function (url, params, headers) {
        if (params === void 0) { params = {}; }
        if (headers === void 0) { headers = new HttpHeaders({ 'Content-Type': 'application/json' }); }
        var options = { headers: headers };
        return this.http_client.get(url, {
            params: new HttpParams({
                encoder: new MyHttpUrlEncodingCodec(),
                fromObject: params
            })
        });
    };
    HttpHelper.prototype.AUTH_HTTP_DELETE = function (url, params, headers) {
        if (params === void 0) { params = {}; }
        if (headers === void 0) { headers = new HttpHeaders({ 'Content-Type': 'application/json' }); }
        return this.http_client.delete(url, {
            params: new HttpParams({
                encoder: new MyHttpUrlEncodingCodec(),
                fromObject: params
            })
        });
    };
    HttpHelper.prototype.AUTH_HTTP_POST = function (url, body, headers) {
        if (headers === void 0) { headers = new HttpHeaders({ 'Content-Type': 'application/json' }); }
        var options = { headers: headers };
        return this.http_client.post(url, body, options);
    };
    HttpHelper.prototype.AUTH_HTTP_PUT = function (url, body, headers) {
        if (headers === void 0) { headers = new HttpHeaders({ 'Content-Type': 'application/json' }); }
        var options = { headers: headers };
        return this.http_client.put(url, body, options);
    };
    HttpHelper.prototype.AUTH_HTTP_UPLOAD_PUT = function (url, body, prefix, headers) {
        if (prefix === void 0) { prefix = 'update'; }
        if (headers === void 0) { headers = new HttpHeaders(); }
        var token = JSON.parse(isPlatformBrowser(this.platformId) ? localStorage.getItem('access_token') : this.access_token) || null;
        // headers.append('Content-Type', 'multipart/form-data');
        headers.set('Accept', 'application/json');
        headers.set('encrypt', 'multipart/form-data');
        var options = { headers: headers };
        var formData = this.objectToFormData(body, new FormData());
        return this.http_client.put(url, formData, options);
    };
    HttpHelper.prototype.AUTH_HTTP_UPLOAD_POST = function (url, body, prefix, headers) {
        if (prefix === void 0) { prefix = 'update'; }
        if (headers === void 0) { headers = new HttpHeaders(); }
        var token = JSON.parse(isPlatformBrowser(this.platformId) ? localStorage.getItem('access_token') : this.access_token) || null;
        // headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        var options = { headers: headers };
        var formData = this.objectToFormData(body, new FormData());
        return this.http_client.post(url, formData, options);
    };
    // 将对象或数组转换成formdata的格式
    HttpHelper.prototype.objectToFormData = function (obj, form, namespace) {
        if (namespace === void 0) { namespace = ''; }
        var fd = form || new FormData();
        var formKey;
        if (obj instanceof Array) {
            for (var _i = 0, obj_1 = obj; _i < obj_1.length; _i++) {
                var item = obj_1[_i];
                if (typeof item === 'object' && !(item instanceof File)) {
                    this.objectToFormData(item, fd, namespace + "[]");
                }
                else {
                    fd.append(namespace + "[]", item);
                }
            }
        }
        else {
            for (var property in obj) {
                if (obj.hasOwnProperty(property)) {
                    if (namespace) {
                        formKey = namespace + '[' + property + ']';
                    }
                    else {
                        formKey = property;
                    }
                    // if the property is an object, but not a File,
                    // use recursivity.
                    if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                        this.objectToFormData(obj[property], fd, formKey);
                    }
                    else {
                        // if it's a string or a File object
                        fd.append(formKey, obj[property]);
                    }
                }
            }
        }
        return fd;
    };
    return HttpHelper;
}());
export { HttpHelper };
