import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelper } from '../../../helper/http.helper';

@Injectable()
export class ProjectMenuService {

  constructor(private http: HttpHelper) {
  }

  // 传project_id就行
  public project_menus(search_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('atyun/project_menus.json?per=10000', search_params).pipe(map(data => this.http.extractData(data)));
  }

  public update(id: any, update_params: any = {}): Observable<any> {
    update_params.project_menu_id = id;
    return this.http.AUTH_HTTP_UPLOAD_PUT('atyun/project_menus/' + id + '.json', update_params);
  }

  public delete(id: string): Observable<any> {
    const params = {project_menu_id: id};
    return this.http.AUTH_HTTP_DELETE('atyun/project_menus/' + id + '.json', params);
  }

  public create(create_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_POST('atyun/project_menus.json', create_params);
  }

  public init_data(init_params = {}): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_POST('atyun/project_menus/init_project_menu.json', init_params);
  }

}
