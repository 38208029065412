import { MainInjectorService } from '../app/services/main-injector.service';
import { CounterType } from '../app/types/counter_type';
import { HttpHelper } from '../helper/http.helper';

type CountAnnotation = (target: any, key: PropertyKey, descriptor: PropertyDescriptor) => PropertyDescriptor;

/**
 * 统计注解
 * @param type
 * @param {{}} create_params
 * @returns {(target: any, key: any, descriptor: any) => any}
 */
export function count(type: CounterType, create_params: { [x: string]: any } = {}, replace: boolean = true): CountAnnotation {
  return function (target: any, key: PropertyKey, descriptor: PropertyDescriptor): PropertyDescriptor {
    const method = descriptor.value;
    descriptor.value = function (...args: any[]): void {
      const ret = method.apply(this, args);

      /**
       * 发送异步统计数据
       */
      const http = MainInjectorService.getInstance().getInjector().get(HttpHelper);

      /**
       * 动态替换函数中的值以及参数
       */

      if (replace) {
        args.forEach((arg, index) => {
          const object_key = Object.keys(create_params)[index];
          if (object_key) {
            create_params[object_key] = arg;
          }

        });
      }
      create_params.url = window.location.href;

      const params = {
        create: {count_type: type, ...create_params}
      };
      http.AUTH_HTTP_POST('/atyun/counters.json', params).subscribe(data => {

      });

      return (ret || []).map((data: any) => {

      });
    };

    return descriptor;
  };

}
