import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Content } from '../../../model/common/content';
import { GraphqlBuilderService } from '../graphql_builder.service';

@Injectable()
export class BasicGraphqlService {

  public Klass;

  public KlassName;

  constructor(public graphql_service: GraphqlBuilderService) {

  }

  register() {
    this.graphql_service.register(this.KlassName, this.Klass);
  }

  public query(search_params = {}, args?: any, search = 'search', pagenation = false) {
    const body = this.graphql_service.getProperties(this.KlassName);
    const query = this.graphql_service.build(this.KlassName, search == 'search' ? body : 'id', search, pagenation);
    const params = {
      query,
      variables: {
        search: search_params,
        ...args
      }
    };
    return this.graphql_service.Query(params).pipe(map(data => {
      const resource = this.graphql_service.toCamel(this.KlassName);
      if ((data[resource].status || {} ).code as any == '40100') {
        this.graphql_service.AtMessageService.warning({
          message: '登录信息过期，请重新登录'
        });
        window.localStorage.clear();
        this.graphql_service.router.navigate(['/login']);
      }
      return data;
    }));
  }

  public query_update(group) {
    let params: any = {};
    const body = this.graphql_service.getProperties(this.KlassName);
    const query = this.graphql_service.build(this.KlassName, body, 'update');
    params = {
      query,
      variables: {
        input: {}
      }
    };
    params.variables.input[this.KlassName.toLowerCase()] = {...group};
    return this.graphql_service.uploadQuery(params).pipe(map(data => data[this.KlassName + 'Update'] || {}));
  }
}
