var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { map } from 'rxjs/operators';
import { HttpHelper } from '../../../helper/http.helper';
import { Project } from '../../../model/common/project';
import { GraphqlBuilder } from '../../grapql/graphql_builder';
import { ContentsService } from './contents.service';
var ProjectService = /** @class */ (function () {
    function ProjectService(http, content_service) {
        this.http = http;
        this.content_service = content_service;
    }
    ProjectService.prototype.projects = function (search_params) {
        var _this = this;
        if (search_params === void 0) { search_params = {}; }
        return this.http.AUTH_HTTP_GET('atyun/projects.json?per=10000', search_params).pipe(map(function (data) { return _this.http.extractData(data).projects; }));
    };
    ProjectService.prototype.manage_projects = function (search_params) {
        if (search_params === void 0) { search_params = {}; }
        return this.http.AUTH_HTTP_GET('atyun/manager/projects.json', search_params);
    };
    // 运营端使用
    ProjectService.prototype.operate_get_projects = function (search_params) {
        if (search_params === void 0) { search_params = {}; }
        return this.http.AUTH_HTTP_GET('atyun/manager/projects.json', search_params);
    };
    ProjectService.prototype.current_version = function (search_params) {
        if (search_params === void 0) { search_params = {}; }
        return this.http.AUTH_HTTP_GET('atyun/projects/current_version.json?per=10000', search_params).pipe(map(function (data) { return data.project; }));
    };
    ProjectService.prototype.update = function (id, update_params) {
        if (update_params === void 0) { update_params = {}; }
        update_params.project_id = id;
        return this.http.AUTH_HTTP_UPLOAD_PUT('atyun/projects/' + id + '.json', update_params);
    };
    ProjectService.prototype.delete = function (id) {
        var params = { project_id: id };
        return this.http.AUTH_HTTP_DELETE('atyun/projects/' + id + '.json', params);
    };
    ProjectService.prototype.create = function (create_params) {
        if (create_params === void 0) { create_params = {}; }
        return this.http.AUTH_HTTP_UPLOAD_POST('atyun/projects.json', create_params);
    };
    ProjectService.prototype.copy = function (params) {
        if (params === void 0) { params = {}; }
        return this.http.AUTH_HTTP_UPLOAD_POST('atyun/projects/copy.json', params);
    };
    ProjectService.prototype.full_search_projects = function (search_params) {
        if (search_params === void 0) { search_params = {}; }
        var query = new GraphqlBuilder(Project);
        var body = query.getProperties();
        var search = query.query().build('projectFull', body);
        var params = {
            query: search,
            variables: { search: __assign({}, search_params) }
        };
        return this.content_service.query(params);
    };
    ProjectService.prototype.member_get_projects = function (search_params) {
        var _this = this;
        if (search_params === void 0) { search_params = {}; }
        return this.http.AUTH_HTTP_GET('atyun/projects/member_permission_project.json', search_params).pipe(map(function (data) { return _this.http.extractData(data); }));
    };
    ProjectService.prototype.copy_content = function (search_params) {
        var _this = this;
        if (search_params === void 0) { search_params = {}; }
        return this.http.AUTH_HTTP_GET('atyun/projects/copy_content.json', search_params).pipe(map(function (data) { return _this.http.extractData(data); }));
    };
    return ProjectService;
}());
export { ProjectService };
