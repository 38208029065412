/**
 * Created by cml on 2017/11/23.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Http } from '@angular/http';
import { map } from 'rxjs/operators';
import { HttpHelper } from '../../../helper/http.helper';
import { Image } from '../../../model/common/image';
import { GraphqlBuilder } from '../../grapql/graphql_builder';
import { BaseService } from './base.service';
import { ContentsService } from './contents.service';
var ImageService = /** @class */ (function (_super) {
    __extends(ImageService, _super);
    function ImageService(content_service, http, http_client) {
        var _this = _super.call(this, http) || this;
        _this.content_service = content_service;
        _this.http = http;
        _this.http_client = http_client;
        return _this;
    }
    ImageService.prototype.images = function (search_params) {
        var _this = this;
        if (search_params === void 0) { search_params = {}; }
        return this.http.AUTH_HTTP_GET('atyun/images.json?per=10000', search_params).pipe(map(function (data) { return _this.http.extractData(data).images; }));
    };
    ImageService.prototype.update = function (id, update_params) {
        if (update_params === void 0) { update_params = {}; }
        update_params.image_id = id;
        return this.http.AUTH_HTTP_PUT('atyun/images/' + id + '.json', update_params);
    };
    ImageService.prototype.create = function (create_params) {
        if (create_params === void 0) { create_params = {}; }
        return this.http.AUTH_HTTP_UPLOAD_POST('atyun/images.json', create_params);
    };
    ImageService.prototype.delete_by_id = function (id) {
        var params = { image_id: id };
        return this.http.AUTH_HTTP_DELETE('atyun/images/' + id + '.json', params);
    };
    ImageService.prototype.gql_images = function (search_params) {
        var _this = this;
        if (search_params === void 0) { search_params = {}; }
        return this.http.AUTH_HTTP_GET('atyun/images.json?per=10000', search_params).pipe(map(function (data) { return _this.http.extractData(data).images; }));
    };
    // 图片全文搜索
    ImageService.prototype.full_search_images = function (search_params) {
        if (search_params === void 0) { search_params = {}; }
        var query = new GraphqlBuilder(Image); // Image
        var body = query.getProperties();
        var search = query.query().build('imageFull', body);
        var params = {
            query: search,
            variables: { search: __assign({}, search_params) }
        };
        return this.content_service.query(params);
    };
    // 获取图片详细信息
    ImageService.prototype.get_image_detail_info = function (url) {
        return this.http_client.get(url).pipe(map(function (data) { return JSON.parse(data['_body']); }));
    };
    return ImageService;
}(BaseService));
export { ImageService };
