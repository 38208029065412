import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelper } from '../../../helper/http.helper';

@Injectable()
export  class BaseService {

  constructor(public http: HttpHelper) {

  }
  public query(params): Observable<any> {
    return this.http.AUTH_HTTP_POST('/atyun/graphql', params).pipe(map(data => this.http.extractData(data)));
  }
}
