import { Injectable } from '@angular/core';
import { AtMessageService } from 'at-ng';
import * as moment from 'moment';
import { DataHelper } from '../../helper/data.helper';
import { Content } from '../../model/common/content';
import { Template } from '../../model/common/template';
import { TemplatesService } from '../services/content/template.service';
import { ContentsService } from './content/contents.service';
import { ProjectConfigService } from './content/project-config.service';
import { GlobalService } from './global.service';
import { MainInjectorService } from './main-injector.service';

@Injectable()
export class AutoSaveService {

  constructor(public nz_message_service: AtMessageService,
              public content_service: ContentsService,
              public data_helper: DataHelper,
              public global_service: GlobalService,
              public template_service: TemplatesService,
              public project_config_service: ProjectConfigService) {
  }

  last_save_time = moment();

  save_interval = 15;

  save(content: Content, callback?) {
    if (this.global_service.edit_modal) {
      if (content.id == '' || content.id == undefined) {
        const create_params = {
          create: content
        };
        this.content_service.create_section(create_params).subscribe(data => {
          this.global_service.saveStatus.saving(false).text('保存成功！');
          content.id = data.content.id;
          callback && callback(data);
          this.global_service.set_editing_template();
        });
      } else {
        const update_params = {
          content_id: content.id,
          update: content
        };
        this.content_service.update_section(update_params).subscribe(data => {
          this.global_service.saveStatus.saving(false).text('保存成功！');
          // this.global_service.render_contents()
          callback && callback(data);
          this.global_service.set_editing_template();
        });
      }
    }
  }

  // 板块间顺序变换自动保存
  save_template(template: any) {
    if (this.global_service.edit_modal) {

      const params = {
        model: 'content',
        seqs: template.contents.map(content => {
          return {id: content.id, seq: content.seq};
        })
      };

      this.project_config_service.change_model_seq(params).subscribe();
    }
  }

  setHistory(content) {
    const contentHistory = MainInjectorService.getInstance().contentHistories;
    const node = contentHistory.append({...content});
    contentHistory.setCurrent(node);
  }

  update_model_seq(model_name, models) {
    if (this.global_service.edit_modal) {
      const params = {
        model: model_name,
        seqs: models.map(m => {
          return {id: m.id, seq: m.seq};
        })
      };
      this.project_config_service.change_model_seq(params).subscribe(data => {

      });
    }
  }
}
