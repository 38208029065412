var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AtMessageService } from 'at-ng';
import { from as observableFrom, throwError as observableThrowError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { api_config } from '../constant/api.config';
import { DataHelper } from '../helper/data.helper';
var _ = require('lodash');
function getScreenSize() {
    var width = window.screen.availWidth;
    var size = 'medium';
    if (width < 720) {
        size = 'small';
    }
    else if (width > 960) {
        size = 'large';
    }
    return size;
}
var TokenInterceptor = /** @class */ (function () {
    function TokenInterceptor(message_service, dataHelper, http, router, platformId) {
        this.message_service = message_service;
        this.dataHelper = dataHelper;
        this.http = http;
        this.router = router;
        this.platformId = platformId;
        this.skip_url = ['http://atyun-api-sit.oss-cn-shanghai.aliyuncs.com',
            'http://atyun-api-prod.oss-cn-shanghai.aliyuncs.com'];
        this.apiConfig = api_config;
    }
    Object.defineProperty(TokenInterceptor.prototype, "access_token", {
        get: function () {
            return isPlatformBrowser(this.platformId) ? (this._access_token || localStorage.getItem('access_token')) : '';
        },
        set: function (value) {
            this._access_token = value;
            isPlatformBrowser(this.platformId) ? localStorage.setItem('access_token', value) : '';
        },
        enumerable: true,
        configurable: true
    });
    TokenInterceptor.prototype.getToken = function () {
        var token_url = '/atyun/oauth/token.json';
        var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        var options = { headers: headers };
        var uid = this.apiConfig.apiUid;
        var secret = this.apiConfig.apiSecret;
        var body = JSON.stringify({ client_secret: secret, client_id: uid, grant_type: 'client_credentials' });
        return this.http.post(token_url, body, options);
    };
    TokenInterceptor.prototype.handleError = function (error) {
        // In a real world app, we might use a remote logging infrastructure
        // We'd also dig deeper into the error to get a better message
        var errMsg = (error.message) ? error.message :
            error.status ? error.status + " - " + error.statusText : 'Server error';
        console.error(errMsg); // log to console instead
        return observableThrowError(errMsg);
    };
    TokenInterceptor.prototype.isRequestAliOss = function (url) {
        var result = false;
        this.skip_url.forEach(function (skip) {
            if (url.startsWith(skip)) {
                result = true;
            }
        });
        return result;
    };
    TokenInterceptor.prototype.isOauthToken = function (url) {
        return url.indexOf('/atyun/oauth/token.json') !== -1 || url.indexOf('/product/api/oauth/token.json') !== -1 || url.indexOf('/sso/oauth/token.json') !== -1;
    };
    TokenInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        if (this.isRequestAliOss(req.url) || this.isOauthToken(req.url)) {
            return next.handle(req);
        }
        else if (req.url.indexOf('product/api') !== -1) {
            // 访问商品管理平台
            var token = JSON.parse(isPlatformBrowser(this.platformId) ? localStorage.getItem('product_access_token') : this.product_access_token) || null;
            if (token == null || token.var3 + token.var2 < new Date().getTime() / 1000 || this.product_access_token == '{}' || token == {}) {
                var observer_pro = observableFrom(this.getProductToken().toPromise()).pipe(switchMap(function (data) {
                    _this.product_access_token = JSON.stringify({
                        session_key: data.access_token,
                        var2: data.expires_in,
                        var3: data.created_at
                    });
                    return _this.handleProductTokenReq(next, req, _this.product_access_token);
                }));
                return observer_pro;
            }
            else {
                return this.handleProductTokenReq(next, req, this.product_access_token);
            }
            // 访问sso
        }
        else if (req.url.indexOf('sso/') !== -1) {
            var token = JSON.parse(isPlatformBrowser(this.platformId) ? localStorage.getItem('sso_access_token') : this.sso_access_token) || null;
            // need get token first
            if (token == null || token.var3 + token.var2 < new Date().getTime() / 1000 || this.sso_access_token == '{}' || token == {}) {
                var observer = observableFrom(this.getSsoToken().toPromise()).pipe(switchMap(function (data) {
                    _this.sso_access_token = JSON.stringify({
                        session_key: data.access_token,
                        var2: data.expires_in,
                        var3: data.created_at
                    });
                    return _this.handleProductTokenReq(next, req, _this.sso_access_token);
                }));
                return observer;
            }
            else {
                return this.handleProductTokenReq(next, req, this.sso_access_token);
            }
        }
        else {
            // check if token exist
            var token = JSON.parse(isPlatformBrowser(this.platformId) ? localStorage.getItem('access_token') : this.access_token) || null;
            // need get token first
            if (token == null || token.var3 + token.var2 < new Date().getTime() / 1000 || this.access_token == '{}' || token == {}) {
                var observer = observableFrom(this.getToken().toPromise()).pipe(switchMap(function (data) {
                    _this.access_token = JSON.stringify({
                        session_key: data.access_token,
                        var2: data.expires_in,
                        var3: data.created_at
                    });
                    return _this.handleTokenReq(next, req);
                }));
                return observer;
            }
            else {
                return this.handleTokenReq(next, req);
            }
        }
    };
    TokenInterceptor.prototype.handleTokenReq = function (next, req) {
        var screen_size = getScreenSize();
        var token = {
            access_token: JSON.parse(this.access_token).session_key,
            user_session_key: localStorage.getItem('user_session_key'),
            screen_size: screen_size
        };
        var update_params = {};
        // check if it is get
        if (req.method == 'GET') {
            update_params.setParams = __assign({}, token);
        }
        // check if it is form data
        if (req.body instanceof FormData) {
            var body = req.body;
            body.append('access_token', token.access_token);
            body.append('user_session_key', token.user_session_key);
            body.append('screen_size', token.screen_size);
            update_params.body = body;
        }
        else {
            update_params.body = __assign({}, req.body, token);
        }
        // its ok send it
        var secureReq = req.clone(__assign({}, update_params));
        return next.handle(secureReq);
    };
    Object.defineProperty(TokenInterceptor.prototype, "product_access_token", {
        get: function () {
            return isPlatformBrowser(this.platformId) ? (this._product_access_token || localStorage.getItem('product_access_token')) : '';
        },
        set: function (value) {
            this._product_access_token = value;
            isPlatformBrowser(this.platformId) ? localStorage.setItem('product_access_token', value) : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TokenInterceptor.prototype, "sso_access_token", {
        get: function () {
            return isPlatformBrowser(this.platformId) ? (this._sso_access_token || localStorage.getItem('sso_access_token')) : '';
        },
        set: function (value) {
            this._sso_access_token = value;
            isPlatformBrowser(this.platformId) ? localStorage.setItem('sso_access_token', value) : '';
        },
        enumerable: true,
        configurable: true
    });
    TokenInterceptor.prototype.getProductToken = function () {
        var token_url = '/product/api/oauth/token.json';
        var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        var options = { headers: headers };
        var uid = this.apiConfig.apiUid;
        var secret = this.apiConfig.apiSecret;
        var body = JSON.stringify({ client_secret: secret, client_id: uid, grant_type: 'client_credentials' });
        return this.http.post(token_url, body, options);
    };
    TokenInterceptor.prototype.getSsoToken = function () {
        var token_url = '/sso/oauth/token.json';
        var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        var options = { headers: headers };
        var uid = this.apiConfig.apiUid;
        var secret = this.apiConfig.apiSecret;
        var body = JSON.stringify({ client_secret: secret, client_id: uid, grant_type: 'client_credentials' });
        return this.http.post(token_url, body, options);
    };
    TokenInterceptor.prototype.handleProductTokenReq = function (next, req, access_token) {
        var screen_size = getScreenSize();
        var token = {
            access_token: JSON.parse(access_token).session_key,
            user_session_key: localStorage.getItem('product_user_session_key'),
            screen_size: screen_size
        };
        var update_params = {};
        // check if it is get
        if (req.method == 'GET') {
            update_params.setParams = __assign({}, token);
        }
        // check if it is form data
        if (req.body instanceof FormData) {
            var body = req.body;
            body.append('access_token', token.access_token);
            body.append('user_session_key', token.user_session_key);
            body.append('screen_size', token.screen_size);
            update_params.body = body;
        }
        else {
            update_params.body = __assign({}, req.body, token);
        }
        // its ok send it
        var secureReq = req.clone(__assign({}, update_params));
        return next.handle(secureReq);
    };
    return TokenInterceptor;
}());
export { TokenInterceptor };
