import { Content } from '../../model/common/content';

export class ContentNode {
  private _elem: Content;
  private _next;
  private _pre;
  private _handle;

  get handle() {
    return this._handle;
  }

  set handle(value) {
    this._handle = value;
  }

  get elem() {
    return this._elem;
  }

  set elem(value) {
    this._elem = value;
  }

  get next() {
    return this._next;
  }

  set next(value) {
    this._next = value;
  }

  get pre() {
    return this._pre;
  }

  set pre(value) {
    this._pre = value;
  }

  constructor(elem) {
    this._elem = elem;
    this._next = null;
    this._pre = null;
  }
}

export class LinkedList<T> {
  private head: ContentNode = null;
  private len = 0;
  private _current: ContentNode = null;

  private pre_contents;
  private current_contents;

  get current(): ContentNode {
    return this._current;
  }

  set current(value: ContentNode) {
    this._current = value;
  }

  public append(elem, handle = 'update') {
    // let pre_node = new ContentNode(pre_elem);
    // pre_node.handle = handle

    const node = new ContentNode({...elem});

    node.handle = handle;

    if (this.head === null) {
      this.head = node;
      this.current = node;
    } else {
      const pre_current = this.current;

      // if (pre_node.elem.id != this.current.elem.id) {
      //   console.log('1121212121')
      //   pre_current.next = pre_node;
      //   pre_node.pre = pre_current;
      //
      //   this.len++;
      //
      // }

      let current = this.current;
      while (current.next) {
        console.log('撤销后返回');
        current = current.next;
      }
      current.next = node;
      node.pre = current;
    }

    this.len++;

    return node;
  }

  public setCurrent(node) {
    this.pre_contents = [];
    this.ps(this.current.elem, 0, false);

    this._current = node;

    this.current_contents = [];
    this.ps(node.elem, 0, true, (data) => {
      if (this.current_contents.length > this.pre_contents.length) {
        // 如果进行撤销操作, 将减少的内容软删除，

      } else if (this.current_contents.length < this.pre_contents.length) {
        // 如果进行撤销操作, 将增加的内容恢复，
      } else {
        // 只是修改内容
      }
    });

  }

  ps(data, depth, current: boolean, callback?) {
    if (data.hasOwnProperty('child_contents') && data.child_contents.length) {
      this.ps(data.child_contents, depth + 1, current);
    }

    for (const item of data) {
      if (item.parent_id || '') {
        if (current) {
          this.current_contents.push(item);
        } else {
          this.pre_contents.push(item);
        }
      }

      if (item.hasOwnProperty('child_contents') && item.child_contents.length) {
        this.ps(item.child_contents, depth + 1, current);
      }
    }

    callback && callback();

  }

  public removeAt(pos) {
    if (pos > -1 && pos < this.len) {
      let current = this.head;
      let previous;
      let index = 0;

      if (pos === 0) {
        this.head = current.next;
      } else {
        while (index++ < pos) {
          previous = current;
          current = current.next;
        }
        previous.next = current.next;
        current.next.pre = previous;
      }
      this.len--;
      return current.elem;
    } else {
      return null;
    }
  }

  public insert(elem, pos) {
    if (pos > -1 && pos < this.len) {
      let current = this.head;
      let index = 0;
      let previous;
      const node = new ContentNode(elem);

      if (pos === 0) {
        node.next = current;
        this.head = node;
      } else {
        while (index++ < pos) {
          previous = current;
          current = current.next;
        }
        node.next = current;
        previous.next = node;
        node.pre = previous;
      }
      this.len++;
      return true;
    } else {
      return false;
    }
  }

  public toString() {
    let current = this.head;
    const str = '';
    while (current) {
      // str += current.elem; //output is undefinedundefinedundefined
      // str += JSON.stringify(current);
      // prints out {"next":{"next":{}}}{"next":{}}{}
      current = current.next;
    }
    return str;
  }
}
