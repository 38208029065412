import { ChintI18nInterface } from '../chint-i18n.interface';
// 阿拉伯语
export const ar_SA: ChintI18nInterface = {
  locale: 'ar_SA',

  // 首页

  login: 'تسجيل الدخول',
  search: 'بحث',
  download_center: 'مركز التنزيل',
  contact_us: 'اتصل بنا',
  learn_more: 'معرفة المزيد',
  subscribe: 'اشتراك',
  all_categories: 'جميع الفئات',
  membership: 'العضوية',
  log_out: 'تسجيل الخروج',
  home: 'المنزل',
  privacy: 'خصوصية',
  back_to_home: ' العودة إلى الصفحة الرئيسية ',
  // 解决方案
  solutions: 'من الحلول',

  // 产品列表
  product_search_placeholder: 'أدخل اسم المنتج أو النموذج',

  // 产品详情
  return_list: 'قائمة العودة',
  overview: 'نظرة عامة',
  catalog: 'Catalog',
  manual: 'Manual',
  certificate: 'Certificate',
  drawings: 'Drawings',
  technical_patameter: 'المعايير الفنية',
  installation_manual: 'يدوي',
  video: 'فيديو',
  // 客服服务
  contacts: 'جهات الاتصال',
  contact_no: 'هاتف الاتصال',
  e_mail: 'عنوان البريد الإلكتروني',
  service_center: 'مركز خدمة',

  // 搜索中心
  all: 'الكل',
  news: 'أخبار',
  products: 'برودكشن',
  files: 'ملفات',

  // 下载中心
  sample: 'عينة',
  product_category: 'فئة المنتج',
  product_sub_caregory: 'فئة المنتج',
  download_center_search_placeholder: 'البحث عن الكلمات الرئيسية ، اسم المنتج',
  file_name: 'اسم الملف',
  document_format: 'شكل وثيقة',
  update_time: 'وقت التحديث',
  document_size: 'حجم المستند',
  download: 'داون لود',
  category: 'الفئات',

  // 关于我们
  about_us: 'معلومات عنا',

  // 新闻中心
  read_more: 'قراءة المزيد من المعلومات',
  more_news: 'أخرى',
  news_center: 'مركز الصحافة',

  // 加入我们
  recruitment: 'التجنيد',
  hunman_resource: 'الموارد البشرية',
  care_team: 'فريق الرعاية الصحية',
  city: 'مدن',
  type: 'نوع',
  position: 'المناصب',
  number: 'كمية',
  download_template: 'تحميل قالب',
  upload: 'تحميل السيرة الذاتية',
  join_us_warn: 'يرجى تحميل الوثائق أو قوات الدفاع الشعبي الملفات',
  details: 'التفاصيل',
  position_search_placeholder: 'موقع البحث',

  // 会员详情
  logins: 'اسم الدخول',
  member_detail_warn: 'يرجى الانتظار بالنسبة للمسؤولين للتحقق من امتيازات عضويتك',
  your_email: 'البريد الإلكتروني الخاص بك',
  password: 'باسوورد',
  confirm_password: 'تأكيد كلمة المرور',
  save: 'حفظ',
  personal_info: 'معلومات شخصية',
  select_your_industry: 'اختيار الصناعة الخاصة بك',
  select_your_country: 'اختر البلد',

  // 登录
  register_now: 'لا حساب ؟تسجيل فوري',
  forgot_password: 'نسيت كلمة المرور',
  password_length_error: 'يجب ان يكون طول كلمة المرور ما بين 6-20 حرفا',
  password_error: 'كلمة مرور غير صحيحة',
  email_check: 'البريد الإلكتروني الخاص بك لم يتم التحقق',
  account_ban: 'صندوق البريد الخاص بك قيد المراجعة',
  no_account: 'لا يوجد مثل هذا الحساب',

  // 注册
  existing_accounts: 'الحساب الجاري',
  profile: 'أنا واحد منهم',
  policy: 'قبول سياسة المستخدم',
  submit: 'إرسال',
  link_expired: 'الرابط الحالي قد انتهت ، يرجى إعادة تشغيل',

  // 注册完发送邮箱验证信息
  send_email: '',
  send_register_email: 'لقد أرسلنا لك رسالة بريد إلكتروني تحتوي على روابط لإكمال التسجيل',
  resend: 'أعاد إرسال',
  after: 'بعد',
  second: 'ثوان',

  // 忘记密码
  register: 'للتسجيل',

  // 修改密码发送邮箱验证信息
  send_reset_email: 'لقد أرسلت لك رسالة بريد إلكتروني تحتوي على وصلات إلى إعادة تعيين كلمة المرور',

  // 重置密码
  new_password: 'كلمة السر الجديدة',

  // 订阅表单
  name: 'الاسم الاول',
  company: 'وحدة',
  email: 'الايميل',
  industry: 'صناعي',
  region: 'إقليميا',

  // Self Service表单
  phone_number: 'رقم هاتف إيرا كود',
  select_your_region: 'اختيار المنطقة الخاصة بك',
  question_type: 'أنواع الأسئلة',
  desc_of_info: 'وصف المعلومات ذات الصلة',
  verification_code: 'رمز التحقق',
  submit_now: 'يقدم فورا',

  // Contact US表单
  title: 'الألقاب المهنية',

  // 表单错误信息
  item_required: 'هذا البند هو البند المطلوب',
  email_wrong: 'أدخل البريد الإلكتروني الأعمال صحيحة',
  fields_required: 'المجالات مع علامات الألوان المطلوبة',
  enter_correct_mail: 'الرجاء إدخال عنوان البريد الإلكتروني الصحيح',
  enter_password: 'الرجاء إدخال كلمة السر',
  enter_graphic_code: 'الرجاء إدخال رمز التحقق من صحة الرسومات الصحيحة',
  password_re_enter: 'اثنين من كلمات السر غير متناسقة ، الرجاء إعادة إدخال',
  agree_use_policy: 'يرجى الموافقة على سياسة الاستخدام',

  // 表单成功信息
  reset_succeed: 'إعادة تعيين كلمة السر بنجاح',
  code_send_succeed: 'كود ارسال بنجاح',
  login_succeed: 'تسجيل الدخول بنجاح',
  log_out_succeed: 'لقد ألغيت',
  sign_succeed: 'سجل النجاح',
  submit_succeed: 'يقدم بنجاح',
  upload_succeed: 'تحميل بنجاح',
  update_succeed: 'تحديث بنجاح',

  // 月份缩写
  Jan: 'كانون الثاني',
  Feb: 'فبراير',
  Mar: 'مارس',
  Apr: 'أبريل',
  May: 'قد',
  Jun: 'يونيو',
  Jul: 'يوليو',
  Aug: 'أغسطس',
  Sept: 'سبتمبر.',
  Oct: 'شهر اكتوبر',
  Nov: 'شهر نوفمبر',
  Dec: 'ديسمبر',

  // 月份
  January: 'ديسمبر',
  February: 'نوفمبر',
  March: 'اكتوبر',
  April: 'سبتمبر',
  May_full: 'أغسطس',
  June: 'يوليو',
  July: 'يونيو',
  August: 'مايو',
  September: 'ابريل',
  October: 'مارس',
  November: 'فبراير',
  December: 'يناير',

  accept: 'Accept',
  before_news: 'Previous',
  next_news: 'Next',

  reset_email_send: 'we have send a email to your account, please check the email to perform the next step'
};
