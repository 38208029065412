import { Injector } from '@angular/core';
import { LinkedList } from '../object-version-manage/linked-list';
var MainInjectorService = /** @class */ (function () {
    function MainInjectorService() {
        this._plugins = [];
        this._contentHistories = new LinkedList();
        if (!MainInjectorService.isCreating) {
            throw new Error('只能存在一个实例');
        }
    }
    MainInjectorService.getInstance = function () {
        if (MainInjectorService.instance == null) {
            MainInjectorService.isCreating = true;
            MainInjectorService.instance = new MainInjectorService();
            MainInjectorService.isCreating = false;
        }
        return MainInjectorService.instance;
    };
    MainInjectorService.prototype.setInjector = function (injector) {
        this._injector = injector;
    };
    MainInjectorService.prototype.getInjector = function () {
        return this._injector;
    };
    Object.defineProperty(MainInjectorService.prototype, "plugins", {
        get: function () {
            return this._plugins;
        },
        set: function (value) {
            this._plugins = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MainInjectorService.prototype, "contentHistories", {
        get: function () {
            return this._contentHistories;
        },
        set: function (value) {
            this._contentHistories = value;
        },
        enumerable: true,
        configurable: true
    });
    MainInjectorService.isCreating = false;
    return MainInjectorService;
}());
export { MainInjectorService };
