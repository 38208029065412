import { Optional, Provider, SkipSelf } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { I18N_TOKEN } from './i18n-token';
import { zh_CN } from './languages/zh_CN';
import * as i0 from "@angular/core";
import * as i1 from "./i18n-token";
var I18nService = /** @class */ (function () {
    function I18nService(locale) {
        this._change = new BehaviorSubject(this._locale);
        this.setLocale(locale || zh_CN);
    }
    Object.defineProperty(I18nService.prototype, "localChange", {
        get: function () {
            return this._change.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(I18nService.prototype, "locale", {
        get: function () {
            return this._locale;
        },
        set: function (value) {
            this._locale = value;
        },
        enumerable: true,
        configurable: true
    });
    I18nService.prototype.setLocale = function (language) {
        this._locale = language;
        this._change.next(language);
    };
    I18nService.prototype.translate = function (path, data) {
        var content = this._getObjectPath(this._locale, path);
        if (typeof content === 'string') {
            if (data) {
                Object.keys(data).forEach(function (key) { return content = content.replace(new RegExp("%" + key + "%", 'g'), data[key]); });
            }
            return content;
        }
        return path;
    };
    I18nService.prototype._getObjectPath = function (obj, path) {
        var res = obj;
        var paths = path.split('.');
        var depth = paths.length;
        var index = 0;
        while (res && index < depth) {
            res = res[paths[index++]];
        }
        return index === depth ? res : null;
    };
    I18nService.ngInjectableDef = i0.defineInjectable({ factory: function I18nService_Factory() { return new I18nService(i0.inject(i1.I18N_TOKEN)); }, token: I18nService, providedIn: "root" });
    return I18nService;
}());
export { I18nService };
export function DZ_LOCALE_SERVICE_PROVIDER_FACTORY(exist, locale) {
    return exist || new I18nService(locale);
}
export var DZ_I18N_SERVICE_PROVIDER = {
    provide: I18nService,
    useFactory: DZ_LOCALE_SERVICE_PROVIDER_FACTORY,
    deps: [[new Optional(), new SkipSelf(), I18nService], I18N_TOKEN]
};
