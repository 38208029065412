import { Content } from './content';

export class ProjectConfig {

  show_top_nav: boolean;

  show_foot: boolean;

  baidu_key: string;

  google_analy: string;

  head: string;
  body: string;
  sitemap: string;
  robots: string;

  disable_search_engine: boolean;

  id: string;

  favicon: string;

  share_pic: string;

  document_center_status: boolean;

  document_center_page_id
  document_search_page_id
  document_detail_page_id

  product_detail_page_id
  product_center_page_id

  about_us_page_id
  join_us_page_id
  contact_us_page_id
  news_center_page_id
  news_detail_page_id
  service_center_page_id
  personal_service_page_id
  custom_service_page_id
  download_center_page_id
  membership_page_id
  login_page_id
  chint_homepage_id
  member_center_page_id

  es_search_status: boolean
  es_search_page_id

  button: Content;
  countries
  industries

  cities
  companies
  join_types
  regions
  question_types
  roles
  project_id
  point_types

  constructor() {
    this.google_analy = '';
    this.disable_search_engine = false;
    this.baidu_key = '';
    this.show_foot = true;
    this.show_top_nav = true;
    this.id = null;
    this.share_pic = '';
    this.favicon = '';
    this.document_center_status = false;
  }
}
