var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var ContentNode = /** @class */ (function () {
    function ContentNode(elem) {
        this._elem = elem;
        this._next = null;
        this._pre = null;
    }
    Object.defineProperty(ContentNode.prototype, "handle", {
        get: function () {
            return this._handle;
        },
        set: function (value) {
            this._handle = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContentNode.prototype, "elem", {
        get: function () {
            return this._elem;
        },
        set: function (value) {
            this._elem = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContentNode.prototype, "next", {
        get: function () {
            return this._next;
        },
        set: function (value) {
            this._next = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ContentNode.prototype, "pre", {
        get: function () {
            return this._pre;
        },
        set: function (value) {
            this._pre = value;
        },
        enumerable: true,
        configurable: true
    });
    return ContentNode;
}());
export { ContentNode };
var LinkedList = /** @class */ (function () {
    function LinkedList() {
        this.head = null;
        this.len = 0;
        this._current = null;
    }
    Object.defineProperty(LinkedList.prototype, "current", {
        get: function () {
            return this._current;
        },
        set: function (value) {
            this._current = value;
        },
        enumerable: true,
        configurable: true
    });
    LinkedList.prototype.append = function (elem, handle) {
        // let pre_node = new ContentNode(pre_elem);
        // pre_node.handle = handle
        if (handle === void 0) { handle = 'update'; }
        var node = new ContentNode(__assign({}, elem));
        node.handle = handle;
        if (this.head === null) {
            this.head = node;
            this.current = node;
        }
        else {
            var pre_current = this.current;
            // if (pre_node.elem.id != this.current.elem.id) {
            //   console.log('1121212121')
            //   pre_current.next = pre_node;
            //   pre_node.pre = pre_current;
            //
            //   this.len++;
            //
            // }
            var current = this.current;
            while (current.next) {
                console.log('撤销后返回');
                current = current.next;
            }
            current.next = node;
            node.pre = current;
        }
        this.len++;
        return node;
    };
    LinkedList.prototype.setCurrent = function (node) {
        var _this = this;
        this.pre_contents = [];
        this.ps(this.current.elem, 0, false);
        this._current = node;
        this.current_contents = [];
        this.ps(node.elem, 0, true, function (data) {
            if (_this.current_contents.length > _this.pre_contents.length) {
                // 如果进行撤销操作, 将减少的内容软删除，
            }
            else if (_this.current_contents.length < _this.pre_contents.length) {
                // 如果进行撤销操作, 将增加的内容恢复，
            }
            else {
                // 只是修改内容
            }
        });
    };
    LinkedList.prototype.ps = function (data, depth, current, callback) {
        if (data.hasOwnProperty('child_contents') && data.child_contents.length) {
            this.ps(data.child_contents, depth + 1, current);
        }
        for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
            var item = data_1[_i];
            if (item.parent_id || '') {
                if (current) {
                    this.current_contents.push(item);
                }
                else {
                    this.pre_contents.push(item);
                }
            }
            if (item.hasOwnProperty('child_contents') && item.child_contents.length) {
                this.ps(item.child_contents, depth + 1, current);
            }
        }
        callback && callback();
    };
    LinkedList.prototype.removeAt = function (pos) {
        if (pos > -1 && pos < this.len) {
            var current = this.head;
            var previous = void 0;
            var index = 0;
            if (pos === 0) {
                this.head = current.next;
            }
            else {
                while (index++ < pos) {
                    previous = current;
                    current = current.next;
                }
                previous.next = current.next;
                current.next.pre = previous;
            }
            this.len--;
            return current.elem;
        }
        else {
            return null;
        }
    };
    LinkedList.prototype.insert = function (elem, pos) {
        if (pos > -1 && pos < this.len) {
            var current = this.head;
            var index = 0;
            var previous = void 0;
            var node = new ContentNode(elem);
            if (pos === 0) {
                node.next = current;
                this.head = node;
            }
            else {
                while (index++ < pos) {
                    previous = current;
                    current = current.next;
                }
                node.next = current;
                previous.next = node;
                node.pre = previous;
            }
            this.len++;
            return true;
        }
        else {
            return false;
        }
    };
    LinkedList.prototype.toString = function () {
        var current = this.head;
        var str = '';
        while (current) {
            // str += current.elem; //output is undefinedundefinedundefined
            // str += JSON.stringify(current);
            // prints out {"next":{"next":{}}}{"next":{}}{}
            current = current.next;
        }
        return str;
    };
    return LinkedList;
}());
export { LinkedList };
