import { ChintI18nInterface } from '../chint-i18n.interface';

export const zh_CN: ChintI18nInterface = {
  locale: 'zh_CN',

  // 首页
  login: '登录',
  search: '搜索',
  download_center: '下载中心',
  contact_us: '联系我们',
  learn_more: '了解更多',
  subscribe: '订阅',
  all_categories: '所有类别',
  membership: '会员中心',
  log_out: '退出',
  home: '首页',
  privacy: '隐私协议',
  back_to_home: '返回首页',

  // 解决方案
  solutions: '解决方案',

  // 产品列表
  product_search_placeholder: '输入产品或型号的名称',

  // 产品详情
  return_list: '返回列表',
  overview: '概述',
  catalog: 'Catalog',
  manual: 'Manual',
  certificate: 'Certificate',
  drawings: 'Drawings',
  technical_patameter: '技术参数',
  installation_manual: '安装手册',
  video: '视频',
  // 客服服务
  contacts: '联系人',
  contact_no: '联系电话',
  e_mail: '电子邮箱',
  service_center: '服务中心',

  // 搜索中心
  all: '所有',
  news: '新闻',
  products: '产品',
  files: '文件',

  // 下载中心
  sample: '样本',
  product_category: '产品类别',
  product_sub_caregory: '产品子类别',
  download_center_search_placeholder: '按关键字、产品名称搜索',
  file_name: '文件名',
  document_format: '文档格式',
  update_time: '更新时间',
  document_size: '文档大小',
  download: '下载',
  category: '类别',

  // 关于我们
  about_us: '关于我们',

  // 新闻中心
  read_more: '了解更多',
  more_news: '更多',
  news_center: '新闻中心',

  // 加入我们
  recruitment: '招聘',
  hunman_resource: '人力资源',
  care_team: '医护团队',
  city: '城市',
  type: '类型',
  position: '职务',
  number: '数量',
  download_template: '下载模板',
  upload: '上传简历',
  join_us_warn: '请上传文档或PDF格式的文件',
  details: '详情',
  position_search_placeholder: '请输入您要搜索的职位',

  // 会员详情
  logins: '登录信息',
  member_detail_warn: '请等待管理员检查您的会员特权。',
  your_email: '您的电子邮件',
  password: '密码',
  confirm_password: '确认密码',
  save: '保存',
  personal_info: '个人信息',
  select_your_industry: '选择你的行业',
  select_your_country: '选择您的国家',

  // 登录
  register_now: '没有账户？立即注册',
  forgot_password: '忘记密码',
  password_length_error: '密码必须在6-20位之间',
  password_error: '密码错误',
  email_check: '您的邮箱正在审核中',
  account_ban: '您的账号暂时被禁用了，如果有任何问题请联系我们',
  no_account: '没有这个账号',

  // 注册
  existing_accounts: '已有账户',
  profile: '我是一个。。。',
  policy: '使用政策',
  submit: '提交',
  link_expired: '当前链接已过期，请重新打开一个新链接',

  // 注册完发送邮箱验证信息
  send_email: '我们已向您的邮箱',
  send_register_email: '发送了一封电子邮件，其中包含完成注册的链接。',
  resend: '重新发送',
  after: '',
  second: '秒后',

  // 忘记密码
  register: '注册',

  // 修改密码发送邮箱验证信息
  send_reset_email: '发送了一封电子邮件，其中包含链接用于重置您的密码。',

  // 重置密码
  new_password: '新密码',

  // 订阅表单
  name: '姓名',
  company: '公司',
  email: '电子邮箱',
  industry: '行业',
  region: '区域',

  // Self Service表单
  phone_number: '电话号码：（+86）',
  select_your_region: '选择您所在的地区',
  question_type: '问题类型',
  desc_of_info: '相关信息说明',
  verification_code: '验证码',
  submit_now: '立即提交',

  // Contact US表单
  title: '职称',

  // 表单错误信息
  item_required: '该项为必填项',
  email_wrong: '请输入有效的商务电子邮箱',
  fields_required: '带*标记的字段是必填项',
  enter_correct_mail: '请输入正确的邮箱地址',
  enter_password: '请输入密码',
  enter_graphic_code: '请输入正确的图形验证码',
  password_re_enter: '两个密码输入不一致，请重新输入。',
  agree_use_policy: '请先同意使用政策',

  // 表单成功信息
  reset_succeed: '重置密码成功',
  code_send_succeed: '验证码发送成功',
  login_succeed: '登录成功',
  log_out_succeed: '退出成功',
  sign_succeed: '注册成功',
  submit_succeed: '提交成功',
  upload_succeed: '上传成功',
  update_succeed: '更新成功',

  // 月份缩写
  Jan: '一',
  Feb: '二',
  Mar: '三',
  Apr: '四',
  May: '五',
  Jun: '六',
  Jul: '七',
  Aug: '八',
  Sept: '九',
  Oct: '十',
  Nov: '十一',
  Dec: '十二',

  // 月份
  January: '一月',
  February: '二月',
  March: '三月',
  April: '四月',
  May_full: '五月',
  June: '六月',
  July: '七月',
  August: '八月',
  September: '九月',
  October: '十月',
  November: '十一月',
  December: '十二月',

  accept: 'Accept',
  before_news: 'Previous',
  next_news: 'Next',

  reset_email_send: 'we have send a email to your account, please check the email to perform the next step'
};
