import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AtMessageService } from 'at-ng';
import { Observable } from 'rxjs';
import { HttpHelper } from '../../helper/http.helper';

@Injectable()
export class GraphqlBuilderService {
  /**
   * query example
   * @type {string}
   */
// 'query image($search: JsonStringType!,$page: JsonStringType!)
// { image(search_params: $search,page: $page){ total_pages }}'

  /**
   * update example
   * @type {string}
   */
//   update_string = '
//   mutation contentUpdate($input: ContentUpdateInput!){
//   contentUpdate(input: $input){ items { id} }}
// '
//   variables= {"input" => {"content" => [
//
//     {"id" => 7727, "content_name" => "asd",
//   "template" => {"id" => 442, "name" => "就业面"},
// "child_contents" => [
//   {"content_name" => "xxxcasdx"},
// {"id" => 7842, "content_name" => "xx"}, {"id" => 7840, "content_name" => "xx"}]}]}}

  /**
   * input example
   * @type {string}
   */
    // camel_case ==> camelcase ;!important

  graphql_type = 'query';

  grapqql_name = '';

  resource_type = null;

  models = {};

  MAX_NEST_LEVEL = 4;

  UNEXCEPTED_TYPE = ['String', 'Number', 'Boolean'];

  UNEXPECT_COLUMN = ['is_add'];

  JSON_COLUMN = ['freedom_grid_config'];

  constructor(public http: HttpHelper, public router: Router, public AtMessageService: AtMessageService) {

  }

  // register model ,such as content. template ..
  register(name, klass) {
    this.resource_type = klass;
    this.models[name] = klass;
    return this;
  }

  build(resource_name, body, args = 'search', pagenation = false) {
    if (args == 'search') {
      if (pagenation) {
        return `query ${this.toCamel(resource_name)}($search: JsonStringType!, $page: JsonStringType, $per: JsonStringType)
              {${this.toCamel(resource_name)}(search_params: $search, page:$page, per:$per){
              total_pages
              total_count
              status {
               code
               message
              }
               items{
                ${body}
              }
              }}`;
      } else {
        return `query ${this.toCamel(resource_name)}($search: JsonStringType!)
              {${this.toCamel(resource_name)}(search_params: $search){
              total_pages
              total_count
              status
              {
               code
               message
              }
               items{
                ${body}
              }
              }}`;
      }
    } else {
      return `mutation ${this.toCamel(resource_name)}Update($input: ${this.toCamel(this.capitalize(resource_name))}UpdateInput!)
      {${this.toCamel(resource_name)}Update(input: $input){items { ${body}} }}`;
    }
  }

  output(T) {
    return this;
  }

  getProperties(model_name: string) {

    const body = '';

    const obj = new this.models[model_name]();

    return this.buildBody(obj, body);
  }

  buildBody(obj, bodys, nest_level = 1, un_expect = []) {
    let body = bodys || '';
    let level = nest_level;
    const properties = Object.getOwnPropertyNames(obj);
    // check the type and build the output type
    const property_types = properties.map(p => {
      return {p, creator: (obj[p] || {}).constructor, name: (obj[p] || {}).constructor.name};
    });
    property_types.forEach(data => {
        // delete the prop you dont want
        const model_prop = obj.constructor.name + '.' + data.p;
        if (this.UNEXPECT_COLUMN.indexOf(data.p) == -1 && un_expect.indexOf(model_prop) == -1) {
          if (this.UNEXCEPTED_TYPE.indexOf(data.name) != -1) {
            body += data.p + ' ';
          } else {
            if (level < this.MAX_NEST_LEVEL) {

              body += data.p + ' ';
              // check if it is the TYPE_ARRAY
              const column_value = obj[data.p];
              let array_property_type;

              // special type
              // TODO https://github.com/webpack/webpack/issues/1659
              if (data.name == 'TypeArray' || data.name == 't') {
                array_property_type = new column_value.GENERIC_MODEL();
              } else {
                array_property_type = new data.creator();
              }

              // set flag to check if nest over the MAX
              if (Object.keys(array_property_type).length > 0 && this.JSON_COLUMN.indexOf(data.p) == -1) {
                // if parent constructor's name same as child's , level + 1
                if (array_property_type.constructor.name == obj.constructor.name) {
                  level += 1;
                }
                body += ' { ';
                body = this.buildBody(array_property_type, body, level, un_expect);
                body += ' } ';
              }
            }
          }
        }
      }
    );
    return body;
  }

  query() {
    this.graphql_type = 'query';
    return this;
  }

  update() {
    this.graphql_type = 'mutation';
    return this;
  }

  capitalize = function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  toCamel = (value) => {
    return value.replace(/_\w/g, (m) => m[1].toUpperCase());
  }

  public Query(params): Observable<any> {
    return this.http.AUTH_HTTP_POST('/atyun/graphql', params);
  }

  public uploadQuery(params) {
    return this.http.AUTH_HTTP_UPLOAD_POST('/atyun/graphql', params);
  }

}
