/**
 * Created by atyun on 8/1/17.
 */

import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnyObject } from '../../../../app/types/custom_access_value';
import { HttpHelper } from '../../../../helper/http.helper';

@Injectable()
export class NewsLetterService {

  constructor(public http: HttpHelper) {

  }

  public news_letters(search_params: any = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('atyun/news_letters.json?', search_params).pipe(map(data => this.http.extractData(data)));
  }

  public update(id: any, update_params: AnyObject = {}): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_PUT('atyun/news_letters/' + id + '.json', update_params);
  }

  public delete(id: string): Observable<any> {
    const params = {id};
    return this.http.AUTH_HTTP_DELETE('atyun/news_letters/' + id + '.json', params);
  }

  public create(create_params: any = {}): Observable<any> {
    return this.http.AUTH_HTTP_UPLOAD_POST('atyun/news_letters.json', create_params);
  }

  public batch_delete(params): Observable<any> {
    return this.http.AUTH_HTTP_DELETE('atyun/news_letters/batch_delete.json', params);
  }
  public export_excel(search_params: any = {}): Observable<any> {
    return this.http.AUTH_HTTP_GET('atyun/news_letters/export_excel.json?', search_params);
  }
}
