/**
 * Created by atyun on 7/26/17.
 */
export class Project {

  id: string;

  name: string;

  user_id: string;

  status: string;

  mail: string;

  parent_id: string;

  cover: string;

  active_version_id: string;

  custom_domain: string;

  combo_type: string;

  project_type: string;

  language: string;

  child_projects: []

  shop_ids: []
  shop_id

  document_share_to
  document_shared_by
  news_share_to
  news_shared_by
  product_share_to
  product_shared_by

  news_share_ids
  document_share_ids
  shop_share_ids
  manager_id
  main_shop_id

  title_abbr
  keyword
  desc

  constructor() {
    this.name = '';
    this.mail = '';
    this.parent_id = '';
    this.cover = '';
    this.id = '';
    this.status = '';
    this.active_version_id = '';
  }
}
