export class Plugin {
  id: string;
  name: string;
  file: any;
  status: string;
  avatar: any;
  module_name: string;
  selector: string;
  created_at: string;

  constructor() {
    this.id = '';
    this.name = '';
    this.file = null;
    this.status = '';
    this.selector = '';
    this.avatar = null;
    this.created_at = '';
    this.module_name = '';
  }
}
