import { map } from 'rxjs/internal/operators';
import { HttpHelper } from '../../../helper/http.helper';
var MemberService = /** @class */ (function () {
    function MemberService(http) {
        this.http = http;
    }
    MemberService.prototype.members = function (search_params) {
        var _this = this;
        if (search_params === void 0) { search_params = {}; }
        return this.http.AUTH_HTTP_GET('atyun/members.json?', search_params).pipe(map(function (data) { return _this.http.extractData(data); }));
    };
    MemberService.prototype.update = function (id, update_params) {
        if (update_params === void 0) { update_params = {}; }
        return this.http.AUTH_HTTP_UPLOAD_PUT('atyun/members/' + id + '.json', update_params);
    };
    MemberService.prototype.delete = function (id) {
        var params = { member_id: id };
        return this.http.AUTH_HTTP_DELETE('atyun/members/' + id + '.json', params);
    };
    MemberService.prototype.create = function (create_params) {
        if (create_params === void 0) { create_params = {}; }
        return this.http.AUTH_HTTP_UPLOAD_POST('atyun/members.json', create_params);
    };
    MemberService.prototype.batch_delete = function (params) {
        return this.http.AUTH_HTTP_DELETE('atyun/members/batch_delete.json', params);
    };
    MemberService.prototype.login = function (params) {
        if (params === void 0) { params = {}; }
        return this.http.AUTH_HTTP_UPLOAD_POST('atyun/members/login.json', params);
    };
    MemberService.prototype.send_validate_code = function (phone) {
        var params = {
            phone: phone
        };
        return this.http.AUTH_HTTP_POST('atyun/members/send_phone_code.json', params);
    };
    MemberService.prototype.send_email_code = function (params) {
        return this.http.AUTH_HTTP_POST('atyun/members/send_email_code.json', params);
    };
    MemberService.prototype.reset_password_email = function (params) {
        return this.http.AUTH_HTTP_POST('atyun/members/reset_password_email.json', params);
    };
    Object.defineProperty(MemberService.prototype, "$current_member", {
        get: function () {
            var member = localStorage.getItem('member');
            member = JSON.parse(member);
            return member;
        },
        set: function (value) {
            localStorage.setItem('member', JSON.stringify(value));
        },
        enumerable: true,
        configurable: true
    });
    MemberService.prototype.getPassword = function (cms_login_config_id) {
        var password = localStorage.getItem('cms_login_config_password' + cms_login_config_id);
        return password;
    };
    MemberService.prototype.setPassword = function (cms_login_config_id, value) {
        localStorage.setItem('cms_login_config_password' + cms_login_config_id, value);
    };
    MemberService.prototype.check_member_code = function (params) {
        if (params === void 0) { params = {}; }
        return this.http.AUTH_HTTP_GET('atyun/members/check_member_code.json?', params);
    };
    MemberService.prototype.active_pass_email = function (params) {
        return this.http.AUTH_HTTP_POST('atyun/members/active_pass_email.json', params);
    };
    MemberService.prototype.member_login_ticket = function (params) {
        return this.http.AUTH_HTTP_GET('atyun/members/member_login_ticket.json', params);
    };
    MemberService.prototype.sign_out = function (id) {
        var params = { id: id };
        return this.http.AUTH_HTTP_GET('atyun/members/sign_out.json?', params);
    };
    MemberService.prototype.check_member_status = function (id) {
        var params = { id: id };
        return this.http.AUTH_HTTP_GET('atyun/members/check_member_status.json?', params);
    };
    return MemberService;
}());
export { MemberService };
