import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AtMessageService } from 'at-ng';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UserService } from '../app/services/content/users.service';
import { SsoService } from '../app/services/sso.service';
import { DataHelper } from '../helper/data.helper';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  skip_urls = ['/atyun/users/sign_in', '/atyun/users/reset_pwd_email'];

  constructor(private message_service: AtMessageService,
              private router: Router,
              private sso_service: SsoService,
              private user_service: UserService,
              private data_helper: DataHelper) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(filter((event: any) => (event instanceof HttpResponse)),
      map((event: any) => {
        if (this.skip_urls.find(url => event.url.indexOf(url) != -1)) {
          return event;
        } else {
          return event.clone({body: this.handleResponse(event)});
        }
      })
    );
  }

  handleResponse(event: HttpResponse<any>) {
    const body: any = event.body;
    let clone_body: any = body;
    if (body) {
      if (body.status && body.status.code === '40100') {
        localStorage.clear();
        this.data_helper.goToSsoLogin(window.location.href);
        clone_body = {};
      }
      if (body.status && body.status.code == '40200') {
        this.message_service.warning({
          message: body.status.message
        });
      }
      if (body.status && body.status.code != '20000') {
        clone_body = body;
      } else {
        clone_body = body.data || body || {code: body.status.code};
      }
    }
    return clone_body;
  }

  loginFailed(): void {
    localStorage.clear();
    this.message_service.warning({
      message: '登录信息已过期,请重新登录'
    });
    this.data_helper.goToSsoLogin(window.location.href);
  }

}
