import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { zh_CN as at_zh_CN } from 'at-ng';
import { ResponseInterceptor } from '../interceptor/response.interceptor';
import { TokenInterceptor } from '../interceptor/token.interceptor';
import { zh_CN as chint_zh_CN } from '../modules/domain/chint/i18n/languages/zh_CN';
import { zh_CN } from './i18n/languages/zh_CN';
export var httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true }
];
var other_config = [];
var ɵ0 = zh_CN, ɵ1 = chint_zh_CN, ɵ2 = at_zh_CN;
//
// const env = process.env.NODE_ENV || 'dev'
// if (env === 'web-production') {
//   Raven.config('https://06951d245b5d4d80b8dbe138cac924bf@sentry.doteetv.com.cn/31').install();
// } else if (env !== 'development') {
//   Raven.config('https://d362308b1e0441d9b20b316ddd080f5d@sentry.doteetv.com.cn/30').install();
// }
//
// export class RavenErrorHandler implements ErrorHandler {
//   handleError(err: any): void {
//     Raven.captureException(err);
//   }
// }
// {
//   provide: ErrorHandler,
//     useClass: RavenErrorHandler
// }
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2 };
