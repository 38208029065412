/**
 * Created by icepoint1999 on 7/31/16.
 */

import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Headers, Http, RequestOptions, Response } from '@angular/http';
import { from as observableFrom, throwError as observableThrowError } from 'rxjs';
import { Observable } from 'rxjs';
import { api_config } from '../constant/api.config';
import { environment } from '../environments/environment';
import { DataHelper } from './data.helper';

import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { Router } from '@angular/router';
import { AtMessageService } from 'at-ng';
import { AccessToken } from '../model/token';

const _ = require('lodash');

// custom serialize encoder
export class MyHttpUrlEncodingCodec extends HttpUrlEncodingCodec {

  encodeKey(k) {
    return encodeURIComponent(k);
  }

  encodeValue(v) {
    return encodeURIComponent(this.serializeValue(v));
  }

  serializeValue(v) {
    if (_.isObject(v)) {
      return _.isDate(v) ? v.toISOString() : JSON.stringify(v);
    }
    if (v === null || _.isUndefined(v)) {
      return '';
    }
    return v;
  }

}

@Injectable()
export class HttpHelper {

  apiConfig: any;

  constructor(public http_client: HttpClient,
              public router: Router,
              public message_service: AtMessageService,
              @Inject(PLATFORM_ID) private platformId: Object,
              public dataHelper: DataHelper) {
    this.apiConfig = api_config;
  }

  /**
   * 获取response里的data 并且转换成JSON 并且进行status 处理
   * @param res
   * @param rails 调用接口是否抛出错误信息，默认false, 抛出传true
   * @returns {any}
   */
  public extractData(res: any, raise = false) {
    if (raise && res.status && (res.status || {} as any).code != '20000') {
      this.message_service.error({
        message: res.status.message
      });
    }
    return res;
  }

  // 存储token
  private _access_token: string;

  get access_token(): string {
    return isPlatformBrowser(this.platformId) ? (this._access_token || localStorage.getItem('access_token')) : '';
  }

  set access_token(value: string) {
    this._access_token = value;
    isPlatformBrowser(this.platformId) ? localStorage.setItem('access_token', value) : '';
  }

// public current_user: string = localStorage.getItem('liuyangbao_user')

  /**
   * 报错处理 开发使用
   * @param error
   * @returns {ErrorObservable}
   */
  public handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return observableThrowError(errMsg);
  }

  /**
   * TODO 有待优化 rxjs
   * 封装自定义get
   */
  public AUTH_HTTP_GET(url: any, params = {}, headers = new HttpHeaders({'Content-Type': 'application/json'})): Observable<any> {
    const options = {headers};
    return this.http_client.get<any>(url, {
      params: new HttpParams({
        encoder: new MyHttpUrlEncodingCodec(),
        fromObject: params
      })
    });
  }

  public AUTH_HTTP_DELETE(url: any, params = {}, headers = new HttpHeaders({'Content-Type': 'application/json'})): Observable<any> {
    return this.http_client.delete<any>(url, {
      params: new HttpParams({
        encoder: new MyHttpUrlEncodingCodec(),
        fromObject: params
      })
    });
  }

  public AUTH_HTTP_POST(url: string, body: Object, headers = new HttpHeaders({'Content-Type': 'application/json'})): Observable<any> {
    const options = {headers};
    return this.http_client.post<any>(url, body, options);
  }

  public AUTH_HTTP_PUT(url: string, body: Object, headers = new HttpHeaders({'Content-Type': 'application/json'})) {
    const options = {headers};
    return this.http_client.put<any>(url, body, options);
  }

  public AUTH_HTTP_UPLOAD_PUT(url: string, body: Object, prefix = 'update', headers = new HttpHeaders()) {
    const token = JSON.parse(isPlatformBrowser(this.platformId) ? localStorage.getItem('access_token') : this.access_token) || null;
    // headers.append('Content-Type', 'multipart/form-data');
    headers.set('Accept', 'application/json');
    headers.set('encrypt', 'multipart/form-data');
    const options = {headers};
    const formData: FormData = this.objectToFormData(body, new FormData());
    return this.http_client.put<any>(url, formData, options);
  }

  public AUTH_HTTP_UPLOAD_POST(url: string, body: Object, prefix = 'update', headers = new HttpHeaders()) {
    const token = JSON.parse(isPlatformBrowser(this.platformId) ? localStorage.getItem('access_token') : this.access_token) || null;
    // headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const options = {headers};
    const formData: FormData = this.objectToFormData(body, new FormData());
    return this.http_client.post<any>(url, formData, options);
  }

  // 将对象或数组转换成formdata的格式
  objectToFormData(obj: any, form: FormData, namespace = '') {
    const fd = form || new FormData();
    let formKey;
    if (obj instanceof Array) {
      for (const item of obj) {
        if (typeof item === 'object' && !(item instanceof File)) {
          this.objectToFormData(item, fd, `${namespace}[]`);
        } else {
          fd.append(`${namespace}[]`, item);
        }
      }
    } else {
      for (const property in obj) {
        if (obj.hasOwnProperty(property)) {

          if (namespace) {
            formKey = namespace + '[' + property + ']';
          } else {
            formKey = property;
          }

          // if the property is an object, but not a File,
          // use recursivity.
          if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {

            this.objectToFormData(obj[property], fd, formKey);
          } else {

            // if it's a string or a File object
            fd.append(formKey, obj[property]);
          }

        }
      }
    }
    return fd;

  }

}
