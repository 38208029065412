export const environment = {
  production: true,
  cdn: '//sit-dz-cdn.chint.com',
  hmr: false,
  suffix: 'sit-dz.chint.com',
  sentry_config: '',
  host: 'http://sit-sso.chint.com',
  product_host: 'http://sit-pro.chint.com',
  project_id: 3
};
