import { Optional, Provider, SkipSelf } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Chint_I18N_TOKEN } from './chint-i18n-token';
import { zh_CN } from './languages/zh_CN';
import * as i0 from "@angular/core";
import * as i1 from "./chint-i18n-token";
var ChintI18nService = /** @class */ (function () {
    function ChintI18nService(locale) {
        this._change = new BehaviorSubject(this._locale);
        this.setLocale(locale || zh_CN);
    }
    Object.defineProperty(ChintI18nService.prototype, "localChange", {
        get: function () {
            return this._change.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChintI18nService.prototype, "locale", {
        get: function () {
            return this._locale;
        },
        set: function (value) {
            this._locale = value;
        },
        enumerable: true,
        configurable: true
    });
    ChintI18nService.prototype.setLocale = function (language) {
        this._locale = language;
        this._change.next(language);
    };
    ChintI18nService.prototype.translate = function (path, data) {
        var content = this._getObjectPath(this._locale, path);
        if (typeof content === 'string') {
            if (data) {
                Object.keys(data).forEach(function (key) { return content = content.replace(new RegExp("%" + key + "%", 'g'), data[key]); });
            }
            return content;
        }
        return path;
    };
    ChintI18nService.prototype._getObjectPath = function (obj, path) {
        var res = obj;
        var paths = path.split('.');
        var depth = paths.length;
        var index = 0;
        while (res && index < depth) {
            res = res[paths[index++]];
        }
        return index === depth ? res : null;
    };
    ChintI18nService.ngInjectableDef = i0.defineInjectable({ factory: function ChintI18nService_Factory() { return new ChintI18nService(i0.inject(i1.Chint_I18N_TOKEN)); }, token: ChintI18nService, providedIn: "root" });
    return ChintI18nService;
}());
export { ChintI18nService };
export function CHINT_LOCALE_SERVICE_PROVIDER_FACTORY(exist, locale) {
    return exist || new ChintI18nService(locale);
}
export var CHINT_I18N_SERVICE_PROVIDER = {
    provide: ChintI18nService,
    useFactory: CHINT_LOCALE_SERVICE_PROVIDER_FACTORY,
    deps: [[new Optional(), new SkipSelf(), ChintI18nService], Chint_I18N_TOKEN]
};
