import { ChintI18nInterface } from '../chint-i18n.interface';
// 意大利语
export const it_IT: ChintI18nInterface = {
  locale: 'it_IT',

  // 首页
  login: 'Accedi',
  search: 'Cerca',
  download_center: 'Centro download',
  contact_us: 'Contattaci',
  learn_more: 'Per saperne di più',
  subscribe: 'Iscriviti',
  all_categories: 'Tutte le categorie',
  membership: 'Iscrizione al Gruppo',
  log_out: 'Disconnetti',
  home: 'Home',
  privacy: 'Privacy',
  back_to_home: 'Torna alla home page',
  // 解决方案
  solutions: 'Soluzioni',

  // 产品列表
  product_search_placeholder: 'Inserire il nome del prodotto o del modello',

  // 产品详情
  return_list: 'Invio elenco',
  overview: 'Panoramica',
  catalog: 'Catalog',
  manual: 'Manual',
  certificate: 'Certificate',
  drawings: 'Drawings',
  technical_patameter: 'Parametri Tecnici',
  installation_manual: 'Manuale',
  video: 'Video',
  // 客服服务
  contacts: 'Contatti',
  contact_no: 'Numero di contatto.',
  e_mail: 'E-mail',
  service_center: 'centro di assistenza',

  // 搜索中心
  all: 'Tutti',
  news: 'Notizie',
  products: 'Prodotti',
  files: 'File',

  // 下载中心
  sample: 'Campione',
  product_category: 'Categoria di prodotto',
  product_sub_caregory: 'Sottocategoria del prodotto',
  download_center_search_placeholder: 'Ricerca per parola chiave, nome del prodotto',
  file_name: 'Nome file',
  document_format: 'Formato del documento',
  update_time: 'Aggiornamento orario',
  document_size: 'Dimensione del documento',
  download: 'Download',
  category: 'Categoria',

  // 关于我们
  about_us: 'Chi siamo',

  // 新闻中心
  read_more: 'Per saperne di più',
  more_news: 'Altri',
  news_center: 'Centro News',

  // 加入我们
  recruitment: 'Assunzione',
  hunman_resource: 'Risorse umane',
  care_team: 'Squadra medica',
  city: 'Città',
  type: 'Tipo',
  position: 'Post',
  number: 'Numero',
  download_template: 'Scarica modello',
  upload: 'CV di upload',
  join_us_warn: 'Si prega di caricare i file in formato doc o PDF',
  details: 'Dettagli',
  position_search_placeholder: 'Cerca la posizione',

  // 会员详情
  logins: 'Login',
  member_detail_warn: 'Attenda che l\'amministratore riveda i suoi privilegi di membro.',
  your_email: 'La tua e -mail',
  password: 'Password',
  confirm_password: 'Conferma password',
  save: 'Salva',
  personal_info: 'Informazioni personali',
  select_your_industry: 'Seleziona la tua industria',
  select_your_country: 'Seleziona il tuo paese',

  // 登录
  register_now: 'Nessun conto?Registrati ora',
  forgot_password: 'Password dimenticata',
  password_length_error: 'La password deve avere un numero di caratteri compreso tra 6 e 20.',
  password_error: 'Password errata',
  email_check: 'La tua email non è stata selezionata',
  account_ban: 'La tua casella di posta è in fase di revisione',
  no_account: 'Nessun account del genere',

  // 注册
  existing_accounts: 'Conti esistenti',
  profile: 'Io sono un...',
  policy: 'Accetta la politica utente',
  submit: 'Invia',
  link_expired: 'Il collegamento corrente è scaduto, per favore riavvia.',

  // 注册完发送邮箱验证信息
  send_email: '',
  send_register_email: 'Vi abbiamo inviato una email con un link per completare la vostra registrazione.',
  resend: 'Ripristina',
  after: '',
  second: 'secondi dopo',

  // 忘记密码
  register: 'Registro',

  // 修改密码发送邮箱验证信息
  send_reset_email: 'Ti abbiamo inviato una e -mail con un link per reimpostare la tua password.',

  // 重置密码
  new_password: 'Nuova password',

  // 订阅表单
  name: 'Nome',
  company: 'Società',
  email: 'Email',
  industry: 'Industria',
  region: 'Regione',

  // Self Service表单
  phone_number: 'Telefon Numarası: (+ alan kodu)',
  select_your_region: 'Seleziona la tua regione',
  question_type: 'Tipo di domande',
  desc_of_info: 'Descrizione delle informazioni pertinenti',
  verification_code: 'Codice di verifica',
  submit_now: 'Invia subito',

  // Contact US表单
  title: 'Titolo',

  // 表单错误信息
  item_required: 'Questa voce è richiesta',
  email_wrong: 'Enter a valid business email',
  fields_required: 'I campi contrassegnati con * sono obbligatori',
  enter_correct_mail: 'Inserire l\'indirizzo della casella postale corretto',
  enter_password: 'Inserisci la password',
  enter_graphic_code: 'Inserisci il codice di validazione grafico corretto.',
  password_re_enter: 'Due voci di password sono incoerenti, per favore inserisci di nuovo.',
  agree_use_policy: 'Si prega di accettare prima la politica di utilizzo.',

  // 表单成功信息
  reset_succeed: 'Riavvia password di successo',
  code_send_succeed: 'Codice di verifica inviato con successo',
  login_succeed: 'Accesso riuscito',
  log_out_succeed: 'Hai già eseguito il login',
  sign_succeed: 'login è stato efficace',
  submit_succeed: 'Inviare con successo',
  upload_succeed: 'Carica con successo',
  update_succeed: 'Aggiornamento riuscito',

  // 月份缩写
  Jan: 'Gen.',
  Feb: 'Feb.',
  Mar: 'Mar.',
  Apr: 'Apr.',
  May: 'Mag.',
  Jun: 'Giu.',
  Jul: 'Lug.',
  Aug: 'Ago.',
  Sept: 'Sett.',
  Oct: 'Ott.',
  Nov: 'Nov.',
  Dec: 'Dic.',

  // 月份
  January: 'Gennaio',
  February: 'Febbraio',
  March: 'Marzo',
  April: 'Aprile',
  May_full: 'Maggio',
  June: 'Giugno',
  July: 'Luglio',
  August: 'Agosto',
  September: 'Settembre',
  October: 'Ottobre',
  November: 'Novembre',
  December: 'Dicembre',

  accept: 'Accept',
  before_news: 'Previous',
  next_news: 'Next',

  reset_email_send: 'we have send a email to your account, please check the email to perform the next step'
};
