import { I18nInterface } from '../i18n.interface';

export const zh_CN: I18nInterface = {
  locale: 'zh_CN',
  common: {
    edit: '编辑',
    data: '数据',
    setting: '设置',
    my_sites: '网站',
    user_center: '个人中心',
    my_orders: '我的订单',
    my_form: '我的表单',
    my_package: '我的套餐',
    my_content: '新闻中心',
    my_member: '会员中心',
    my_document: '文档中心',
    log_out: '退出',
    order: '排序',
    days_ago: '天前',
    minutes_ago: '分钟前',
    hours_ago: '小时前',
    language: '语言',
    hello: '您好',
    is_saving: '正在保存...',
    save_succeed: '保存成功',
    product_center: '产品中心',
    product_label: '产品标签',
    product_category: '产品种类',
    product_management: '产品',
    brands: '产品品牌',
    user_management: '用户',
    shops: '我的站点',
    shop_management: '站点管理',
    promotion: '促销品',
    promotion_category: '促销品种类',
    website_name: '正泰电器海外网站群'
  },
  common_locale: {
    edit_document: '编辑文件夹',
    batch_upload: '批量上传',
    batch_edit: '批量编辑',
    cancel_top: '取消置顶',
    move_top: '置顶',
    document_type_code: 'Code',
    end_time: '失效日期',
    confirm_delete: '确认删除',
    confirm_edit: '确认修改',
    content_tag: '内容标签',
    total: '共',
    page: '页',
    edit: '编辑',
    show: '查看',
    delete: '删除',
    batch_delete: '删除',
    new_data: '新建',
    name: '名称',
    english_name: '英文名称',
    status: '状态',
    new_content_category: '新建标签',
    keywords: '关键字',
    current_records: '当前记录树',
    count: '条',
    desc: '描述',
    parent_node: '上级节点',
    thumbnail: '缩略图',
    operation: '操作',
    edit_category: '编辑标签',
    confirm: '确认',
    cancel: '取消',
    time: '时间',
    seq: '排序',
    seq_info: '*顺序越大，排序越靠前',
    input_info: '名称为必填项',
    index_page: '首页',
    search: '查询',
    last_page: '末页',
    preview: '预览',
    download: '下载',
    errors: {
      not_check: '你还没有选择标签'
    },
    create_success: '创建成功',
    update_success: '修改成功',
    delete_success: '删除成功',
    create_failed: '创建失败',
    update_failed: '修改失败',
    delete_failed: '删除失败',
    creating: '正在创建...',
    updating: '正在修改...',
    deleting: '正在删除...',
    update_time: '修改时间',
    create_time: '创建时间',
    updater: '修改人',
    creator: '创建人',
    active: '正常',
    all: '全部',
    check: '待审核',
    ban: '禁用',
    active_a: '有效',
    inactive: '无效',
    permission: '权限',
    edit_permission: '修改权限',
    pls_chose_item: '请选择您需要删除的数据',
    yes: '是',
    no: '否',
    password: '密码',
    password_required: '密码为必填项',
    password_confirm: '密码确认',
    input_password_again: '请再次输入密码',
    password_different: '两次输入密码不一致',
    pls_input_password: '请输入密码',
    gender: '性别',
    male: '男',
    female: '女',
    reset: '重置',
    export: '导出',
    confirm_export: '确认导出',
    export_left: '您此次一共要导出',
    export_right: '条数据，确认导出?',
    batch_export_select: '您尚未选择要导出的内容',
    email_config: '邮件设置',
    company_name_required: '公司名称为必填项',
    profile_required: '身份为必填项',
    document_config: '文档设置',
    news_config: '新闻设置',
    share: '分享',
    shared_by: '通过分享',
    config: '设置',
    password_length_error: '密码长度6-20位',
    export_all: '导出全部',
    export_all_str: '您此次需要导出全部数据，确认导出？'
  },
  pages: {
    projects: {
      new: '新建网站',
      site_info: '网站概况',
      site_count: '网站数量',
      online_site_count: '上线网站数量',
      paid_site_count: '已购付费网站数量',
      used_site_count: '已用付费网站数量',
      package_expired_in: '还剩',
      none_package: '当前没有任何套餐',
      days_left: '天到期',
      message: '留言',
      history_versions: '历史版本',
      latest_version: '最新版本',
      original_version: '最初版本',
      online: '已上线',
      offline: '未上线',
      site_online: '网站上线',
      site_offline: '网站下线',
      free: '免费版',
      paid: '付费版',
      recently_reading: '最近7天浏览',
      updated_at: '更新于',
      created_at: '创建时间',
      please: '请',
      renew: '立即续费',
      or: '或',
      upgrade_package: '升级套餐',
      recently_updated: '最近更新',
      recently_created: '最近创建',
      delete_site: '删除网站',
      use_package: '使用付费版',
      add_version: '添加到历史版本',
      add_soon: '赶紧新建体验吧',
      add_to_template_library: '添加到模板库',
      add_new_project: '创建新的网站',
      name_of_template: '模板名称',
      cover_of_template: '模板封面',
      name_of_project: '网站名称',
      modal_message: {
        confirm_delete: '确定要删除吗？删除之后网站数据都会被清空',
        confirm_online: '确认上线？',
        confirm_offline: '确认下线？',
        has_online: '您的网站已成功上线',
        has_offline: '您的网站已成功下线',
        succeed: '操作成功',
        adding: '正在为您后台添加版本，请耐心等待'
      },
      content_category: '新闻种类',
      content_tag: '新闻标签',
      news: '新闻',
      new_project: {
        choose_template: '请选择一个模板',
        template_type: {
          all: '全部',
          internet: '互联网',
          health_care: '健康医疗',
          talent_consultation: '人才咨询'
        },
        preview: '预览',
        use: '使用',
        create_now: '不用模板,即刻创建',
        no_template: '找不到合适的模板，请',
        contact_service: '联系客服',
        login_first: '请您先登录',
        my_site: '我的网站',
        site: '的网站'
      },
      project_data: {
        PV: 'PV统计',
        UV: 'UV统计',
        from: '从',
        to: '到',
        query: '查询',
        last_7_days: '最近7天',
        last_3_months: '最近3个月',
        last_1_year: '最近1年',
        chart_option: {
          number_of_clicks: '链接点击次数',
          total: '总量',
          IP_access_times: 'ip访问次数'
        }
      },
      custom_form: {
        created_at: '创建时间:',
        form_data: '表单数据',
        all_messages: '所有留言',
        time: '留言时间'
      }
    },
    user_center: {
      new_password_confirm: '新密码确认',
      change_profile: '更改个人设置',
      email: '邮箱地址',
      phone: '手机号码',
      current_password: '当前密码',
      new_password: '新密码',
      change_phone: '更改手机号码',
      change_email: '更改邮箱地址',
      update: '更新',
      placeholder: {
        email: '你的邮件地址',
        phone_no: '请输入11位手机号码',
        current_password: '你当前的密码',
        new_password: '请输入新密码',
        phone_validate_code: '短信验证码',
        get_validate_code: '获取验证码',
        new_password_confirm: '请再次输入新密码'
      },
      error: {
        blank_phone: '请输入手机号',
        true_email: '请输入正确的邮箱地址',
        true_phone: '请输入正确的手机号',
        blank_code: '验证码不能为空',
        error_code: '验证码错误',
        blank_password: '密码不能为空',
        error_password: '密码错误',
        none_update: '你没有要更新的信息',
        password_restriction: '密码长度6-20位',
        password_not_same: '两次输入的密码不一致'
      },
      orders: {
        no_data: '暂无数据',
        order_no: '订单编号',
        desc: '描述',
        status: '订单状态',
        order_fee: '订单金额',
        pay_fee: '支付金额',
        pay_type: '支付方式',
        pay_time: '支付时间',
        help_doc_prefix: '如需退款、发票，请点击',
        help_doc: '帮助文档',
        my_order: '我的订单'
      },
      packages: {
        package_type: '套餐类型',
        site_count: '网站数量',
        status: '套餐状态',
        expired_in: '有效期至',
        my_package: '我的套餐',
        help_doc: '帮助文档',
        help_doc_prefix: '如需退款、发票，请点击',
        renew: '续费',
        buy_more: '购买更多',
        no_packages: '暂无套餐',
        buy_packages: '购买套餐'
      },
      modify_info_succeed: '信息修改成功',
      code_error: '验证码错误',
      password_error: '密码错误',
      send_code_succeed: '验证码发送成功'
    },
    member: {
      dealer: '正常',
      update_confirm: '你确定要更新吗?',
      register_at: '注册于',
      member_label: '会员标签',
      member: '会员',
      keyword: '关键字',
      status: '状态',
      name: '姓名',
      mobile_phone: '手机号码',
      email: '邮箱',
      full_name: '姓名',
      nick_name: '昵称',
      company_name: '公司名称',
      add_member: '新增会员',
      batch_delete_member: '删除',
      confirm_delete_member: '确认删除',
      search: '查询',
      portrait: '头像',
      data_source: '来源',
      options: '操作',
      all: '全部',
      active: '待审核',
      check: '邮件验证中',
      ban: '禁用',
      sign: '注册',
      message: '留言',
      question: '问卷',
      system: '录入',
      pass: '审核通过',
      show: '查看',
      edit: '编辑',
      remove: '删除',
      new_label: '新建会员标签',
      update_time: '更新时间',
      edit_permission: '编辑权限',
      active_a: '有效',
      inactive: '无效',
      delete_left: '您此次一共要删除',
      delete_right: '条数据，确认删除?',
      delete_confirm: '确认删除该条数据吗？',
      description: '描述',
      create_time: '创建时间',
      updater: '更新人',
      creator: '创建人',
      seq: '顺序',
      permalink: '永久链接地址',
      picture: '缩略图',
      parent: '上级',
      create_label: '新建会员标签',
      edit_label: '编辑会员标签',
      phone_failed: '请输入正确的手机号码',
      email_failed: '请输入正确的邮箱地址',
      birthday: '生日',
      type_of_certificate: '证件类型',
      work_permit: '工作证',
      id_card: '身份证',
      passport: '护照',
      certificate_number: '证件号码',
      country: '国家',
      province: '省',
      city: '市',
      connect_phone: '联系电话',
      fax: '传真',
      connect_address: '联系地址',
      address_code: '邮政编码',
      state: '邦',
      profile: '身份',
      register_time: '注册时间'
    },
    news_information: {
      news_information: '新闻资讯',
      title: '主题',
      status: '状态',
      author: '作者',
      search: '查询',
      classification: '分类',
      label: '标签',
      time: '更新时间',
      create_button: '新建新闻资讯',
      batch_delete: '删除',
      today: '今天',
      yesterday: '昨天',
      seven_day: '最近七天',
      option: '操作',
      confirm_delete: '确认删除',
      all: '全部',
      draft: '草稿',
      published: '已发布',
      create_news_information: '创建新闻',
      edit_news_information: '编辑新闻',
      title_must_input: '主题为必填项',
      sub_title: '副标题',
      summary: '摘要',
      extract_from: '从正文中抽取',
      keyword: '关键字',
      parent_node: '上级内容',
      create_new_classification: '创建新闻分类',
      create_new_label: '创建新闻标签',
      cover: '封面图',
      content_text: '内容正文',
      data_source: '数据来源',
      data_source_link: '数据来源地址',
      seq: '顺序',
      is_top: '是否置顶',
      is_essence: '是否精华',
      read_times: '阅读数',
      collect_times: '收藏数',
      share_times: '分享数',
      permalink: '永久链接地址',
      blank: '无',
      delete_confirm: '确定删除该新闻吗？',
      updater: '更新者',
      release_time: '发布时间',
      prompt_str: '200个字符以内',
      file_big: '封面和内容推荐上传图片尺寸：1200*800px，图片格式：JPEG，图片大小：200KB以内'

    },
    document: {
      document_management: '文档',
      document_category: '文档种类',
      document_type: '文档类型',
      document_language: '文档语言',
      document_label: '文档标签',
      code: '编号',
      version: '版本',
      size: '大小',
      document_file: '文件列表',
      date: '日期',
      is_require: '不能为空',
      parent_version: '父版本',
      new_document: '新建文档',
      edit_document: '编辑文档',
      batch_delete_select: '您尚未选择要删除的内容',
      batch_edit_select: '您尚未选择要编辑的内容',
      filename: '文件名称',
      download_times: '下载量',
      new_file: '新建文件',
      edit_file: '修改文件',
      file: '文件',
      click_upload: '点击上传',
      new_document_type: '新建文档类型',
      edit_document_type: '编辑文档类型',
      go_document: '查看文档',
      only_parent_search: '只搜索父级文档种类',
      new_document_category: '新建文档种类',
      edit_document_category: '编辑文档种类',
      blank: '无',
      public: '公开',
      password: '密码保护',
      member: '会员',
      manager: '管理者',
      owner: '所有者',
      member_hint: '开启会员权限，请务必在您的网站中开启登录注册功能。',
      new_document_language: '新建文档语言',
      edit_document_language: '编辑文档语言',
      new_document_label: '新建文档标签',
      edit_document_label: '编辑文档标签',
      category: '文档种类',
      type: '文档类型',
      label: '文档标签',
      language: '文档语言',
      none: '无',
      file_type: '业务类型',
      product_sample: '产品样本',
      certificate: '认证证书',
      system_certification: '体系认证',
      test_report: '检测报告',
      resource_document: '所属文档'
    },
    content: {
      content_category: {
        confirm_delete: '确认删除',
        content_category: '内容分类',
        total: '共',
        page: '页',
        edit: '编辑',
        show: '查看',
        delete: '删除',
        batch_delete: '删除',
        name: '名称',
        status: '状态',
        new_content_category: '新建新闻种类',
        keywords: '关键字',
        current_records: '当前记录数',
        count: '条',
        desc: '描述',
        parent_node: '上级节点',
        thumbnail: '缩略图',
        operation: '操作',
        edit_category: '编辑类别',
        confirm: '确认',
        cancel: '取消',
        time: '时间',
        seq: '排序',
        seq_info: '*顺序越大，排序越靠前',
        input_info: '*名称为必填项',
        index_page: '首页',
        search: '查询',
        last_page: '末页',
        all: '全部',
        active: '有效',
        inactive: '无效',
        blank: '无',
        errors: {
          not_check: '你还没有选择分类'
        }
      },
      content_tag: {
        confirm_delete: '确认删除',
        content_tag: '内容标签',
        total: '共',
        page: '页',
        edit: '编辑',
        show: '查看',
        delete: '删除',
        batch_delete: '删除',
        new_data: '新建',
        name: '名称',
        status: '状态',
        new_content_category: '新建标签',
        keywords: '关键字',
        current_records: '当前记录树',
        count: '条',
        desc: '描述',
        parent_node: '上级节点',
        thumbnail: '缩略图',
        operation: '操作',
        edit_category: '编辑标签',
        confirm: '确认',
        cancel: '取消',
        time: '时间',
        seq: '排序',
        seq_info: '*顺序越大，排序越靠前',
        input_info: '*名称为必填项',
        index_page: '首页',
        search: '查询',
        last_page: '末页',
        preview: '预览',
        download: '下载',
        errors: {
          not_check: '你还没有选择标签'
        }
      }
    },
    login: {
      placeholder: {
        email_or_phone: '邮件地址/用户名登录',
        password: '密码'
      },
      account_login: '账号登录',
      automatic_login_next: '下次自动登录',
      forget_password: '忘记密码?',
      home: {
        customize_websites: '一起定制个性化网站',
        new: '立即创建网站',
        WYS_WYG: '所见即所得',
        find_more: '发现更多',
        enterprise_package: '企业版套餐',
        apply_for_trial: '申请试用',
        contact_us: '联系我们',
        submit: '提交',
        reset: '重置',
        join_us: '加入我们',
        about_us: '关于我们',
        help_center: '帮助中心',
        placeholder: {
          name: '姓名',
          phone: '电话',
          email: '邮箱',
          feedback: '意见反馈'
        },
        wrong_email: '请填写正确的邮箱地址',
        wrong_form: '请填写完整表单',
        sending: '正在发送...',
        thanks: '发送成功，感谢您的反馈~'
      },
      common: {
        sign_out: '退出',
        login: '登录',
        sign: '注册',
        privacy_statement: '隐私声明',
        legal_notices: '法律声明',
        terms_of_service: '服务条款'
      },
      check: {
        sign: '账号注册',
        apply: '申请递交',
        waiting: '等待审核',
        succeed: '审核成功',
        improve_application_materials: '完善申请资料',
        after_application: '申请提交完成后，我们会与您核实申请信息，请务必如实填写',
        name: '姓名',
        company: '公司',
        phone: '手机号',
        code: '验证码',
        get_code: '获取验证码',
        resend: '秒后重发',
        other_message: '其他留言',
        submit: '提交申请',
        thanks: '感谢您注册',
        stage: '平台。',
        account_check: '平台试运行期间，通过审核后方可使用平台提供的功能。工作人员将在1~3个工作日内完成账号审核。',
        send_to: '最新审核进展会发送到',
        period: '。',
        pass_check: '恭喜您已经通过审核！现在开始定制您的个性化网站吧！',
        new: '立即创建网站',
        wrong_phone: '请填入正确的手机号',
        wrong_form: '请填写完整表单'
      },
      alipay_jump: {
        waiting: '易企定： 正在跳转到支付宝支付页面，请稍后...'
      }
    },
    register: {
      use_email: '使用邮箱注册',
      sign: '立即注册',
      accept: '我已阅读并接受',
      terms_of_service: '《服务条款》',
      placeholder: {
        email: '请输入邮箱',
        password: '请输入6-20位密码'
      },
      message: {
        sign_failed: '注册失败，请重试！',
        wrong_email: '请输入正确的邮箱！',
        wrong_password: '请输入6-20位密码！',
        not_accept: '请先阅读并接受《服务条款》'
      }
    },
    reset_password: {
      retrieve_password: '找回密码',
      next_step: '下一步',
      sent: '邮件已发送',
      check_now: '立即查看',
      reset_password: '修改密码',
      complete: '完成',
      placeholder: {
        email: '请输入注册时的邮箱',
        password: '设置新密码',
        confirm_password: '请确认新密码'
      },
      message: {
        succeed: '修改密码成功，请重新登录',
        wrong_password: '请输入6-20密码',
        differ_password: '两次输入的密码不一致'
      }
    },
    dashboard: {
      back: '返回',
      preview: '预览',
      offline: '下线',
      online: '上线',
      setting: '设置',
      menu: '菜单',
      page_management: '页面管理',
      save: '保存',
      edit: '编辑',
      delete: '删除',
      login_page: '登录页面',
      add_page: '添加页面',
      exit_editor: '退出编辑器',
      add_template: '添加板块',
      set_page_modal: {
        title: '设置页面',
        page_name: '名称',
        page_desc: '描述',
        page_keyword: '关键字',
        business_type: '业务类型',
        page_title: '标题',
        page_head: '自定义头部',
        page_body: '自定义正文',
        page_title_abbr: '品牌词'
      },
      add_page_modal: {
        title: '添加页面',
        custom_name: '自定义名称'
      },
      add_to_template_modal: {
        title: '添加到模板',
        template_name: '模板名称'
      },
      set_site_properties_modal: {
        title: '设置网站属性',
        basic_setting: {
          title: '基本设置',
          site_title: '网站标题',
          site_description: '网站描述',
          site_keyword: '网站关键字',
          site_category: '网站类别',
          site_categories: {
            shop: '电商',
            offical: '企业官网',
            show: '展示',
            personal: '个人主页'
          },
          site_language: '网站语言',
          message_mailbox: '留言邮箱',
          second_domain: '二级域名',
          custom_domain: '自定义域名',
          site_logo: '网站图标',
          show_navigation: '显示导航',
          open_document_center: '开启文档中心',
          open_search: '开启全站搜索'
        },
        site_share: {
          title: '网站分享',
          share_imgae_big: '分享大图',
          share_image_samll: '分享小图'
        },
        privacy_setting: {
          title: '隐私设置',
          shielding_search_engine: '屏蔽搜索引擎'
        },
        service_and_validation: {
          title: '服务与验证',
          baidu_statistics_key: '百度统计key',
          google_analytics_tracker: 'Google Analytics 跟踪器',
          placeholder: {
            baidu: '请填入百度提供的HTML标签验证码(例如NQ53KRpuNy)',
            google: '例如： UA-XXXXXXX-X'
          }
        },
        data_analysis: {
          title: '数据分析',
          data_analysis_and_traffic: '数据分析与流量',
          click_to_data: '点击查看数据'
        },
        header_and_footer: {
          title: '页眉和页脚',
          show_header: '显示页面导航',
          show_footer: '显示页面底部',
          navigation: '导航',
          footer: '底部'
        },
        custom_content: {
          title: '自定义内容',
          title_head: '自定义头部',
          title_body: '自定义正文'
        },
        sitemap: {
          title: '站点地图',
          content: '内容'
        },
        robots: {
          title: 'robots.txt',
          content: '内容'
        },
        system_setting: {
          title: '系统设置',
          auto_save: '自动保存间隔（秒）'
        },
        sign_and_login: {
          title: '登录注册',
          show_sign_and_login: '显示登录注册',
          account_type: '账号类型',
          email: '邮箱地址',
          phone: '手机号码',
          check_type: '审核方式',
          manual: '人工审核',
          auto: '自动审核',
          sign_type: '注册方式',
          account: '账号验证',
          password: '设置密码',
          sign_picture: '注册时显示图片验证码',
          login_picture: '登录时显示图片验证码',
          service_agreement: '服务协议'
        },
        access_right: {
          title: '访问权限',
          access_control: '访问控制',
          site_access_restrictions: '网站访问限制',
          public: '公开访问',
          password: '密码保护',
          member: '注册会员',
          everyone_has_access: '所有人均可访问网站',
          access_password: '访问密码',
          enter_the_correct_password: '输入正确密码后可访问网站',
          membership_scope: '会员范围',
          all: '全部会员',
          checked: '审核过的会员',
          member_label: '按会员标签',
          passed_the_audit: '审核通过的会员登录后方可访问网站',
          permission: '具有以上会员标签的会员登录后方可访问网站',
          page_access_control: '页面访问控制',
          project: '同网站设置',
          custom: '自定义'
        }
      },
      add_new_block: {
        title: '添加新板块',
        system_block: '推荐板块',
        personal_block: '我的板块',
        delete: '确认？'
      },
      system_block: {
        'y-form': '留言表单',
        'y-navbar': '菜单导航',
        'y-news-information': '新闻资讯',
        'y-title': '段落标题',
        'y-product-card': '产品列表',
        'y-carousel': '轮播图',
        'y-footer': '页脚',
        'y-freedom': '自由定制',
        'y-product-desc': '简易图文',
        'y-product-recruit': '招聘列表',
        'y-workflow': '流程',
        'y-slider': '滑块',
        'y-hr': '分割线',
        'y-handbook': '帮助手册',
        'y-widget': '页面挂件',
        'y-custom-section': '网格定制',
        'y-photo-album': '相册',
        'y-news': '行业新闻',
        'y-category-list': '分类列表',
        'y-product-detail': '商品详情',
        'y-product-list': '商品列表'
      },
      tip_modal: {
        title: '提示',
        tip: '设置 访问控制 - 注册会员 需要开启 注册登录。'
      },
      ok: '确认',
      cancel: '取消',
      new_page: '新页面',
      confirm_delete: '确认删除？',
      add_page_first: '请先添加页面',
      confirm_online: '确认上线？',
      confirm_offline: '确认下线？',
      has_online: '您的网站已成功上线',
      has_offline: '您的网站已成功下线',
      login_and_register: '登录注册',
      validate_logon: '登录验证',
      login_title: '登录网站，享受易企定的优质服务',
      login_subtitle: '京备XXXXXXXXXXX',
      document: {
        center_name: '下载专区',
        search_name: '搜索列表',
        detail_name: '文档详情',
        center_file_name: '下载文件',
        search_title: '搜索页面',
        search_subtitle: '搜索产品、文档、新闻、页面',
        center_title: '下载文件，支持信息、软件、视频和音频内容'
      },
      es_search: {
        search_center: '搜索中心',
        search: '搜索',
        search_list: '搜索列表'
      },
      new_template_name: '新页面'
    },
    drawer_group: {
      navigation: '导航',
      add_new_item: '添加新项',
      language_setting: '站点',
      add_language: '添加站点',
      add: '添加',
      button_setting: '按钮设置',
      add_button: '添加按钮',
      menu_name: '名称',
      jump_page: '跳转页面',
      jump_block: '跳转板块',
      jump_link: '跳转链接',
      add_new_menu: '添加新菜单',
      menu: '菜单',
      alignment_at_both_ends: '两端对齐',
      compact_center: '紧凑居中',
      is_saving: '正在保存...',
      save_succeed: '保存成功',
      is_adding: '正在添加...',
      add_succeed: '添加成功',
      confirm_delete_button: '确认删除?',
      confirm_delete_language: '确认删除?',
      delete_button_succeed: '删除成功',
      delete_language_succeed: '删除成功',
      new_nav_link: '新导航菜单',
      chint_menu_type: '类型',
      product_label: '产品标签',
      open_new_tab: '打开新标签页',
      link: '链接',
      product: '产品导航',
      common: '普通导航',
      member: '会员导航',
      language_name: '语言'
    }
  },
  component: {
    config_drawer: {
      basic_setting: '基础设置',
      name: '名称',
      to_personal_block: '至个人板块',
      placeholder: {
        new_content: '新内容',
        custom_color: '自定义颜色#fff'
      },
      hide_category: '隐藏分类',
      use_menu: '使用菜单进行动态内容管理',
      apply_to_all_pages: '应用于所有页面',
      open_advanced_search: '开启高级搜索',
      delete: '移除',
      theme: '主题',
      icon: '图标',
      qrcode: '二维码',
      background: '背景',
      column_layout: '列布局',
      column: '列',
      pictures_and_links: '图片与链接',
      external_link: '图片外链接',
      sequence: '图片顺序',
      carousel: '轮播图',
      slider: '滑块',
      display: '展示方式',
      tiling: '平铺',
      locking: '锁定',
      align: '对齐方式',
      filter: '滤镜全局',
      clip_path_left: '左倾',
      clip_path_right: '右倾',
      more: '更多',
      upload: '上传',
      effect: '轮播效果',
      animation: '动画',
      speed: '速度',
      max_height: '最大高度',
      navigation_effect: '导航效果',
      superposition_effect: '叠加效果',
      selection_effect: '选中效果',
      fixed_on_the_top: '是否浮动在顶部',
      news: '新闻资讯',
      choose_category: '选择您需要展示的新闻分类',
      menu_left: '目录靠左',
      menu_right: '目录靠右',
      basic: '基础',
      display_settings: '显示设置',
      navigation_style: '导航样式',
      text_color: '文字颜色',
      chosen_color: '选中颜色',
      on_top: '是否置顶',
      element: '元素',
      show_element: '显示元素',
      button: '按钮',
      language: '国家语言',
      logo: 'logo',
      qrcode_icon: '二维码图标',
      transparent: '透明',
      mask: '遮罩',
      hrs: {
        dashed: '虚线',
        solid: '实线'
      },
      alignments: {
        center: '居中',
        left: '居左',
        right: '居右'
      },
      animations: {
        common: '普通',
        bounce: '反弹',
        fade: '渐变',
        flip: '翻转'
      },
      speeds: {
        none: '无',
        quick: '快',
        slow: '慢'
      },
      selected_animation_effects: {
        none: '无',
        focus: '聚焦',
        underline: '下划线'
      },
      confirm_delete: '确认删除?',
      save_succeed: '保存成功',
      add_to_personal_blocks_succeed: '已成功添加至个人板块',
      es_search: '全站搜索',
      none: '无',
      search_type_list: {
        all: '全部',
        document: '文档',
        news_information: '新闻',
        template: '页面',
        product: '产品'
      },
      category_setting: '分类设置',
      width: '宽度',
      product_label: '产品标签',
      choose_product_label: '请选择需要展示的商品的标签',
      content_label: '新闻标签',
      choose_content_label: '请选择需要展示的新闻的标签',
      title: '标题',
      time: '时间',
      link: '链接',
      setting: '设置',
      content_source: '内容来源',
      relevant_tag: '关联标签',
      manual_edit: '手动编辑',
      hidden: '隐藏内容',
      file_big: '图片过大',
      desc: '描述',
      seq: '显示顺序',
      delete_customer_service_channel: '删除当前客户服务渠道'
    },
    picture_config_drawer: {
      picture: '图片',
      video: '视频',
      choose_picture: '选择图片',
      upload_video: '上传视频',
      edit_picture: '编辑图片',
      picture_link: '输入图片链接地址',
      page: '页面',
      pic_size: '请使用小写字母(a-z)和数字(0-9)，单词间用“-”连接',
      open_new_tab: '打开新标签页',
      picture_descripition: '输入图片描述',
      picture_desc_placeholder: '图片描述',
      remove: '移除图片',
      video_url: '输入视频网址（腾讯、爱奇艺、优酷、土豆视频）',
      url: '网址 (http://xxxx)',
      confirm_upload: '确认上传?',
      none: '无',
      save: '保存',
      cancel: '取消',
      confirm: '确认？',
      edit: '编辑',
      move: '拖动',
      crop: '剪切',
      enlarge: '放大',
      reduce: '缩小',
      anticlockwise: '逆时针45°',
      clockwise: '顺时针45°',
      left_translation: '左平移',
      right_translation: '右平移',
      recommended_size: '推荐尺寸 ：',
      file_big: '上传图片过大，建议上传大小200KB以内的图片'
    },
    button: {
      name: '名称',
      layout: '样式选择',
      solid: '实心',
      hollow: '空心',
      button_color: '按钮颜色',
      link: '链接',
      jump_page: '跳转页面',
      jump_block: '跳转板块',
      open_new_tab: '打开新标签页',
      hide_after_going_online: '上线后隐藏',
      right_angle: '直角',
      fillet: '圆角',
      full_angle: '全角',
      new_content: '新内容',
      button_size: {
        small: '小号',
        normal: '正常',
        big: '大号'
      },
      none: '无'
    },
    image_modal: {
      upload_new_pictures: '上传新图片',
      uploaded_pictures: '已上传的图片',
      gallery: '图库',
      browse_in_local_files: '在本地文件中浏览',
      all_pictures: '这里是你上传的所有图片。',
      pic_size: '请使用小写字母(a-z)和数字(0-9)，单词间用“-”连接',
      picture_select: '你可以上传更多图片，然后从列表中选择。',
      upload_pictures: '上传图片',
      file_count: '个文件',
      uploaded_at: '上传于',
      all: '全部',
      confirm_delete: '确认删除?',
      landscape: '风景',
      animation: '动画',
      blurry: '模糊',
      food: '食物',
      city: '城市',
      literary: '文艺清新',
      abstract: '抽象',
      business: '商业',
      natural: '自然'
    },
    menu_editor: {
      all_menu: '全部菜单',
      all: '全部',
      block_content: '板块内容',
      add_content: '添加内容',
      title: '标题',
      belongs_to_blcok: '所属板块',
      creat_at: '创建时间',
      operate: '操作',
      confirm_delete: '确认删除？',
      add_succeed: '添加成功',
      modify_succeed: '修改成功',
      update_succeed: '更新成功',
      edit: '编辑',
      delete: '删除',
      belongs_to_menu: '所属菜单',
      status: '状态',
      published: '已发布',
      unpublished: '未发布',
      picture: '配图',
      upload: '点击上传',
      notes: '注释',
      content: '内容',
      cancel: '取消',
      ok: '确认',
      setting_menu: '设置菜单',
      name: '名称',
      associate_page: '关联页面',
      associate_block: '关联板块',
      add_new_menu: '添加新菜单',
      superior_menu: '上级菜单',
      none: '无'
    },
    nav_title_dropdown: {
      link: '链接',
      page: '跳转页面',
      block: '跳转板块',
      null: '无',
      selected_color: '选中颜色',
      unselected_font_color: '未选中字体颜色',
      open_new_tab: '打开新标签页',
      delete: '删除'
    },
    section_menu: {
      setting: '设置'
    },
    form_editor: {
      field: '表单字段',
      options: '表单选项',
      border_color: '表单边框颜色',
      save: '保存',
      cancel: '取消',
      check_all_messages: '查看所有留言'
    },
    map_editor: {
      baidu_map: '百度地图',
      placeholder: '请输入详细地址',
      address: '地址',
      save: '保存',
      cancel: '取消',
      edit: '编辑'
    },
    cook_loading: {
      preparing: 'Loading...'
    },
    drag_grid_item: {
      down: '下层',
      up: '上层',
      untying: '解绑',
      center: '居中',
      locking: '锁定层',
      theme: '主题',
      layout: '样式',
      upload_picture: '上传图片',
      custom_color: '自定义颜色#fff',
      remove: '移除',
      combine: '组合'
    },
    empty_box: {
      no_data: '没有查询到符合条件的记录'
    },
    item_adder: {
      add_content: '添加内容',
      title: '标题',
      text: '文本',
      picture_or_video: '图片/视频',
      button: '按钮',
      product: '产品',
      form: '表单',
      map: '地图',
      grid: '网格',
      split_line: '分割线',
      delete: '删除',
      confirm: '确认?',
      config: '配置'
    },
    plan_loading: {
      is_loading: '正在为您拼命加载... '
    },
    es_search: {
      news_information: '新闻资讯',
      product: '产品',
      document: '文档',
      template: '页面',
      search_keyword: '请输入搜索关键字',
      search: '搜索',
      search_option: '筛选方式',
      search_type: '类型',
      find_result: '找到结果',
      search_order: '排序',
      order_type: '排序类型',
      all_type: '全部',
      load_more: '显示更多'
    },
    image: {
      confirm_upload: '确认上传?'
    },
    item_empty_adder: {
      add: '添加'
    },
    nav_modal: {
      cancel: '取消',
      ok: '确认'
    }
  },
  sections: {
    login_section: {
      placeholder: {
        enter_password: '请输入访问密码',
        email: '邮箱',
        phone: '手机号',
        password: '密码',
        code: '验证码',
        picture_code: '图形验证码',
        set_password: '设置密码 密码长度6-20位',
        confirm_password: '确认密码'
      },
      login: '登录',
      send_code: '发送验证码',
      resend: '秒后重发',
      change_picture: '换一张',
      code_login: '账号验证码登录',
      password_login: '账号密码登录',
      find_password: '找回密码',
      sign: '注册',
      read_and_accept: '我已阅读并同意',
      service_agreement: '服务协议',
      account_type: {
        check: '您的账号正在审核中',
        ban: '您的账号已被网站管理员禁用',
        no_member: '您登录的账号并不是该网站的会员',
        no_member_label: '您没有访问该页面的权限，请联系网站管理员'
      },
      modify_password: '修改密码',
      new_password: '你已成功设置密码，请试用新密码登录',
      next_step: '下一步',
      wrong_phone_format: '手机号码格式不正确',
      wrong_email_format: '邮箱地址格式不正确',
      send_code_succeed: '验证码发送成功',
      wrong_phone: '请输入正确的手机号码',
      wrong_email: '请输入正确的邮箱',
      wrong_code: '请输入正确的验证码',
      empty_code: '请输入验证码',
      wrong_password_length: '请输入密码',
      wrong_password: '密码错误',
      password_not_same: '两次密码输入不一致，请重新输入',
      wrong_picture_code: '请输入正确的图形验证码',
      read_agreement_first: '请先阅读服务协议',
      account_status: '正常',
      sign_succeed: '注册成功',
      login_succeed: '登录成功'
    },
    y_carousel: {
      add: '添加轮播图',
      animations: {
        common: '普通',
        bounce: '反弹',
        fade: '渐变',
        flip: '翻转'
      },
      speed: {
        null: '无',
        slow: '慢',
        quick: '快'
      },
      is_saving: '正在保存...',
      save_succeed: '保存成功'
    },
    y_category_list: {
      all_categories: '全部分类',
      categories: '分类',
      all: '全部',
      add_content: '添加内容',
      add_category: '添加分类',
      modify_category: '修改分类',
      name: '名称',
      ok: '确定',
      cancel: '取消',
      add_succeed: '添加分类成功',
      name_empty: '分类名称不能为空',
      modify_succeed: '修改分类成功',
      confirm_delete: '确认删除？',
      delete_succeed: '删除分类成功',
      choose_menu: '请先选择一个菜单',
      none: '无'
    },
    y_document_center: {
      download: '下载文档'
    },
    y_document_detail: {
      download_area: '下载专区',
      download_center: '下载中心',
      document_attributes: {
        date: '文档日期',
        size: '文档大小',
        type: '类型',
        category: '种类',
        code: '编号',
        version: '版本',
        language: '语言',
        description: '描述'
      },
      copy: '复制链接',
      download: '文件下载',
      download_all: '全部下载',
      scan_and_share: '扫一扫分享页面',
      login_download: '点击登录页面，登录后即可下载',
      login: '立即登录',
      password_for_download: '请输入密码以访问或下载该文档',
      password: '访问密码',
      ok: '确认',
      login_member_account: '访问该文档需要您登陆会员账号，请点击该链接前往登陆',
      params_error: '缺少参数，请通过文档列表页面跳转进入文档详情页',
      account_is_checking: '您的账号正在审核中，暂时不能访问该文档',
      account_is_forbidden: '您的账号被禁用了，暂时不能访问该文档',
      login_for_read: '该文档仅限网站管理员访问，如果您是管理员，请登录您的易企定账户再进行访问',
      read_forbidden: '该文档仅限网站管理员访问',
      read_for_owner: '该文档仅限网站所有者访问',
      password_error: '访问密码错误',
      open_in_browser: '请点击屏幕右上角选择在浏览器中打开',
      copy_succeed: '链接已成功复制到剪贴板',
      click_to_share: '请点击屏幕右上角分享'
    },
    y_document_list: {
      choose_type: '筛选方式',
      clear: '清除所有筛选方式',
      document_category: '文档种类',
      document_type: '文档类型',
      document_language: '文档语言',
      category: '种类',
      type: '类型',
      extends: '展开',
      language: '语言',
      order: '排序',
      order_placeHolder: '排序类型',
      display: '显示',
      display_count: '显示个数',
      document_attributes: {
        date: '文档日期',
        size: '文档大小',
        type: '类型',
        category: '种类',
        code: '编号',
        version: '版本',
        language: '语言',
        description: '描述'
      },
      advanced_search: '高级搜索',
      password_for_download: '请输入密码以访问或下载该文档',
      password: '访问密码',
      ok: '确认',
      login_member_account: '访问该文档需要您登陆会员账号，请点击该链接前往登陆',
      params_error: '缺少参数，请通过文档列表页面跳转进入文档详情页',
      account_is_checking: '您的账号正在审核中，暂时不能访问该文档',
      account_is_forbidden: '您的账号被禁用了，暂时不能访问该文档',
      login_for_read: '该文档仅限网站管理员访问，如果您是管理员，请登录您的易企定账户再进行访问',
      read_forbidden: '该文档仅限网站管理员访问',
      read_for_owner: '该文档仅限网站所有者访问',
      password_error: '访问密码错误',
      open_in_browser: '请点击屏幕右上角选择在浏览器中打开',
      copy_succeed: '链接已成功复制到剪贴板',
      click_to_share: '请点击屏幕右上角分享',
      order_rules: {
        name_asc: '名称升序',
        name_desc: '名称降序',
        created_at_asc: '创建时间升序',
        created_at_desc: '创建时间降序',
        updated_at_asc: '更新时间升序',
        updated_at_desc: '更新时间降序'
      }
    },
    y_document_search: {
      advanced_search: '高级搜索',
      category: '文件种类',
      type: '文档类型',
      search_placeholder: '按关键字、产品编号或产品名称搜索',
      search: '搜索'
    },
    y_footer: {
      link: '链接',
      page: '跳转页面',
      block: '跳转板块',
      none: '无',
      open_new_tab: '打开新标签页',
      delete: '删除',
      add_link: '添加链接',
      add_picture: '添加图片',
      themes: {
        center: '居中分布',
        justify_align: '两端对齐',
        cross_sectional: '分类排列'
      },
      confirm_delete: '确认删除？'
    },
    y_form: {
      edit: '编辑',
      form_content: {
        name: '姓名',
        name_label: '姓名标签',
        mail: '邮箱地址',
        mail_label: '邮箱地址标签',
        phone: '电话',
        phone_label: '电话标签',
        opinion: '意见',
        opinion_label: '意见标签',
        send: '发送',
        send_label: '发送标签',
        subject: '主题',
        subject_label: '主题标签'
      },
      is_sending: '正在发送...',
      send_succeed: '发送成功，感谢您的反馈~',
      wrong_mail: '请填写正确的邮箱地址',
      complete_form: '请填写完整表单',
      themes: {
        layout_A: '布局A',
        layout_B: '布局B'
      }
    },
    y_freedom_section: {
      container_width: '容器宽度',
      responsive: '响应式',
      text: '文本',
      picture: '图片',
      button: '按钮',
      whiteboard: '白板',
      map: '地图',
      spilt_line: '分割线'
    },
    y_handbook: {
      home_page: '首页',
      add_article: '添加文章',
      modify_article: '修改文章',
      add_category: '添加类别',
      modify_category: '修改分类',
      category: '类别',
      title: '文章标题',
      rich_text: '富文本',
      preview: '预览',
      category_name: '分类名称',
      modify_home_page: '修改首页',
      color: '颜色',
      custom_color: '自定义颜色 #fff',
      name: '名称',
      name_title: '标题',
      confirm_delete: '确认删除？',
      is_saving: '正在保存...',
      save_succeed: '保存成功'
    },
    y_header: {
      confirm_delete: '确认删除？',
      themes: {
        common: '普通',
        layout_A: '布局1'
      }
    },
    y_hr: {
      dashed: '虚线',
      solid: '实线'
    },
    y_markdown: {
      preview: '预览'
    },
    y_menu: {
      keyword_placeholder: '输入关键字',
      search: '搜索',
      login_out: '退出',
      confirm_delete: '确认删除该语言？'
    },
    y_navbar: {
      add_link: '添加链接',
      keyword_placeholder: '输入关键字',
      confirm_delete: '确认删除？',
      is_saving: '正在保存..',
      save_succeed: '保存成功',
      theme: {
        two: '两端对齐A',
        layout_A: '两端对齐B',
        layout_B: '叠加',
        layout_C: '二级菜单',
        layout_D: '紧凑居中',
        layout_img: '图标按钮'
      },
      none: '无'
    },
    y_news: {
      link: '链接',
      page: '跳转页面',
      none: '无',
      selected_color: '选中颜色',
      open_new_tab: '打开新标签页',
      add_news: '添加新闻',
      is_saving: '正在保存..',
      save_succeed: '保存成功',
      confirm_delete: '确认删除？'
    },
    y_news_information: {
      author: '作者',
      description: '摘要',
      data_source: '来源',
      tips: '非特殊说明，本文版权归原作者所有，转载请注明出处',
      data_source_link: '本文地址',
      labels: '文章标签',
      scan_and_preview: '扫一扫，手机预览页面',
      scan_and_share: '扫一扫分享页面',
      click_to_share: '请点击屏幕右上角分享'
    },
    y_news_list: {
      choose_category: '请点击设置，选择需要展示的新闻分类',
      author: '作者',
      no_news: '暂无新闻',
      modify_title: '修改标题',
      color: '颜色',
      custom_color: '自定义颜色 #fff',
      name: '名称',
      title: '标题'
    },
    y_photo_album: {
      add_picture: '添加图片',
      choose_menu: '请先选择一个菜单',
      confirm_delete: '确认删除？',
      themes: {
        common: '图文交替',
        one: '左图右文',
        two: '图文堆叠'
      }
    },
    y_product_card: {
      add_product: '添加产品',
      confirm_delete: '确认删除？',
      themes: {
        four: '纵向布局',
        layout_A: '上图下文A',
        layout_B: '左图右文A',
        layout_C: '上图下文B',
        layout_D: '左图右文B'
      },
      none: '无'
    },
    y_product_desc: {
      upload_thumbnail: '上传缩略图',
      upload_banner: '上传大图',
      remove: '移除',
      confirm_delete: '确认删除？',
      themes: {
        layout_A: '左文右图-居中',
        layout_B: '左图右文-居中',
        layout_C: '上图下文',
        layout_D: '左文右图-下对齐',
        layout_E: '左图右文-下对齐'
      }
    },
    y_recruit_card: {
      add_recruit: '添加招聘启事',
      confirm_delete: '确认删除？',
      themes: {
        layout_A: '布局A'
      }
    },
    y_search: {
      search: '搜索',
      search_keyword: '请输入搜索关键字'
    },
    y_search_list: {
      choose_type: '筛选方式',
      type: '类型',
      all: '全部',
      results: '找到结果',
      unit: '条',
      order: '排序',
      order_type: '排序类型',
      document_attributes: {
        date: '日期',
        size: '大小',
        type: '类型',
        category: '种类',
        code: '编号',
        version: '版本',
        language: '语言',
        description: '描述'
      },
      author: '作者',
      brand: '品牌',
      date: '日期',
      no_data: '暂无数据',
      more: '显示更多',
      search_empty: '未搜索到数据',
      orders: {
        relativity: '按相关性从高到低',
        updated_at_desc: '按日期从新到旧',
        updated_at_asc: '按日期从旧到新',
        name_sort_desc: '按文件名称'
      },
      news_information: '新闻资讯',
      product: '产品',
      document: '文档',
      template: '页面'
    },
    y_slider: {
      add_slider: '添加滑块',
      is_saving: '正在保存...',
      save_succeed: '保存成功',
      animations: {
        common: '普通',
        bounce: '反弹',
        fade_1: '渐变-1',
        fade_2: '渐变-2',
        flip: '翻转'
      },
      themes: {
        common: '文字居中',
        one: '左文右图',
        two: '左图右文'
      }
    },
    y_title: {
      themes: {
        layout_A: '居中A',
        layout_B: '居中B',
        layout_C: '居中C',
        layout_D: '居左',
        layout_E: '居右'
      }
    },
    y_widget: {
      save_succeed: '保存成功',
      confirm_delete: '确认删除？'
    },
    y_workflow: {
      add_item: '添加项目',
      confirm_delete: '确认删除？',
      circle_types: {
        right_angle: '直角',
        fillet: '圆角'
      },
      themes: {
        common: '从左到右',
        one: '从上到下',
        two: '上下居中',
        three: '上下居左',
        four: '上图下文'
      },
      style: '样式',
      none: '无',
      selected_color: '选中颜色',
      delete: '删除'
    }
  },
  initialization_data: {
    button: {
      content_name: '显示更多'
    },
    map: {
      title: '添加文本',
      page_content: '上海市张江高科技园区张衡路666弄'
    },
    text: {
      add_text: '在此处添加文本',
      title: '作者',
      subtitle: '鄂霍茨克的雪',
      page_content: '每到冬季，会出现一列名为流冰特急鄂霍茨克之风的季节限定列车，疾驰横穿过大半个北海道大陆。我的旅途便是从札幌出发，直到鄂霍茨克海边的网走。'
    },
    form: {
      title: '添加文本',
      name: '姓名',
      name_label: '姓名标签',
      mail: '邮箱地址',
      mail_label: '邮箱地址标签',
      phone: '电话',
      phone_label: '电话标签',
      opinion: '意见',
      opinion_label: '意见标签',
      send: '发送',
      send_label: '发送标签',
      theme: '主题',
      theme_label: '主题标签'
    },
    category_list: {
      title_1: '墨尔本大学',
      subtitle_1: '澳洲',
      title_2: '剑桥大学',
      subtitle_2: '英国'
    },
    footer: {
      name_1: '链接1',
      name_2: '链接2',
      title: 'copyright 2018 XXXX 沪ICP备XXXXXXXX号'
    },
    function: {
      title: '在此处添加标题',
      subtitle: '在此处添加副标题',
      page_content: '在此处添加内容'
    },
    header: {
      title: '大标题',
      subtitle: '副标题',
      page_content: '时光静好，我亦不老倾我一生一世，换取岁月静好。如若岁月静好，我亦微笑，亦不老。'
    },
    navbar: {
      name: '新链接',
      content_name: '标题',
      name_1: '首页',
      name_2: '产品列表',
      name_3: '关于我们',
      name_4: '活动动态',
      name_5: '产品服务',
      name_6: '联系我们',
      login: '登录',
      sign: '注册',
      button: '按钮'
    },
    news: {
      title: '标题栏',
      title_1: '获得国外设计奖',
      subtitle_1: '继今年三月获得中国第一个建筑技术品类的IF设计金奖之后，公司再次凭借出色的设计理念和独特的外观设计荣获三项&ldquo;CERMAN DESIGN&rdquo;设计奖',
      title_2: '中国设计类第一杂志专访',
      subtitle_2: '接受了全球建筑与设计领域权威杂志《DOMUS国际中文版》的采访。在采访中。欧瑞博讲述了如何以科技美学引领只能家居的发展，以及研究智',
      title_3: '喜讯|引领只能家居科技美学，公司产品获得IDEA大奖',
      subtitle_3: 'IDEA奖（INDUSTRIAL DESGIN EXCELLENCE AWARDS）源自美国，由美国商业周刊主办业内公认为评审更加苛刻的国际设计大将。同时',
      title_4: '智能创新技术 引领行业风潮',
      subtitle_4: '长期致力于只能视频分析、计算视觉、机器学习、云计算等技术上的研发，坚持将最先进的科学技术与大众需求相结合，通过创新思路与科技革新，'
    },
    news_list: {
      all: '全部'
    },
    product_card: {
      name: '产品',
      title: '13.3英寸笔记本电脑',
      subtitle: '官方自营旗舰店',
      content_name: '加入购物车'
    },
    product_desc: {
      title: '标题内容',
      subtitle: '正文内容',
      content: {
        title: '<p><span style="font-size:36px">猫王收音机</span></p><p><span style="line-height:1.5"><span style="color:#abadae"><span style="font-size:14px">PRINCELING&nbsp; 2017款</span></span></span></p> <p><span style="color:#abadae"><span style="font-size:14px;">价格： </span></span><span style="color:#f43244"><span style="font-size:14px"><span style="font-size:22px">￥399.00</span></span></span></p> <p><span style="color:#bdbdbe"><span style="font-size:14px"><span style="font-size:22px">&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;</span></span></span></p><p>&nbsp;</p><p><em><span style="font-size:14px">原木的生命力 ，经得起时间的洗礼</span></em></p><p><em><span style="font-size:14px">严苛选用北美胡桃原木，时光流逝</span></em></p><p><em><span style="font-size:14px">方可赋予奇妙纹理与色泽</span></em></p>',
        subtitle: '情怀与打磨',
        content_name: '立即购买'
      }
    },
    recruit_card: {
      title: '标题内容',
      subtitle: '正文内容'
    },
    slider: {
      title: '伊肯纳斯',
      subtitle: '家用地毯'
    },
    workflow: {
      title_1: '购买',
      subtitle_1: '从高品质的系列中挑选你喜欢的，并添加到购物车。',
      title_2: '在线支付',
      subtitle_2: '输入收货地址，可使用信用卡，借记卡或PayPal付款。',
      title_3: '发货',
      subtitle_3: '我们将在5个工作日内发货， 有任何问题可以与我们联系！',
      title_4: '确认',
      subtitle_4: '收到货品后，请及时确认, 有任何问题可以与我们联系。'
    },
    title: {
      title: '大标题内容',
      subtitle: '小标题内容',
      page_conteng: '在此处添加新内容',
      content_name: '按钮'
    },
    handbook: {
      category: '分类1',
      title: '文章标题',
      page_content: '在此处添加新内容'
    },
    freedom: {
      page_content_1: '服务大厅',
      page_content_2: '客服热线',
      page_content_3: '我们会在乎每一个细小的意见，如果您对产品有什么建议，不妨通过&ldquo;400-800-1188&rdquo;这个属于您自己的专业语音频道，与我们沟通、交流。',
      page_content_4: '办卡指引',
      page_content_5: '您只要申请成为优惠消费者，就可以得到贴心的服务与支持。为此，我们特为您准备了相关问与答，祝您有一个愉快的购物体验。'
    },
    custom_section: {
      title: '组织活动'
    },
    blocks: {
      title: '段落标题',
      custom_section: '网格定制',
      header: '页面头部',
      navbar: '菜单导航',
      product_card: '产品列表',
      form: '留言表单',
      carousel: '轮播图',
      footer: '页尾',
      slider: '滑块',
      hr: '分割线',
      workflow: '流程',
      markdown: 'markdown语法板块',
      handbook: '帮助文章',
      photo_album: '相册',
      freedom: '自由定制',
      contact_info: '联系方式',
      product_desc: '简易图文',
      product_recruit: '招聘列表',
      dynamic_form: '动态表单',
      widget: '挂件',
      news: '行业新闻',
      category_list: '分类列表',
      news_information: '新闻资讯'
    }
  },
  site: {
    use_now: '立即使用',
    back: '返回'
  },
  chint: {
    dealer_checking: 'Please wait for the admin to review your membership privileges.',
    email_not_blank: '邮箱地址不能为空',
    delete_confirm: '你确定要删除这条数据吗?',
    product_center: '产品中心',
    product_detail: '产品详情',
    home_page: '首页',
    service_center: '服务中心',
    personal_service: '个人服务',
    customer_service: '客服服务',
    solution: '解决方案',
    about_us: '关于我们',
    news_center: '新闻中心',
    news_detail: '新闻详情',
    download_center: '下载中心',
    contact_us: '联系我们',
    product_marketing: '产品推广',
    join_us: '加入我们',
    membership: '会员中心',
    member_center: '个人中心',
    member_promotion: '积分商城',
    search_center: '搜索中心',
    add_item: '添加',
    remove_item: '删除',
    edit_item: '编辑',
    unlock: '解锁',
    return: '返回',
    edit_contacts: '编辑联系人',
    edit_address: '编辑地址',
    confirm_delete: '确认删除？',
    login_and_register: '登录注册',
    submit_successful: '提交成功',
    save: '保存',
    option: '操作',
    name: '名称',
    color: '颜色',
    member_label: '会员标签',
    member_label_permission: '可访问该内容的会员标签',
    permission: '权限',
    public: '公开',
    permission_manage: '权限管理',
    role_list: '角色列表',
    new_role: '新增角色',
    login: {
      login: '登录',
      register: '注册',
      register_email: '注册邮件',
      register_success: '注册成功',
      reset_password: '忘记密码',
      reset_password_email: '忘记密码邮件',
      reset_password_success: '重置密码成功',
      confirm_password: '重置密码',
      user_agreement: '用户协议',
      must_input: '带*号的为必填项',
      reset_email_send: '我们向您的账号发送了一封邮件，请按照邮件内容进行下一步操作',
      send_email: 'We have sent you an email with a link to',
      send_register_email: 'to complete your registration',
      send_reset_pwd_email: 'to reset your password',
      return: 'Return',
      no_account: 'No Account? Register Now',
      forgot_password: 'Forgot Password',
      submit: 'Submit',
      existing_account: 'Existing accounts',
      log_in: 'Login',
      privacy: 'Acceptable Use Policy - Privacy',
      resend: 'Resend',
      after_send: 'Can be sent again after',
      accept: '同意'
    },
    home: {
      search: 'Search',
      download_center: 'Download Center',
      contact_us: 'Contact Us',
      login: 'Login',
      learn_more: 'Learn More'
    },
    search: {
      place_holder: 'NA1 Air Circuit Breaker',
      search_button: 'Search'
    },
    bread: {
      home: 'Home',
      search: 'Search',
      service_center: 'Service Center',
      customer_service: 'Customer Service',
      self_service: 'Enquiries',
      download_center: 'Download Center',
      about_us: 'About Us',
      company: 'Company',
      news_center: 'News Center',
      join_us: 'Join Us',
      membership: 'Membership',
      user_center: 'User Center',
      promotions: 'Promotions'
    },
    nav: {
      log_out: '退出成功'
    },
    member_center_page: {
      wrong_email: '请输入正确的邮箱.',
      password_not_same: '两次密码输入不一致，请重新输入.',
      logins: 'Logins',
      your_email: 'Your Email',
      password: 'Password',
      personal_info: 'Personal Info'
    },
    product_detail_page: {
      return_list: 'Return list',
      overview: 'Overview',
      technical_parameter: 'Technical Parameter',
      install_manual: 'Installation Manual'
    },
    product_list_page: {
      search_button: 'Search',
      search_place_holder: 'Enter the name of Product or model'
    },
    foot_content: {
      customer_service_channel: '新增客服渠道'
    },
    form: {
      name: 'Name',
      company: 'Company',
      title: 'Title',
      email: 'Email',
      phone_number: 'Phone Number:（+Area code）',
      region: 'Select your region',
      question_type: 'Question type',
      description: 'Description of relevant information',
      verification_code: 'Verification Code',
      submit: 'Submit Now',
      password: 'Password',
      confirm_password: 'Confirm Password',
      save: 'Save',
      first_name: 'First Name',
      last_name: 'Last Name',
      position: 'Position',
      industry: 'Industry',
      new_password: 'New Password',
      phone_number_code: 'Phone',
      region_code: 'Region',
      role: 'I am a...'
    },
    download_center_page: {
      sample: 'Sample',
      category: 'Product Category',
      sub_category: 'Product Sub Category',
      search_place_holder: 'Search by keyword, product name',
      file_name: 'File Name',
      document_format: 'Document Format',
      update_time: 'Update Time',
      document_size: 'Document Size',
      download: 'Download'
    },
    news_center_page: {
      more_news: 'More',
      read_more: 'Read More',
      return_list: 'Return List',
      add_news_tag: '选择您需要展示的新闻标签',
      edit_news: '编辑新闻Tab',
      title: '标题',
      seq: '顺序',
      news_tag: '新闻种类',
      seq_hint: '按数字降序排列',
      before_news: '上一篇',
      next_news: '下一篇'
    },
    join_us_page: {
      recruitment: 'Recruitment',
      hr: 'Human Resource',
      care_team: 'Care Team',
      city: 'City',
      company: 'Company',
      type: 'Type',
      search_placeholder: 'Search by keyword, product name',
      position: 'Position',
      number: 'Number',
      email: 'Email',
      download_template: 'Download template',
      upload: 'Upload',
      upload_hint: 'Please upload files in. doc or PDF format',
      edit_name: '编辑标签页名称和是否显示',
      name: '名称',
      status: '状态',
      show: '展示',
      hide: '隐藏'
    },
    custom_form: {
      form_type: '表单类型',
      form_data: '表单数据',
      name: '姓名',
      company: '公司',
      email: '邮箱',
      industry: '行业',
      region: '区域',
      time: '时间',
      position: '岗位',
      type: '类型',
      number: '数量',
      title: '职衔',
      phone: '联系方式',
      question_type: '问题类型',
      subscribe: '订阅者',
      recruitment: '招聘',
      self_service: '自助服务',
      custom_contact: '用户留言',
      status: '状态',
      new: '未处理',
      processing: '处理中',
      processed: '已处理',
      from_page: '来源'
    },
    contact: {
      add_new_point: '添加新坐标',
      edit_point: '编辑坐标',
      add_new_contact: '添加新联系人',
      edit_contact: '编辑联系人',
      confirm_delete_point: '确认删除坐标-',
      name: '名称',
      state: '邦',
      type: '类型',
      address: '地址',
      contact: '联系人',
      contact_no: '联系电话',
      email: '邮箱',
      operation: '操作',
      color: '颜色',
      blue: '蓝',
      red: '红',
      green: '绿',
      orange: '橙',
      yellow: '黄',
      purple: '紫',
      light_blue: '浅蓝',
      distributor: '经销商',
      location: '详细地址',
      edit_address: '编辑地址'
    }
  }
};
